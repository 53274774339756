import { createSlice } from '@reduxjs/toolkit';
import { createNotification } from 'utils/notification';
import { backendAPI, i18nInstance } from 'utils';
import { gqlclient, GET_PROPERTY, GET_PROPERTIES } from 'query';
import { GET_OFFERING, GET_OFFERINGS } from 'query/offering';
import { RESET_STATE } from './sharedActions';
import type {
  SimpleProperty,
  PropertyIssuerRequest,
  OnChainOfferingRaw,
  OnChainOfferings,
  OnChainOfferingsRaw,
  OnChainProperties,
  OnChainPropertiesRaw,
  OnChainPropertyRaw,
  PropertiesState,
  Property,
  PropertyRaw,
  PropertyDocument,
  PropertyStatistics,
  PropertyStatistic,
  PropertyToken,
  PropertyHighlight,
  PropertyIssuer,
} from 'types';
import {
  createAppAsyncThunk,
  genericErrorReasons,
  IssuerData,
  OnChainOffering,
  OnChainProperty,
} from 'types';

const initialPropertyToken: PropertyToken = {
  address: '',
  created: '',
  numberOfDecimals: 0,
  currentValuation: 0,
  symbol: '',
  totalSupply: 0,
};

const initialPropertyStatistic: PropertyStatistic = {
  availableTokens: 0,
  averageRevenue: 0,
  profitDistributed: 0,
  projectedYield: 0,
  propertyValuation: 0,
  tokenValuation: 0,
  valuation: 0,
  averageYearlyRevenue: 0,
  staticYield: 0,
};

const initialPropertyState: Property = {
  address: '',
  featuredImage: '',
  highlights: [],
  images: [],
  latitude: 0,
  longitude: 0,
  offeringPrice: 0,
  propertyName: '',
  propertyStatistic: initialPropertyStatistic,
  propertyStatus: null,
  token: initialPropertyToken,
};

const initialPropertyStatistics: PropertyStatistics = {
  capitalStacks: [],
  revenueDistributions: [],
  tokenBalances: [],
  valuation: 0,
};

const initialPropertyIssuer: PropertyIssuer = {
  address: '',
  latitude: 0,
  longitude: 0,
  licencedIssuerStats: {
    averageHolder: 0,
    averageYield: 0,
    listedProperties: 0,
    profitDistributed: 0,
    totalInvestors: 0,
    totalValuation: 0,
  },
  name: '',
  walletAddress: '',
  websiteUrl: '',
  meta: {
    fetchingComplete: false,
  },
};

const initialState: PropertiesState = {
  documents: [],
  statistics: initialPropertyStatistics,
  featuredProperties: [],
  properties: [],
  onChainProperties: {},
  onChainOfferings: {},
  isRequestingOnChainProperties: false,
  isRequestingOnChainOfferings: false,
  onChainPropertiesRequestError: null,
  onChainOfferingsRequestError: null,
  offeringProperties: [],
  property: initialPropertyState,
  issuer: initialPropertyIssuer,
  isRequestingIssuer: false,
  errorMessageIssuer: null,
  isRequestingFeaturedProperties: false,
  errorMessageFeaturedProperties: null,
  isRequestingOfferingProperties: false,
  errorMessageOfferingProperties: null,
  isRequestingProperties: false,
  errorMessageProperties: null,
  isRequestingProperty: false,
  errorMessageProperty: null,
  isRequestingDocuments: false,
  errorMessageDocuments: null,
  isRequestingStatistics: false,
  errorMessageStatistics: null,
  isRequestingOnChainProperty: false,
  errorMessageRequestOnChainProperty: null,
  isRequestingOnChainOffering: false,
  errorMessageRequestOnChainOffering: null,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    selectedPropertyStateReset(state) {
      state.property = initialPropertyState;
    },
    propertyClearLastError(state) {
      state.errorMessageProperty = null;
    },
    resetIssuerData(state) {
      state.issuer = initialPropertyIssuer;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchProperty.pending, (state) => {
      state.isRequestingProperty = true;
      state.errorMessageProperty = null;
    });
    builder.addCase(fetchProperty.fulfilled, (state, { payload }) => {
      state.isRequestingProperty = false;
      const { highlights: unformattedHighlights, images, ...property } = payload;

      const highlightsFormatted: Array<PropertyHighlight> = Object.entries(
        unformattedHighlights
      ).map(([key, value]: [string, string]) => ({
        id: key.toLowerCase().replace(' ', '_'),
        type: key,
        text: value,
      }));

      const newProperty: Property = {
        highlights: highlightsFormatted,
        images: images && Array.isArray(images) ? images : [],
        ...property,
      };

      state.property = newProperty;
    });
    builder.addCase(fetchProperty.rejected, (state, { payload }) => {
      state.isRequestingProperty = false;
      state.errorMessageProperty = payload as string;
    });
    builder.addCase(fetchProperties.pending, (state) => {
      state.isRequestingProperties = true;
      state.errorMessageProperties = null;
    });
    builder.addCase(fetchProperties.fulfilled, (state, { payload }) => {
      state.isRequestingProperties = false;
      state.properties = payload;
    });
    builder.addCase(fetchProperties.rejected, (state, { payload }) => {
      state.isRequestingProperties = false;
      state.errorMessageProperties = payload as string;
    });
    builder.addCase(fetchPropertyDocuments.pending, (state) => {
      state.isRequestingDocuments = true;
      state.errorMessageDocuments = null;
    });
    builder.addCase(fetchPropertyDocuments.fulfilled, (state, { payload }) => {
      state.isRequestingDocuments = false;
      state.documents = payload;
    });
    builder.addCase(fetchPropertyDocuments.rejected, (state, { payload }) => {
      state.isRequestingDocuments = false;
      state.errorMessageDocuments = payload as string;
    });
    builder.addCase(fetchPropertyStatistics.pending, (state) => {
      state.isRequestingStatistics = true;
      state.errorMessageStatistics = null;
    });
    builder.addCase(fetchPropertyStatistics.fulfilled, (state, { payload }) => {
      state.isRequestingStatistics = false;

      const { revenueDistributions, ...propertyStatistics } = payload;
      // Sort revenues by oldest -> newest
      const sortedRevenueDistributions = revenueDistributions.sort((a, b) =>
        a.date > b.date ? 1 : -1
      );
      const newPropertyStatistics: PropertyStatistics = {
        revenueDistributions: sortedRevenueDistributions,
        ...propertyStatistics,
      };

      state.statistics = newPropertyStatistics;
    });
    builder.addCase(fetchPropertyStatistics.rejected, (state, { payload }) => {
      state.isRequestingStatistics = false;
      state.errorMessageStatistics = payload as string;
    });
    builder.addCase(fetchFeaturedProperties.pending, (state) => {
      state.isRequestingFeaturedProperties = true;
      state.errorMessageFeaturedProperties = null;
    });
    builder.addCase(fetchFeaturedProperties.fulfilled, (state, { payload }) => {
      state.isRequestingFeaturedProperties = false;
      state.featuredProperties = payload;
    });
    builder.addCase(fetchFeaturedProperties.rejected, (state, { payload }) => {
      state.isRequestingFeaturedProperties = false;
      state.errorMessageFeaturedProperties = payload as string;
    });
    builder.addCase(fetchOfferingProperties.pending, (state) => {
      state.isRequestingOfferingProperties = true;
      state.errorMessageOfferingProperties = null;
    });
    builder.addCase(fetchOfferingProperties.fulfilled, (state, { payload }) => {
      state.isRequestingOfferingProperties = false;
      state.offeringProperties = payload;
    });
    builder.addCase(fetchOfferingProperties.rejected, (state, { payload }) => {
      state.isRequestingOfferingProperties = false;
      state.errorMessageOfferingProperties = payload as string;
    });
    builder.addCase(fetchPropertyIssuer.pending, (state) => {
      state.isRequestingIssuer = true;
      state.errorMessageIssuer = null;
    });
    builder.addCase(fetchPropertyIssuer.fulfilled, (state, { payload }) => {
      state.isRequestingIssuer = false;
      state.issuer = { ...payload, meta: { fetchingComplete: true } };
    });
    builder.addCase(fetchPropertyIssuer.rejected, (state, { payload }) => {
      state.isRequestingIssuer = false;
      state.issuer.meta = { fetchingComplete: true };
      state.errorMessageIssuer = payload as string;
    });
    builder.addCase(fetchPropertyOnChain.pending, (state) => {
      state.isRequestingOnChainProperty = true;
      state.errorMessageRequestOnChainProperty = null;
    });
    builder.addCase(fetchPropertyOnChain.fulfilled, (state, { payload }) => {
      state.isRequestingOnChainProperty = false;
      // We need to copy the array first, update it and then set the full array as new state.
      // Otherwise the state is not successful updated.
      const newOnChainProperties = { ...state.onChainProperties };
      newOnChainProperties[payload.id] = payload;
      state.onChainProperties = newOnChainProperties;
    });
    builder.addCase(fetchPropertyOnChain.rejected, (state, { payload }) => {
      state.isRequestingOnChainProperty = false;
      state.errorMessageRequestOnChainProperty = payload as string;
    });
    builder.addCase(fetchOfferingOnChain.pending, (state) => {
      state.isRequestingOnChainOffering = true;
      state.errorMessageRequestOnChainOffering = null;
    });
    builder.addCase(fetchOfferingOnChain.fulfilled, (state, { payload }) => {
      state.isRequestingOnChainOffering = false;
      const newOnChainOfferings = { ...state.onChainOfferings };
      newOnChainOfferings[payload.id] = payload;
      state.onChainOfferings = newOnChainOfferings;
    });
    builder.addCase(fetchOfferingOnChain.rejected, (state, { payload }) => {
      state.isRequestingOnChainOffering = false;
      state.errorMessageRequestOnChainOffering = payload as string;
    });
    builder.addCase(fetchPropertiesOnChain.pending, (state) => {
      state.isRequestingOnChainProperties = true;
      state.onChainPropertiesRequestError = null;
    });
    builder.addCase(fetchPropertiesOnChain.fulfilled, (state, { payload }) => {
      state.isRequestingOnChainProperties = false;
      state.onChainProperties = payload;
    });
    builder.addCase(fetchPropertiesOnChain.rejected, (state, { payload }) => {
      state.isRequestingOnChainProperties = false;
      state.onChainPropertiesRequestError = payload as string;
    });
    builder.addCase(fetchOfferingsOnChain.pending, (state) => {
      state.isRequestingOnChainOfferings = true;
      state.onChainOfferingsRequestError = null;
    });
    builder.addCase(fetchOfferingsOnChain.fulfilled, (state, { payload }) => {
      state.isRequestingOnChainOfferings = false;
      state.onChainOfferings = payload;
    });
    builder.addCase(fetchOfferingsOnChain.rejected, (state, { payload }) => {
      state.isRequestingOnChainOfferings = false;
      state.onChainOfferingsRequestError = payload as string;
    });
  },
});

/**
 * Receive the featured properties
 *
 */
export const fetchProperties = createAppAsyncThunk(
  'properties/properties',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<SimpleProperty>>('/property', {
        params: {
          type: 'MARKETPLACE',
        },
      })
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive a single property
 *
 */
export const fetchProperty = createAppAsyncThunk(
  'properties/property',
  async (propertyAddress: string, { dispatch, getState, rejectWithValue }) => {
    const { properties } = getState();
    if (properties.property.token.address === propertyAddress)
      return rejectWithValue('Property already fetched');

    dispatch(fetchPropertyOnChain(propertyAddress));
    return backendAPI
      .get<PropertyRaw>(`/property/${propertyAddress}`)
      .then((response) => {
        if (!response.data) {
          const errorMessage = i18nInstance.t('property_not_found');
          dispatch(createNotification(errorMessage, 'error'));
          return rejectWithValue(errorMessage);
        }
        return response.data;
      })
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the documents for a single property
 *
 */
export const fetchPropertyDocuments = createAppAsyncThunk(
  'properties/propertyDocuments',
  async (propertyAddress: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<PropertyDocument>>(`/property/${propertyAddress}/documents`)
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the statistics  for a single property
 *
 */
export const fetchPropertyStatistics = createAppAsyncThunk(
  'properties/propertyStatistics',
  async (propertyAddress: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<PropertyStatistics>(`/property/${propertyAddress}/statistics`)
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the featured properties
 *
 */
export const fetchFeaturedProperties = createAppAsyncThunk<Array<SimpleProperty>>(
  'properties/featuredProperties',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<SimpleProperty>>('/property', {
        params: {
          type: 'FEATURED',
        },
      })
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the properties on offering
 *
 */
export const fetchOfferingProperties = createAppAsyncThunk(
  'properties/offeringProperties',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<SimpleProperty>>('/property', {
        params: {
          type: 'OFFERING',
        },
      })
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the issuer for a single property
 *
 */
export const fetchPropertyIssuer = createAppAsyncThunk(
  'properties/propertyIssuer',
  async (propertyAddress: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<PropertyIssuerRequest>(`/property/${propertyAddress}/licenced-issuer`)
      .then(({ data }) => new IssuerData(data))
      .catch(() => {
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the onchain informations for a property from the subgraph
 *
 */
export const fetchPropertyOnChain = createAppAsyncThunk(
  'properties/propertyOnChain',
  async (tokenAddress: string, { dispatch, rejectWithValue }) => {
    return gqlclient
      .query<{ property: OnChainPropertyRaw | null }>({
        query: GET_PROPERTY,
        variables: {
          propertyId: tokenAddress.toLowerCase(),
        },
      })
      .then((result) => {
        const {
          data: { property: propertyRaw },
        } = result;
        if (!propertyRaw) return rejectWithValue('Property not found');
        return new OnChainProperty(propertyRaw);
      })
      .catch(() => {
        const errorMessage = i18nInstance.t(
          genericErrorReasons.blockchain_indexed_data_fetch_error
        );
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the onchain informations of properties from subgraph
 *
 */
export const fetchPropertiesOnChain = createAppAsyncThunk(
  'properties/propertiesOnChain',
  async (_, { dispatch, rejectWithValue }) => {
    return gqlclient
      .query<OnChainPropertiesRaw>({
        query: GET_PROPERTIES,
      })
      .then((result) => {
        const {
          data: { properties: propertiesRaw },
        } = result;

        const properties: OnChainProperties = {};
        if (propertiesRaw) {
          propertiesRaw.forEach((propertyRaw) => {
            properties[propertyRaw.id] = new OnChainProperty(propertyRaw);
          });
        }
        return properties;
      })
      .catch(() => {
        const errorMessage = i18nInstance.t(
          genericErrorReasons.blockchain_indexed_data_fetch_error
        );
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the onchain informations for a offering from subgraph
 *
 */
export const fetchOfferingOnChain = createAppAsyncThunk(
  'properties/offeringOnChain',
  async (tokenAddress: string, { dispatch, rejectWithValue }) => {
    return gqlclient
      .query<{ propertyOffering: OnChainOfferingRaw }>({
        query: GET_OFFERING,
        variables: {
          offeringId: tokenAddress.toLowerCase(),
        },
      })
      .then((result) => {
        const {
          data: { propertyOffering: offering },
        } = result;
        if (!offering) return rejectWithValue('Offering not found');

        return new OnChainOffering(offering);
      })
      .catch(() => {
        const errorMessage = i18nInstance.t(
          genericErrorReasons.blockchain_indexed_data_fetch_error
        );
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Receive the onchain informations of offerings from subgraph
 *
 */
export const fetchOfferingsOnChain = createAppAsyncThunk(
  'properties/offeringsOnChain',
  async (_, { dispatch, rejectWithValue }) => {
    return gqlclient
      .query<OnChainOfferingsRaw>({
        query: GET_OFFERINGS,
      })
      .then((result) => {
        const {
          data: { propertyOfferings },
        } = result;

        const onChainOfferings: OnChainOfferings = {};
        if (propertyOfferings) {
          propertyOfferings.forEach((offering) => {
            onChainOfferings[offering.id] = new OnChainOffering(offering);
          });
        }
        return onChainOfferings;
      })
      .catch(() => {
        const errorMessage = i18nInstance.t(
          genericErrorReasons.blockchain_indexed_data_fetch_error
        );
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

export function resetSelectedProperty() {
  return async (dispatch) => {
    dispatch(selectedPropertyStateReset());
  };
}

export const { propertyClearLastError, selectedPropertyStateReset, resetIssuerData } =
  propertiesSlice.actions;
export default propertiesSlice.reducer;
