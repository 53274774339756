import type { ReactNode, FC, CSSProperties } from 'react';
import styled from 'styled-components';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { LighthouseSVG } from 'components/icons';

interface ZeroItemsLayoutProps {
  size?: number;
  lightTheme?: boolean;
  direction?: 'row' | 'column';
  style?: CSSProperties;
  desc?: string;
  hint?: string;
  align?: string;
  children?: ReactNode;
  Icon?: typeof LighthouseSVG;
}

interface ZeroItemsLayoutGridProps {
  size?: number;
  light_theme?: string;
  align?: string;
}

interface TypographyWrapperProps {
  size?: number;
  light_theme?: string;
}

export const ZeroItemsLayout = styled(Grid)<ZeroItemsLayoutGridProps>`
  padding: ${(props) => (props.size || 12) / 6}rem;
  flex: 1;
  flex-wrap: nowrap;
  max-width: 40em;
  margin: 0 auto;
  opacity: ${(props) => (props.light_theme === 'true' ? '1' : '0.8')};
`;

export const TypographyWrapper = styled(Grid)<TypographyWrapperProps>`
  padding: ${(props) => (props.size || 12) / 6}rem;
  color: ${(props) =>
    props.light_theme === 'true' ? props.theme.palette.primary.contrastText : 'inherit'};
`;

export const HeaderTypography = styled(Typography)`
  font-weight: bold;
  font-size: 1.1em;
`;

const ZeroItemsLayoutLayout: FC<ZeroItemsLayoutProps> = (props) => {
  const theme = useTheme();

  const {
    desc,
    hint,
    direction,
    size,
    style,
    children,
    lightTheme = false,
    Icon = LighthouseSVG,
  } = props;

  const fillColor = lightTheme ? theme.palette.secondary.main : theme.palette.primary.main;
  const strokeColor = lightTheme ? theme.palette.secondary.main : 'rgba(0,0,0, 0.87)';
  return (
    <ZeroItemsLayout
      style={style}
      direction={direction || 'column'}
      align={direction === 'row' ? 'left' : 'center'}
      alignItems="center"
      justifyContent="center"
      container
      item
      size={size}
      light_theme={lightTheme.toString()}
    >
      <Grid>
        <Icon
          fillColor={fillColor}
          strokeColor={strokeColor}
          width={`${size || '12'}em`}
          height={`${size || '12'}em`}
        />
      </Grid>

      <TypographyWrapper size={size} light_theme={lightTheme.toString()}>
        {desc && <HeaderTypography>{desc}</HeaderTypography>}
        {hint && <Typography variant="body1">{hint}</Typography>}
      </TypographyWrapper>
      {children}
    </ZeroItemsLayout>
  );
};

export default ZeroItemsLayoutLayout;
