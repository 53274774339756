export function extractErrorMessage(e: unknown): string {
  let errorMessage;

  if (typeof e === 'string') {
    errorMessage = e;
  } else if (e instanceof Error) {
    errorMessage = e.message;
  }

  return errorMessage;
}
