import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { resetState } from 'utils/state';
import { gqlclient, GET_COMPANY_QUERY } from 'query';
import { RESET_STATE } from './sharedActions';
import type {
  CompanyCPBytesGraphRequest,
  CompanyConfigurationRequest,
  CompanyDefaultRouteRequest,
  CompanyDetailsRequest,
  CompanyState,
  CompanyTermsAndPolicyRequest,
  Languages,
} from 'types';
import {
  CurrencyTypes,
  NOTIFICATION_TYPES,
  configFetchErrorTypes,
  createAppAsyncThunk,
  genericErrorReasons,
} from 'types';

const initialState: CompanyState = {
  defaultCompanyRoute: '',
  company: 'blocksquare',
  cpBytes: '',
  policy: '',
  terms: '',
  languages: [],
  offeringCurrency: CurrencyTypes.EUR,
  configuration: {
    title: '',
    images: {
      brandImage: '',
      faviconImage: '',
    },
    theme: null,
    defaultLanguage: 'en',
  },
  companyConfigurationFetchError: false,
  companyConfigurationFetchErrorType: '',
  defaultCompanyRouteFetchError: false,
  defaultCompanyRouteNotFound: false,
  defaultCompanyRouteRequestProcessed: false,
  isFetchingDefaultCompanyRoute: false,
  isUpdatingCompany: false,
  updateCompanyErrorMessage: null,
  isRequestingOnChainData: false,
  onChainRequestErrorMessage: null,
  isRequestingLanguages: false,
  languagesRequestError: null,
  isRequestingTermsAndPolicy: false,
  errorRequestingTermsAndPolicy: null,
  isRequestingCurrency: false,
  errorRequestingCurrency: null,
  isRequestingCompanyDetails: false,
  errorRequestingCompanyDetails: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyChangeRequest(state) {
      state.isUpdatingCompany = true;
    },
    companyChangeSuccess(state, { payload }: PayloadAction<{ company: string }>) {
      const { company } = payload;
      state.company = company;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = null;
    },
    companyChangeFailure(state, { payload }: PayloadAction<{ reason: string }>) {
      const { reason } = payload;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = reason;
    },
    companyOnChainRequest(state) {
      state.isRequestingOnChainData = true;
    },
    companyOnChainSuccess(state, { payload }: PayloadAction<{ cpBytes: string }>) {
      const { cpBytes } = payload;
      state.cpBytes = cpBytes;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = null;
    },
    companyOnChainFailure(state, { payload }: PayloadAction<{ reason: string }>) {
      const { reason } = payload;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = reason;
    },
    companyLanguageRequest(state) {
      state.isRequestingLanguages = true;
    },
    companyLanguageSuccess(state, { payload }: PayloadAction<{ languages: Languages }>) {
      const { languages } = payload;
      state.languages = languages;
      state.isRequestingLanguages = false;
    },
    companyLanguageFailure(state, { payload }: PayloadAction<{ reason: string }>) {
      const { reason } = payload;
      state.languagesRequestError = reason;
      state.isRequestingLanguages = false;
    },
    companyConfigurationFetchSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        companyId: string;
        data: CompanyConfigurationRequest;
      }>
    ) {
      const {
        data: { brandColor, brandImage, faviconImage, title, defaultLanguage },
        companyId,
      } = payload;

      // We must reset the error status in case this success was
      // triggered by a retry.
      state.companyConfigurationFetchError = false;
      state.configuration = {
        title,
        images: { brandImage, faviconImage },
        theme: { identifier: companyId, brandColor },
        defaultLanguage,
      };
    },
    companyConfigurationFetchFailure(state, { payload }: PayloadAction<{ errorType: string }>) {
      const { errorType } = payload;

      state.companyConfigurationFetchErrorType = errorType;
      state.companyConfigurationFetchError = true;
    },
    companyConfigurationFetchRetry(state) {
      state.companyConfigurationFetchErrorType = '';
    },
    defaultCompanyRouteFetchSuccess(state, { payload }: PayloadAction<{ defaultRoute: string }>) {
      const { defaultRoute } = payload;

      state.defaultCompanyRoute = defaultRoute;
      state.isFetchingDefaultCompanyRoute = false;
      state.defaultCompanyRouteRequestProcessed = true;
    },
    defaultCompanyRouteFetchFailure(state) {
      state.isFetchingDefaultCompanyRoute = false;
      state.defaultCompanyRouteFetchError = true;
      state.defaultCompanyRouteRequestProcessed = true;
    },
    defaultCompanyRouteNotFound(state) {
      // Invoking this reducer will show a 404 page in our app
      state.isFetchingDefaultCompanyRoute = false;
      state.defaultCompanyRouteNotFound = true;
      state.defaultCompanyRouteRequestProcessed = true;
    },
    defaultCompanyRouteNotSet(state) {
      state.isFetchingDefaultCompanyRoute = false;
      state.defaultCompanyRouteRequestProcessed = true;
    },
    defaultCompanyRouteFetchRequest(state) {
      state.isFetchingDefaultCompanyRoute = true;
      state.defaultCompanyRouteRequestProcessed = false;
    },
    defaultCompanyRouteFetchRetry(state) {
      state.defaultCompanyRouteFetchError = false;
    },
    companyCurrencyRequest(state) {
      state.isRequestingCurrency = true;
    },
    companyCurrencySuccess(state, { payload }: PayloadAction<{ offeringCurrency: CurrencyTypes }>) {
      const { offeringCurrency } = payload;
      state.offeringCurrency = offeringCurrency;
      state.isRequestingCurrency = false;
    },
    companyCurrencyFailure(state, { payload }: PayloadAction<{ reason: string }>) {
      const { reason } = payload;
      state.errorRequestingCurrency = reason;
      state.isRequestingCurrency = false;
    },
    companyDetailsFailure(state, { payload }: PayloadAction<{ reason: string }>) {
      const { reason } = payload;
      state.errorRequestingCompanyDetails = reason;
      state.isRequestingCompanyDetails = false;
    },
    companyDetailsRequest(state, { payload }: PayloadAction<{ isRequesting: boolean }>) {
      const { isRequesting } = payload;

      state.isRequestingCompanyDetails = isRequesting;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchCompanyTermsAndPolicy.pending, (state) => {
      state.isRequestingTermsAndPolicy = true;
    });
    builder.addCase(fetchCompanyTermsAndPolicy.fulfilled, (state, { payload }) => {
      state.terms = payload.terms;
      state.policy = payload.policy;
      state.isRequestingTermsAndPolicy = false;
    });
    builder.addCase(fetchCompanyTermsAndPolicy.rejected, (state, { payload }) => {
      state.isRequestingTermsAndPolicy = false;
      state.errorRequestingTermsAndPolicy = payload as string;
    });
  },
});

/**
 * Fetch the company default route
 *
 */
export const getCompanyDefaultRoute = createAppAsyncThunk(
  'user/getCompanyDefaultRoute',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(defaultCompanyRouteFetchRequest());
    return backendAPI
      .get<CompanyDefaultRouteRequest | string>('company/handle')
      .then((response) => {
        const { data, status } = response;

        // There can be a case where the default handle isn't set for a domain
        // and then we just move on without it.
        // In case a user  consequently tries to access the Root route without the company
        // identifier, we show a 404. We will also show the 404 page if
        // the handle exists in the response but is empty.
        const defaultHandleNotSet = typeof data === 'string' && data === '' && status === 204;

        if (defaultHandleNotSet) {
          dispatch(defaultCompanyRouteNotSet());
        } else if (typeof data !== 'string' && data.handle) {
          const { handle } = data;
          dispatch(defaultCompanyRouteFetchSuccess({ defaultRoute: handle }));
        } else {
          dispatch(defaultCompanyRouteNotFound());
        }
      })
      .catch(() => {
        const error = i18nInstance.t('default_company_route_fetching_error');
        dispatch(createNotification(error, 'error'));
        // The following should render an error screen in the Routes component
        dispatch(defaultCompanyRouteFetchFailure());
      });
  }
);

/**
 * Set the company identifier and fetch the company details
 *
 * @param company - The company identifier
 *
 */
export const setCompany = createAppAsyncThunk(
  'user/setCompany',
  async (company: string, { dispatch, getState }) => {
    dispatch(companyChangeRequest());
    const {
      authentication: { site, token },
      company: {
        company: currentCompany,
        terms,
        policy,
        configuration: { theme },
      },
    } = getState();

    // Destroy active session and reset state
    if (token && (!site || company !== site)) {
      return dispatch(resetState());
    }

    if (company !== currentCompany || !policy || !terms) {
      dispatch(fetchCompanyTermsAndPolicy(company));
    }

    // TODO
    // I will probably need to add a few more conditions because
    // the endpoint contacted by this function is going to provide
    // so much more than just the theme and brand images
    if (company !== currentCompany || !theme) {
      dispatch(getCompanyConfiguration(company));
    }

    return dispatch(companyChangeSuccess({ company }));
  }
);

/**
 * Fetch the company details from the backend and on-chain
 *
 */
export const getCompanyDetails = createAppAsyncThunk(
  'user/getCompanyDetails',
  async (_, { dispatch, getState }) => {
    const {
      company: { company: currentCompany },
    } = getState();

    dispatch(getBackendCompanyDetails());

    dispatch(companyOnChainRequest());
    gqlclient
      .query<CompanyCPBytesGraphRequest>({
        query: GET_COMPANY_QUERY,
        variables: {
          companyName: currentCompany,
        },
      })
      .then((result) => {
        const {
          data: { certifiedPartners },
        } = result;
        if (certifiedPartners && certifiedPartners.length > 0) {
          const certifiedPartner = certifiedPartners[0];
          dispatch(companyOnChainSuccess({ cpBytes: certifiedPartner.id }));
        }
      })
      .catch((error) => {
        dispatch(companyOnChainFailure({ reason: error.message }));
      });
  }
);

// TODO
// This function should expand so that it can provide the app with all of the
// necessary company configuration so that we don't have to rely on multiple endpoints.
// e.g. we will get terms and policy from this endpoint as well
/**
 * Fetch the company configuration
 *
 *  @param company - The company identifier
 *
 */
const getCompanyConfiguration = createAppAsyncThunk(
  'user/getCompanyConfiguration',
  async (company: string, { dispatch }) => {
    const { invalidData, serverError } = configFetchErrorTypes;
    return backendAPI
      .get<CompanyConfigurationRequest>(`/company/${company}/configuration`)
      .then((response) => {
        const {
          data: { brandColor, brandImage, faviconImage, title, defaultLanguage },
        } = response;

        if (!brandColor || !brandImage || !faviconImage || !title || !defaultLanguage) {
          const error = i18nInstance.t('theme_configuration_fetching_insufficient_data');
          dispatch(createNotification(error, 'error'));
          dispatch(companyConfigurationFetchFailure({ errorType: invalidData }));
        } else {
          dispatch(companyConfigurationFetchSuccess({ data: response.data, companyId: company }));
        }
      })
      .catch(() => {
        const error = i18nInstance.t('theme_configuration_fetching_error');
        dispatch(createNotification(error, 'error'));
        dispatch(companyConfigurationFetchFailure({ errorType: serverError }));
      });
  }
);

/**
 * Fetch the company terms and policy
 *
 *  @param company - The company identifier
 *
 */
const fetchCompanyTermsAndPolicy = createAppAsyncThunk(
  'company/termsAndPolicy',
  async (company: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<CompanyTermsAndPolicyRequest>(`/company/${company}/terms-policy`)
      .then(({ data }) => data)
      .catch(() => {
        const networkError = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Fetch the company details from the backend
 *
 */
const getBackendCompanyDetails = createAppAsyncThunk(
  'user/getBackendCompanyDetails',
  async (_, { dispatch }) => {
    dispatch(companyDetailsRequest({ isRequesting: true }));
    return backendAPI
      .get<CompanyDetailsRequest>(`/company/details`)
      .then((response) => {
        const {
          data: { languages, defaultCurrency },
        } = response;

        // Language setting section
        if (languages.length > 0) {
          const tempLanguages = languages.map((languageData) => {
            const { language, code: countryCode } = languageData;
            return {
              language: language.toLowerCase(),
              countryCode,
            };
          });
          dispatch(companyLanguageSuccess({ languages: tempLanguages }));
        } else {
          const errorMessage = i18nInstance.t('marketplace_information_fetch_error');
          dispatch(companyLanguageFailure({ reason: errorMessage }));
          dispatch(createNotification(errorMessage, 'error'));
        }
        // //////

        // Offering currency setting section
        if (defaultCurrency) {
          dispatch(companyCurrencySuccess({ offeringCurrency: defaultCurrency as CurrencyTypes }));
        } else {
          const errorMessage = i18nInstance.t('marketplace_information_fetch_error');
          dispatch(companyCurrencyFailure({ reason: errorMessage }));
          dispatch(createNotification(errorMessage, 'error'));
        }
        // //////

        // StableCoin setting section
        // StableCoin section is WIP
        // We plan to use the preferred CP's stablecoin for displaying
        // prices and support payments with it.
        // //////
        dispatch(companyDetailsRequest({ isRequesting: false }));
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = i18nInstance.t('marketplace_information_fetch_error');
        dispatch(companyDetailsFailure({ reason: errorMessage }));
        dispatch(createNotification(errorMessage, 'error'));
      });
  }
);

export const {
  companyChangeRequest,
  companyChangeSuccess,
  companyChangeFailure,
  companyOnChainRequest,
  companyOnChainSuccess,
  companyOnChainFailure,
  companyLanguageRequest,
  companyLanguageSuccess,
  companyLanguageFailure,
  companyConfigurationFetchSuccess,
  companyConfigurationFetchFailure,
  companyConfigurationFetchRetry,
  defaultCompanyRouteFetchRequest,
  defaultCompanyRouteFetchSuccess,
  defaultCompanyRouteFetchFailure,
  defaultCompanyRouteFetchRetry,
  defaultCompanyRouteNotFound,
  defaultCompanyRouteNotSet,
  companyCurrencyRequest,
  companyCurrencySuccess,
  companyCurrencyFailure,
  companyDetailsFailure,
  companyDetailsRequest,
} = companySlice.actions;
export default companySlice.reducer;
