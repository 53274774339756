import type { InterfaceAbi } from 'ethers';
export const OfferingInvestmentAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'dataProxy',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pricePerToken',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maxInvestment',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'minInvestment',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'presaleStart',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'presaleEnd',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'saleStart',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'saleEnd',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'collector',
        type: 'address',
      },
    ],
    name: 'InitialOffer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'wallet',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'investmentToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountInvested',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountReceived',
        type: 'uint256',
      },
    ],
    name: 'Investment',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'dataProxy',
        type: 'address',
      },
    ],
    name: 'changeDataProxy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'maxInvestment',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'minInvestment',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'softCap',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'presaleStart',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'presaleEnd',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'saleEnd',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'collector',
        type: 'address',
      },
    ],
    name: 'modifyOfferingInfo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'invest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
    ],
    name: 'collectInvestments',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'wallets',
        type: 'address[]',
      },
    ],
    name: 'returnInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
    ],
    name: 'currentSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
    ],
    name: 'hardCap',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
    ],
    name: 'offeringInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'presaleStart',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'presaleEnd',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'saleStart',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'saleEnd',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amountCollected',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxInvestment',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minInvestment',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'softCap',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'investmentToken',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'collector',
            type: 'address',
          },
          {
            internalType: 'bool',
            name: 'investmentCollected',
            type: 'bool',
          },
        ],
        internalType: 'struct InitialInvestment.Offering',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'property',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'wallet',
        type: 'address',
      },
    ],
    name: 'currentInvestmentByUser',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as InterfaceAbi;
