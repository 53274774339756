import type { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import type { TBaseProps as Props } from 'types';

const InfoValue = styled(Typography)`
  font-weight: 600;
  font-size: 1.5rem;
  font-family: 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  line-height: 1.2;
`;

const InfoValueTypography: FC<Props> = ({ style, children, align, color, className }) => {
  return (
    <InfoValue className={className} style={style} align={align} color={color}>
      {children}
    </InfoValue>
  );
};

export default InfoValueTypography;
