import { formatEther } from 'ethers';
import { type OnChainProperty, type OnChainOffering } from 'types/store';
import { MAX_SUPPLY, PRICE_DECIMAL_MULTIPLIER } from './constants';

export function displayHexString(address: string, length = 6): string {
  if (!address) return '';

  const addressLength = address.length || 0;

  const firstPart = address.substring(0, length) || '';
  const lastPart = address.substring(addressLength - length, addressLength) || '';
  return `${firstPart}...${lastPart}`;
}

/**
 *
 * @param property - on chain property
 * @returns current valuation ( combo of tokenized & property valuation )
 *
 * More abstract version of `calculateCurrentValuation` due to allowing you
 * to just pass in the `onChainProperty`.
 *
 */
export function getCurrentValuation(property: OnChainProperty) {
  if (!property) return 0;

  const {
    token: { totalSupply: totalSupplyWEI },
    tokenValuation: tokenValuationWEI,
    propertyValuation: propertyValuationWEI,
  } = property;

  const totalSupply = totalSupplyWEI ? formatBigInt(totalSupplyWEI) : 0;
  const tokenValuation = tokenValuationWEI ? formatBigInt(tokenValuationWEI) : 0;
  const propertyValuation = propertyValuationWEI ? formatBigInt(propertyValuationWEI) : 0;

  return calculateCurrentValuation(tokenValuation, propertyValuation, totalSupply);
}

/**
 *
 * @param tokenValuation
 * @param propertyValuation
 * @param totalSupply
 * @returns current property valuation ( used primarily for properties traded on the secondary marketplace )
 *
 * Takes raw variable inputs and gives back the 'current valuation' formula result which takes
 * into account both the traditional property valuation and the more volatile token valuation which is
 * determined by the CP platform's marketplace trading activities for the property.
 *
 * The amount of influence the 'token valuation' has on the 'current valuation' is determined by
 * the total supply of tokens - percentage of the property that is tokenized & traded.
 *
 */
export function calculateCurrentValuation(
  tokenValuation: number,
  propertyValuation: number,
  totalSupply: number
): number {
  const result =
    (tokenValuation * totalSupply + propertyValuation * (MAX_SUPPLY - totalSupply)) / MAX_SUPPLY;

  return result || 0;
}

export function getCurrentPropertyUserTokenValuation(
  tokenValuation: number,
  propertyValuation: number,
  totalSupply: number,
  userTokenAmount: number
): number {
  const currentPropertyValue: number = calculateCurrentValuation(
    tokenValuation,
    propertyValuation,
    totalSupply
  );
  return (currentPropertyValue / MAX_SUPPLY) * userTokenAmount;
}

/**
 *
 * @param onChainOffering - indexed offering property information object
 * @returns Offering valuation ( price per BSPT token multiplied by the maximum supply )
 *
 * Takes in the onChainOffering object that consists of indexed blockchain data and returns back
 * the valuation of the offering which is calculated differently to the one for a secondary marketplace
 * property.
 *
 */
export function getOfferingValuation(onChainOffering: OnChainOffering): number {
  if (!onChainOffering?.price) return 0;

  const price = onChainOffering.price / PRICE_DECIMAL_MULTIPLIER;
  const result = price * MAX_SUPPLY;

  return result;
}

export function formatBigInt(valuation: bigint | string | number): number {
  const valuationBI =
    typeof valuation === 'string' || typeof valuation === 'number' ? BigInt(valuation) : valuation;
  // Check for bigger 0 to prevent zero division
  return valuationBI > 0n ? Number(formatEther(valuationBI)) : 0;
}
