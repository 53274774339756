import type { AdaptedPortfolioProperty, PropertyDocument } from 'types/store';
type Element = AdaptedPortfolioProperty | PropertyDocument;
export const desc = (a: Element, b: Element, orderBy: string): 1 | -1 | 0 => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
