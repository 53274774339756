import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const WalletSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 48 44.875"
    >
      <path
        d="M43.821,44.876 L4.051,44.876 C1.814,44.876 -0.005,43.053 -0.005,40.812 L-0.005,13.061 C-0.005,10.819 1.763,8.875 4.000,8.875 L6.039,8.875 L6.039,3.548 C6.039,2.442 6.495,1.432 7.290,0.778 C8.081,0.126 9.154,-0.130 10.233,0.082 L43.952,4.572 C47.501,5.351 47.841,7.545 47.873,7.909 C47.873,7.912 47.873,7.916 47.874,7.919 C47.875,7.937 47.876,7.952 47.876,7.957 L47.876,8.647 C47.942,8.708 48.000,8.770 48.000,8.875 L48.000,40.875 C48.000,43.116 46.057,44.876 43.821,44.876 ZM43.605,6.534 L9.911,2.049 C9.353,1.942 8.893,2.041 8.559,2.316 C8.221,2.594 8.035,3.032 8.035,3.548 L8.035,8.875 L42.000,8.875 C43.849,8.875 45.383,8.554 45.829,7.859 C45.699,7.574 45.236,6.893 43.605,6.534 ZM46.000,10.875 C45.073,11.303 43.452,10.875 42.000,10.875 L4.000,10.875 C2.863,10.875 1.990,11.919 1.990,13.061 L1.990,40.812 C1.990,41.953 2.914,42.882 4.051,42.882 L43.821,42.882 C44.957,42.882 46.000,42.017 46.000,40.875 L46.000,10.875 ZM7.975,29.996 C6.325,29.996 4.982,28.651 4.982,26.998 C4.982,25.346 6.325,24.001 7.975,24.001 C9.625,24.001 10.968,25.346 10.968,26.998 C10.968,28.651 9.625,29.996 7.975,29.996 ZM7.975,25.995 C7.425,25.995 6.978,26.445 6.978,26.998 C6.978,27.551 7.425,28.002 7.975,28.002 C8.526,28.002 8.973,27.551 8.973,26.998 C8.973,26.445 8.526,25.995 7.975,25.995 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default WalletSVG;
