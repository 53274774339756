import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs } from 'store/dialogs';
import { backendAPI, createNotificationByType, i18nInstance } from 'utils';
import { RESET_STATE } from './sharedActions';
import type { UserBankAccountState, UserBankAccountUpdateData, RequestStatus } from 'types';
import { ApiStatus, NOTIFICATION_TYPES, createAppAsyncThunk } from 'types';

const initialState: UserBankAccountState = {
  bankAccountType: '',
  bankAddress: '',
  bankBIC: '',
  bankName: '',
  country: '',
  fetchError: null,
  iban: '',
  isFetching: false,
  isInitialFetchingDone: false,
  isInitialState: true,
  isUpdating: false,
  name: '',
  updateErrorMessage: null,
};

const userBankAccountSlice = createSlice({
  name: 'userBankAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchUserBankAccount.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUserBankAccount.fulfilled, (state, { payload }) => {
      state.fetchError = null;
      state.isFetching = false;
      state.isInitialFetchingDone = true;
      const { bankAccountType, bankAddress, bankBIC, bankName, country, iban, name } = payload;
      state.bankAccountType = bankAccountType;
      state.bankAddress = bankAddress;
      state.bankBIC = bankBIC;
      state.bankName = bankName;
      state.country = country;
      state.iban = iban;
      state.name = name;

      state.isInitialState = false;
    });
    builder.addCase(fetchUserBankAccount.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isInitialFetchingDone = true;
      state.fetchError = payload as string;
    });
    builder.addCase(updateUserBankAccountRequest.pending, (state) => {
      state.isUpdating = true;
      state.updateErrorMessage = null;
    });
    builder.addCase(updateUserBankAccountRequest.fulfilled, (state) => {
      state.isUpdating = false;
    });
    builder.addCase(updateUserBankAccountRequest.rejected, (state, { payload }) => {
      state.isUpdating = false;
      state.updateErrorMessage = payload as string;
    });
  },
});

/**
 * Receive the bank account details for a given user
 *
 */
export const fetchUserBankAccount = createAppAsyncThunk(
  'userBankAccount/account',
  async (refresh: boolean, { dispatch, getState, rejectWithValue }) => {
    const { userBankAccount } = getState();

    if (!userBankAccount.isInitialState && !refresh)
      return rejectWithValue('Already fetched the user bank account');

    return backendAPI
      .get('/account/bank')
      .then(({ data }) => data)
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Update the bank account details for a given user
 *
 */
export const updateUserBankAccountRequest = createAppAsyncThunk(
  'userBankAccount/accountUpdate',
  async (bankAccountData: UserBankAccountUpdateData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/account/bank', bankAccountData)
      .then(({ data }) => {
        const { status, reason } = data;

        if (status !== ApiStatus.SUCCESS) {
          dispatch(fetchUserBankAccount(true));
          return rejectWithValue(reason as string);
        }

        dispatch(fetchUserBankAccount(true));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.BANK_ACCOUNT_SAVED));
        dispatch(hideDialogs());
        return;
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

export default userBankAccountSlice.reducer;
