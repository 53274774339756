import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import App from './App';
import { persistor, store } from './store/index';
import Loader from './components/custom/Loader';

import './fonts/font.scss';

const tagManagerArgs = {
  gtmId: 'GTM-K5NHVD3',
};

TagManager.initialize(tagManagerArgs);

if (!process.env.REACT_APP_DAI_ADDRESS) throw new Error('REACT_APP_DAI_ADDRESS not set');

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
