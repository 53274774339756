/* eslint-disable import/prefer-default-export */
import { store } from 'store';

export function getCompanyInformation() {
  const {
    company: {
      configuration: {
        title,
        theme,
        images: { brandImage, faviconImage },
      },
    },
  } = store.getState();

  const companyInformation = {
    brandColor: theme?.brandColor,
    brandImage,
    faviconImage,
    identifier: theme?.identifier,
    title,
  };

  return companyInformation;
}
