// The most important part is that each element in the array has the timestamp at the top level
interface Element {
  timestamp: string | number;
}

const newestTimestampSort = <E extends Element>(elementArray: Array<E>) => {
  if (!elementArray || !Array.isArray(elementArray)) return [];

  const sortedArray = elementArray
    .map((element) => element)
    .sort((stack1, stack2) => {
      if (!stack1.timestamp || !stack2.timestamp) {
        return 0;
      }

      const timestamp1 = +stack1.timestamp;
      const timestamp2 = +stack2.timestamp;
      return timestamp2 - timestamp1;
    });

  return sortedArray;
};

export default newestTimestampSort;
