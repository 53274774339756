import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'types/store';

export const propertiesSelector = (state: RootState) => state.properties;

export const propertyDetailRequestsProcessingSelector = createSelector(
  propertiesSelector,
  (properties) => {
    const {
      isRequestingStatistics,
      isRequestingProperty,
      isRequestingOnChainOffering,
      isRequestingOnChainProperty,
    } = properties;

    const isLoadingPropertyDetails =
      isRequestingStatistics ||
      isRequestingProperty ||
      isRequestingOnChainOffering ||
      isRequestingOnChainProperty;

    return isLoadingPropertyDetails;
  }
);

export const propertiesMetaLoadingSelector = createSelector(
  propertiesSelector,
  (properties) => properties.isRequestingProperties
);

export const statisticsSelector = createSelector(
  propertiesSelector,
  (properties) => properties.statistics
);

export const propertyDataSelector = createSelector(
  propertiesSelector,
  (properties) => properties.property
);

export const propertyTokenSelector = createSelector(
  propertiesSelector,
  (properties) => properties.property.token
);

export const propertiesDocumentsSelector = createSelector(
  propertiesSelector,
  (properties) => properties.documents
);

export const propertiesDocumentsLoadingSelector = createSelector(
  propertiesSelector,
  (properties) => properties.isRequestingDocuments
);

export const propertiesIssuerSelector = createSelector(
  propertiesSelector,
  (properties) => properties.issuer
);

export const propertiesIssuerLoadingSelector = createSelector(
  propertiesSelector,
  (properties) => properties.isRequestingIssuer
);

export const propertyHighlightsSelector = createSelector(
  propertiesSelector,
  (properties) => properties.property.highlights
);

export const propertyStatisticSelector = createSelector(
  propertiesSelector,
  (properties) => properties.property.propertyStatistic
);

export const onChainPropertiesDataSelector = createSelector(
  propertiesSelector,
  (properties) => properties.onChainProperties
);
export const onChainPropertiesLoadingSelector = createSelector(
  propertiesSelector,
  (properties) => properties.isRequestingOnChainProperties
);

export const onChainOfferingsDataSelector = createSelector(
  propertiesSelector,
  (properties) => properties.onChainOfferings
);

export const offeringPropertiesDataSelector = createSelector(
  propertiesSelector,
  (properties) => properties.offeringProperties
);

export const onChainOfferingsLoadingDataSelector = createSelector(
  propertiesSelector,
  (properties) => properties.isRequestingOnChainOffering
);
