import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const BanSVG: FC<TBaseIconProps> = ({ style, fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 48 48"
      width={width}
      height={height}
    >
      <path
        d="M24.000,48.000 C10.767,48.000 -0.000,37.233 -0.000,24.000 C-0.000,10.767 10.767,0.000 24.000,0.000 C37.233,0.000 48.000,10.767 48.000,24.000 C48.000,37.233 37.233,48.000 24.000,48.000 ZM24.000,46.000 C29.706,46.000 34.895,43.798 38.808,40.222 L7.778,9.192 C4.202,13.105 2.000,18.294 2.000,24.000 C2.000,36.131 11.869,46.000 24.000,46.000 ZM24.000,2.000 C18.294,2.000 13.105,4.202 9.192,7.778 L40.222,38.808 C43.798,34.895 46.000,29.706 46.000,24.000 C46.000,11.869 36.131,2.000 24.000,2.000 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BanSVG;
