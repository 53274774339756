import type { SnackbarKey } from 'notistack';

export type NotificationVariants = 'success' | 'error' | 'warning';

// REFACTOR NOTE
// This could be made into an enum.

// But first we must make sure that we get a compile/build error when using this
// as a function argument and a value outside of the range of the num is passed to that func
export const NOTIFICATION_TYPES = {
  BANK_ACCOUNT_SAVED: 0,
  NETWORK_ERROR: 1,
  ORDER_CREATION_ERROR: 2,
  ORDER_FILLED: 3,
  ORDER_PLACED: 4,
  PASSWORD_CHANGED: 5,
  WALLET_NAME_CHANGED: 6,
  PASSWORD_FORGET_REQUESTED: 7,
  SETTINGS_SAVED: 8,
} as const;

export type NotificationKeys = (typeof NOTIFICATION_TYPES)[keyof typeof NOTIFICATION_TYPES];

export type Notification = {
  key: SnackbarKey;
  message: string;
  options: {
    variant: NotificationVariants;
    autoHideDuration: number;
  };
};

export type NotificationState = {
  notifications: Array<Notification>;
};
