import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import type { LanguageState } from 'types';
import { ApiStatus, NOTIFICATION_TYPES, createAppAsyncThunk, genericErrorReasons } from 'types';
import { updatePreferredLanguage } from './user';

const initialState: LanguageState = {
  isUpdatingLanguage: false,
  isFetchingAvailableLanguages: false,
  updateLanguageErrorMessage: null,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeLanguage.pending, (state) => {
      state.isUpdatingLanguage = true;
      state.updateLanguageErrorMessage = null;
    });
    builder.addCase(changeLanguage.fulfilled, (state) => {
      state.isUpdatingLanguage = false;
      state.updateLanguageErrorMessage = null;
    });
    builder.addCase(changeLanguage.rejected, (state, { payload }) => {
      state.isUpdatingLanguage = false;
      state.updateLanguageErrorMessage = payload as string;
    });
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

/**
 * Change the active language on the platform
 *
 */
export const changeLanguage = createAppAsyncThunk(
  'language/changeLanguage',
  async (langCode: string, { dispatch, getState, rejectWithValue }) => {
    const {
      user: { preferredLanguage },
    } = getState();

    if (preferredLanguage !== langCode) {
      return backendAPI
        .put('/user', { language: langCode })
        .then((response) => {
          const { data } = response;
          const { status } = data;
          if (status !== ApiStatus.SUCCESS) {
            dispatch(createNotification(data.reason, 'error'));
            return rejectWithValue(data.reason);
          }

          changeI18nInstanceLanguage(langCode);
          dispatch(updatePreferredLanguage({ langCode }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.SETTINGS_SAVED));
          return;
        })
        .catch(() => {
          const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
          dispatch(createNotification(errorMessage, 'error'));
          return rejectWithValue(errorMessage);
        });
    }
    return rejectWithValue(
      "The language you're trying to change to is already the active language"
    );
  }
);

export function changeI18nInstanceLanguage(langCode) {
  i18nInstance.changeLanguage(langCode);
}

export default languageSlice.reducer;
