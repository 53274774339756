export const WALLET_TYPE = {
  METAMASK: 'METAMASK',
  TRUSTWALLET: 'TRUSTWALLET',
} as const;

export type WalletTypeKeys = (typeof WALLET_TYPE)[keyof typeof WALLET_TYPE];

export type WalletState = {
  type: WalletTypeKeys | null;
  account: string | null;
  showedDialogAlready: boolean;
  handledWalletReconnect: boolean;
};
