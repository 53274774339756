import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { configFetchErrorTypes, useAppSelector } from 'types';

/**
 * App initialization error handler.
 *
 * In case there are errors with fetching the default CP route based
 * on the domain or the theme configuration, this component
 * navigates to the correct error/404 route.
 *
 */
const InitErrorHandler = () => {
  const navigate = useNavigate();
  const {
    defaultCompanyRouteFetchError,
    defaultCompanyRouteNotFound,
    companyConfigurationFetchErrorType,
  } = useAppSelector((state) => state.company);

  useEffect(() => {
    if (
      (companyConfigurationFetchErrorType !== '' &&
        companyConfigurationFetchErrorType !== configFetchErrorTypes.serverError) ||
      defaultCompanyRouteFetchError
    ) {
      navigate('/error');
    }

    if (
      defaultCompanyRouteNotFound ||
      companyConfigurationFetchErrorType === configFetchErrorTypes.serverError
    ) {
      navigate('/404');
    }
  }, [
    companyConfigurationFetchErrorType,
    defaultCompanyRouteFetchError,
    defaultCompanyRouteNotFound,
    navigate,
  ]);

  return null;
};

export default InitErrorHandler;
