/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  useTheme,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'types';
import { tokenHistoryFetchRequest } from 'store/trade';
import { displayHexString, formatBigInt } from 'utils/format';
import { formatDaiToFiatString } from 'utils/currency';
import TableTaglineTypography from 'components/typography/TableTaglineTypography';
import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import { TableWrapper, TableBodyRow } from 'components/marketplace/TableStyledUtil';
import TablePaginationWithExtremes from 'components/custom/TablePaginationWithExtremes';
import Loader from 'components/custom/Loader';
import { propertyTokenSelector } from 'store/selectors/properties';

const PropertyChart = lazy(() => import('components/marketplace/PropertyChart'));

export const StyledTable = styled(Table)`
  background-color: ${(props) => props.theme.body.secondary.main};
  padding: 1rem;
  border-radius: 0.6rem;
`;

const TradeChartWrapper = styled(Grid)`
  background-color: ${(props) => props.theme.body.secondary};
  border-radius: 0.6rem;
  flex: 1;
`;

const TableCell = styled(MuiTableCell)`
  white-space: nowrap;
  padding: 0.3rem;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 0.7rem;
  }
  text-align: right;
  vertical-align: bottom;
`;

const Typography = styled(MuiTypography)`
  text-align: right;
`;

const TableHeadCell = styled(TableCell)`
  padding: 0.5rem;
`;

function LoaderGrid() {
  return (
    <Grid
      container
      item
      xs
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '28rem' }}
    >
      <Loader size="6rem" />
    </Grid>
  );
}

const ETHERSCAN_TRANSACTION_URL = `${process.env.REACT_APP_ETHERSCAN_URL}/tx/`;

const PropertyTradesTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const history = useAppSelector((state) => state.trade.history);
  const { address } = useAppSelector(propertyTokenSelector);

  useEffect(() => {
    dispatch(tokenHistoryFetchRequest(address));
  }, [address, dispatch]);

  const handleChangePage = (event, page) => setPage(page);
  const handleChangeRowsPerPage = (event) => setRowsPerPage(event.target.value);

  return (
    <Grid container spacing={4} item direction="column" style={{ flexWrap: 'nowrap' }}>
      {history && history.length > 1 ? (
        <Grid container item>
          <TradeChartWrapper container item>
            <Suspense fallback={<LoaderGrid />}>
              <PropertyChart />
            </Suspense>
          </TradeChartWrapper>
        </Grid>
      ) : null}
      <Grid container item style={{ height: '100%', flexGrow: '1' }}>
        <TableWrapper container item xs>
          <StyledTable aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('txn')}</Typography>
                </TableHeadCell>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('date')}</Typography>
                </TableHeadCell>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('size')}</Typography>
                </TableHeadCell>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('price')}</Typography>
                </TableHeadCell>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('volume')}</Typography>
                </TableHeadCell>
                <TableHeadCell>
                  <Typography variant="subtitle2">{t('estimated_valuation')}</Typography>
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.length > 0 ? (
                history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                  return (
                    <TableBodyRow hover tabIndex={-1} key={n.id}>
                      <TableCell>
                        <MuiLink
                          target="_blank"
                          rel="noopener noreferrer"
                          href={ETHERSCAN_TRANSACTION_URL + n.transactionHash}
                          style={{ color: theme.palette.primary.main }}
                        >
                          {displayHexString(n.transactionHash)}
                        </MuiLink>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{n.transactionDate}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {`${formatBigInt(n.cost).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} BSPT`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TableTaglineTypography align="right">
                          {formatDaiToFiatString(n.price, 2)}
                        </TableTaglineTypography>
                        <Typography variant="body1">
                          {`${n.price.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} DAI`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TableTaglineTypography align="right">
                          {formatDaiToFiatString(formatBigInt(n.volume), 2)}
                        </TableTaglineTypography>
                        <Typography variant="body1">
                          {`${formatBigInt(n.volume).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} DAI`}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ paddingRight: '0.5rem' }}>
                        <TableTaglineTypography align="right">
                          {formatDaiToFiatString(n.valuation, 2)}
                        </TableTaglineTypography>
                        <Typography variant="body1">
                          {`${n.valuation.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} DAI`}
                        </Typography>
                      </TableCell>
                    </TableBodyRow>
                  );
                })
              ) : (
                <TableBodyRow tabIndex={-1} key="noTradesFound" style={{ borderBottom: 'none' }}>
                  <TableCell colSpan={6} align="center">
                    <ZeroItemsLayout
                      desc={t('trade_history_no_trades')}
                      hint={t('trade_history_no_trades_hint')}
                    />
                  </TableCell>
                </TableBodyRow>
              )}
            </TableBody>
          </StyledTable>
          {history.length >= 5 ? (
            <TablePaginationWithExtremes
              rowsPerPageOptions={[5, 7]}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              count={history.length}
              labelRowsPerPage={''}
              disableRowsPerPageOptions={'true'}
            />
          ) : null}
        </TableWrapper>
      </Grid>
    </Grid>
  );
};
export default PropertyTradesTable;
