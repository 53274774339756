import styled, { withTheme } from 'styled-components';
import { Chip } from '@material-ui/core';

const StatusChip = styled(Chip)`
  font-size: 0.7rem;
  font-family: 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  height: 2em;
`;

export default withTheme(StatusChip);
