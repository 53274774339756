export const DialogTypes = {
  ADD_BANK_ACCOUNT: 1,
  ASSET_EXCHANGE: 2,
  BUY_OFFERING_TOKENS: 3,
  CHANGE_WALLET_NAME: 4,
  CLAIM_RENT: 5,
  CONNECT_WALLET: 6,
  DISCONNECT_WALLET: 7,
  EXCHANGE_BUY: 8,
  EXCHANGE_SELL: 9,
  PASSWORD_CHANGE: 10,
  UNLOCK_PROPERTY: 11,
  REQUEST_WITHDRAW: 12,
  CONFIRM_BUY: 13,
  SELECT_WALLET: 14,
  WRONG_NETWORK: 15,
  TRANSACTION_FAILED: 16,
  TRANSACTION_PENDING: 17,
  TRANSACTION_SUCCESS: 18,
  EXCHANGE_NO_SELLERES: 19,
  ADD_FUNDS: 20,
  WELCOME_TOUR: 21,
  WALLET_REGISTRATION_INITIATED: 22,
} as const;

export type DialogTypes = (typeof DialogTypes)[keyof typeof DialogTypes];

export type BuyConfirmDialogData = {
  token: string;
  amount: number;
  transactionHash: string;
};

export type BuyOfferingTokensData = {
  address: string;
};

export type ChangeWalletNameDialogData = {
  address: string;
  walletName: string;
};

export type FailedTransactionDialogData = {
  errorMessage: string;
};

export type PendingTransactionDialogData = {
  type: string;
};

export type DialogData = {
  buyConfirm: BuyConfirmDialogData;
  buyOfferingTokens: BuyOfferingTokensData;
  changeWalletName: ChangeWalletNameDialogData;
  failedTransaction: FailedTransactionDialogData;
  pendingTransaction: PendingTransactionDialogData;
};

// We should possibly rework the dialog handler to have a type safe data usage.
export type DialogsState = {
  activeDialog: DialogTypes | null;
  activeDialogData: DialogData;
};
