import { lazy } from 'react';
import type { CustomRouteWrapper } from 'types';

// Auth pages
const SignIn = lazy(() => import('pages/auth/SignIn'));
const SignUp = lazy(() => import('pages/auth/SignUp'));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
const Verify = lazy(() => import('pages/auth/Verify'));
const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'));

const authRoutes: CustomRouteWrapper = {
  id: 'Auth',
  path: '/auth',
  children: [
    {
      id: 'AuthSignIn',
      path: '/auth/sign-in',
      name: 'Sign In',
      component: SignIn,
    },
    {
      id: 'AuthSignUp',
      path: '/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      id: 'AuthResetPassword',
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      id: 'AuthVerify',
      path: '/verify',
      name: 'Verify Email',
      component: Verify,
    },
    {
      id: 'AuthForgotPassword',
      path: '/forgot-password/:token',
      name: 'Forgot Password',
      component: ForgotPassword,
    },
  ],
};

export const authenticationRoutes = [authRoutes];
