import type { FC } from 'react';
import styled from 'styled-components';
import { type GridProps, Grid, Typography, useTheme } from '@material-ui/core';

interface BlockProps {
  p?: string;
  background_image?: string;
  background_color?: string;
}

const Block = styled(Grid)<BlockProps>`
  border-radius: 0.6rem;
  padding: ${(props) => props.p || '1.2em'};
  ${(props) =>
    (props.justifyContent || props.alignItems) &&
    `
    display:flex;
    flex-direction: column;
  `}

  ${(props) =>
    (props.background_image &&
      `
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }

    &:before {
      content: '';
      background-color: #000;
    }

    &:after {
      content: '';
      background-image: url('${props.background_image}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.5;
    }
    `) ||
    `background-color: ${props.background_color};`}
`;

const HeaderTypography = styled(Typography)`
  text-align: center;
  text-transform: capitalize;
  width: 100%;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(255, 255, 255, 0.5);
`;

interface TProps extends GridProps {
  variant?: string;
  backgroundImage?: string;
  p?: string;
}

const BlockLayout: FC<TProps> = (props) => {
  const theme = useTheme();

  const { title, children, variant, backgroundImage, p } = props;

  const backgroundColor =
    variant === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main;

  const padding = p || '1.2em';

  if (!children) {
    return null;
  }

  const blockProps = {
    ...props,
    p: padding,
    background_color: backgroundColor,
    background_image: backgroundImage,
  };

  // If we don't remove the camelCased background image prop, we will get console
  // warnings that attributes need to be lowercased
  delete blockProps.backgroundImage;

  return (
    <Block container item {...blockProps}>
      {title && (
        <HeaderTypography variant="h2" color="secondary">
          {title}
          <Divider />
        </HeaderTypography>
      )}
      {children}
    </Block>
  );
};

export default BlockLayout;
