import { lazy } from 'react';
import type { CustomRouteWrapper } from 'types';

// Pro forma page
const OfferingProForma = lazy(() => import('components/offering/OfferingProForma'));

export const offeringProFormaRoutes: Array<CustomRouteWrapper> = [
  {
    id: 'ProForma',
    path: '/offeringProForma',
    name: 'Offering Pro Forma',
    component: OfferingProForma,
  },
];
