import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const PhotoSVG: FC<TBaseIconProps> = ({
  fillColor = '#acacac',
  height = '100%',
  width = '100%',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="3 3 18 18"
      focusable={false}
    >
      <path
        d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PhotoSVG;
