import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const DownloadDocSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid"
        version="1.1"
        viewBox="0 0 36.001 47.924"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-73.067 -139.1)">
          <path
            fill={fillColor}
            fillRule="evenodd"
            d="m83.774 171.7 6.293 6.293v-19.586c0-0.552 0.448-1 1-1s1 0.448 1 1v19.586l6.293-6.293c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-8 8c-0.092 0.092-0.203 0.165-0.325 0.216-0.122 0.05-0.252 0.077-0.382 0.077s-0.26-0.027-0.382-0.077c-0.123-0.051-0.233-0.124-0.325-0.216l-8-8c-0.391-0.391-0.391-1.023 0-1.414s1.023-0.391 1.414 0zm25.294-18.622c0 0.035-0.016 0.064-0.02 0.098v31.824c0 1.116-0.908 2.024-2.024 2.024h-31.92c-1.123 0-2.037-0.913-2.037-2.035v-43.854c0-1.123 0.914-2.035 2.037-2.035h19.952c0.045 0 0.083 0.02 0.127 0.025 0.045 6e-3 0.085 0.014 0.129 0.027 0.171 0.046 0.33 0.119 0.451 0.241l12.884 12.87c0.031 0.022 0.047 0.061 0.076 0.086 0.029 0.027 0.051 0.058 0.077 0.089 0.155 0.175 0.268 0.387 0.268 0.64zm-34-12.055-2e-3 43.966 32.002 0.034v-31h-12c-0.552 0-1.012-0.394-1.012-0.945v-12.055zm20.987 1.485v9.571h9.581z"
          />
        </g>
      </svg>
    </div>
  );
};

export default DownloadDocSVG;
