import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { backendAPI, createNotificationByType, i18nInstance } from 'utils';
import { RESET_STATE } from 'store/sharedActions';
import type {
  AuthenticationState,
  JWTToken,
  UserLoginData,
  UserLoginDataNewCP,
  AuthenticationLoginRequest,
} from 'types';
import {
  ApiStatus,
  NOTIFICATION_TYPES,
  createAppAsyncThunk,
  genericErrorReasons,
  loginErrorReasons,
} from 'types';

const initialState: AuthenticationState = {
  token: null,
  site: '',
  isAuthenticating: false,
  isRequestingNewPassword: false,
  needAgreementForNewCP: false,
  expireAt: null,
  errorMessage: null,
  errorReason: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginRequest(state) {
      state.isAuthenticating = true;
    },
    loginFailure(state, { payload }: PayloadAction<string>) {
      state.isAuthenticating = false;
      state.errorMessage = i18nInstance.t(payload);
      state.errorReason = payload;
      state.needAgreementForNewCP = payload === loginErrorReasons.login_share_personal_info;
    },
    loginSuccess(state, { payload }: PayloadAction<string>) {
      const { exp, company } = jwtDecode<JWTToken>(payload);
      state.token = payload;
      state.isAuthenticating = false;
      state.expireAt = exp;
      state.site = company;
    },
    loginCancel(state) {
      state.needAgreementForNewCP = false;
      state.errorMessage = null;
    },
    resetErrorState(state) {
      state.errorReason = null;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorState());
  };
}

/**
 * Receive the user login token
 *
 */
export const userLoginRequest = createAppAsyncThunk(
  'user/userLogin',
  async (loginData: UserLoginData, { dispatch }) => {
    dispatch(loginRequest());
    return backendAPI
      .post<AuthenticationLoginRequest>('/login', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === ApiStatus.SUCCESS) {
          dispatch(loginSuccess(data.jwt));
        } else {
          const errorReason = status.reason || 'No error provided';
          dispatch(loginFailure(errorReason));
        }
      })
      .catch(() => {
        dispatch(loginFailure(genericErrorReasons.network_error));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  }
);

/**
 * Receive the user login token for a new certified partner
 *
 */
export const userLoginRequestNewCP = createAppAsyncThunk(
  'user/userLoginNewCP',
  async (loginData: UserLoginDataNewCP, { dispatch }) => {
    dispatch(loginRequest());
    return backendAPI
      .post<AuthenticationLoginRequest>('/register/new-company', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === ApiStatus.SUCCESS) {
          dispatch(loginSuccess(data.jwt));
        } else {
          const errorReason = status.reason || 'No error provided';
          dispatch(loginFailure(errorReason));
        }
      })
      .catch(() => {
        dispatch(loginFailure(genericErrorReasons.network_error));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  }
);

export const { loginRequest, loginFailure, loginSuccess, loginCancel, resetErrorState } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
