import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const CloseWithBorderSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <path
        d="M24.000,48.000 C10.767,48.000 0.000,37.233 0.000,24.000 C0.000,10.767 10.767,0.000 24.000,0.000 C37.233,0.000 48.000,10.767 48.000,24.000 C48.000,37.233 37.233,48.000 24.000,48.000 ZM24.000,2.000 C11.869,2.000 2.000,11.869 2.000,24.000 C2.000,36.131 11.869,46.000 24.000,46.000 C36.131,46.000 46.000,36.131 46.000,24.000 C46.000,11.869 36.131,2.000 24.000,2.000 ZM32.707,32.707 C32.512,32.902 32.256,33.000 32.000,33.000 C31.744,33.000 31.488,32.902 31.293,32.707 L24.000,25.414 L16.707,32.707 C16.512,32.902 16.256,33.000 16.000,33.000 C15.744,33.000 15.488,32.902 15.293,32.707 C14.902,32.316 14.902,31.684 15.293,31.293 L22.586,24.000 L15.293,16.707 C14.902,16.316 14.902,15.684 15.293,15.293 C15.684,14.902 16.316,14.902 16.707,15.293 L24.000,22.586 L31.293,15.293 C31.684,14.902 32.316,14.902 32.707,15.293 C33.098,15.684 33.098,16.316 32.707,16.707 L25.414,24.000 L32.707,31.293 C33.098,31.684 33.098,32.316 32.707,32.707 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CloseWithBorderSVG;
