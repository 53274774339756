import type { InterfaceAbi } from 'ethers';
export const RevenueDistributionAbi = [
  [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      inputs: [{ internalType: 'address', name: 'target', type: 'address' }],
      name: 'AddressEmptyCode',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'AddressInsufficientBalance',
      type: 'error',
    },
    { inputs: [], name: 'FailedInnerCall', type: 'error' },
    { inputs: [], name: 'InvalidInitialization', type: 'error' },
    { inputs: [], name: 'NotInitializing', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'OwnableInvalidOwner',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'OwnableUnauthorizedAccount',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
      name: 'SafeERC20FailedOperation',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'address', name: 'newDataProxyContract', type: 'address' },
      ],
      name: 'DataProxyChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: 'uint64', name: 'version', type: 'uint64' }],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
        { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'OwnershipTransferStarted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
        { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'property', type: 'address' },
        { indexed: false, internalType: 'address[]', name: 'users', type: 'address[]' },
        { indexed: false, internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
        { indexed: false, internalType: 'uint256', name: 'fromTime', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'toTime', type: 'uint256' },
      ],
      name: 'RevenueAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'property', type: 'address' },
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      ],
      name: 'RevenueClaimed',
      type: 'event',
    },
    {
      inputs: [],
      name: 'acceptOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'property', type: 'address' },
        { internalType: 'address[]', name: 'users', type: 'address[]' },
        { internalType: 'uint256[]', name: 'amount', type: 'uint256[]' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'addRevenue',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'wallet', type: 'address' },
        { internalType: 'address[]', name: 'properties', type: 'address[]' },
      ],
      name: 'claimRevenuesForWalletForMultipleProperties',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'wallet', type: 'address' },
        { internalType: 'address', name: 'property', type: 'address' },
      ],
      name: 'claimRevenuesForWalletForProperty',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'property', type: 'address' }],
      name: 'getAverageMonthlyPayout',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'data', type: 'address' },
        { internalType: 'address', name: 'token', type: 'address' },
        { internalType: 'address', name: 'ownerAddress', type: 'address' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pendingOwner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'property', type: 'address' },
        { internalType: 'address', name: 'user', type: 'address' },
      ],
      name: 'pendingRevenue',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'property', type: 'address' },
        { internalType: 'address[]', name: 'wallets', type: 'address[]' },
      ],
      name: 'pushRevenueToUser',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'dataProxy', type: 'address' }],
      name: 'setDataProxy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'property', type: 'address' }],
      name: 'totalPayout',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
      name: 'totalPendingRevenue',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
] as InterfaceAbi;
