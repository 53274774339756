import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs } from 'store/dialogs';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { RESET_STATE } from './sharedActions';
import type { BankTransaction, OfferingTransaction, UserBankTransactionsState } from 'types';
import { ApiStatus, NOTIFICATION_TYPES, createAppAsyncThunk } from 'types';

const initialState: UserBankTransactionsState = {
  isSendingTransaction: false,
  sendTransactionErrorMessage: null,
  userTransactions: [],
  userOfferings: [],
  isRequestingTransactions: false,
  userOfferingsRequestMeta: {
    loading: false,
    loaded: false,
    error: null,
  },
  requestingTransactionsErrorMessage: null,
  isSendingWithdrawal: false,
  sendWithdrawalErrorMessage: null,
};

const userBankTransactionsSlice = createSlice({
  name: 'userBankTransactions',
  initialState,
  reducers: {
    sendTransactionRequest(state) {
      state.isSendingTransaction = true;
    },
    sendTransactionSuccess(state) {
      state.isSendingTransaction = false;
      state.sendTransactionErrorMessage = null;
    },
    sendTransactionFailure(state, action) {
      const { error } = action.payload;
      state.isSendingTransaction = false;
      state.sendTransactionErrorMessage = error;
    },
    sendWithdrawal(state) {
      state.isSendingWithdrawal = true;
    },
    sendWithdrawalSuccess(state) {
      state.isSendingWithdrawal = false;
      state.sendWithdrawalErrorMessage = null;
    },
    sendWithdrawalFailure(state, action) {
      const { error } = action.payload;
      state.isSendingWithdrawal = false;
      state.sendWithdrawalErrorMessage = error;
    },
    updateTransactionStatus(state, action) {
      const txIndex = state.userOfferings.findIndex(
        (tx) => tx.transactionHash === action.payload.transactionHash
      );
      if (txIndex >= 0) {
        state.userOfferings[txIndex].status = action.payload.txStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchUserBankTransactionsHistory.pending, (state) => {
      state.isRequestingTransactions = true;
    });
    builder.addCase(fetchUserBankTransactionsHistory.fulfilled, (state, { payload }) => {
      state.isRequestingTransactions = false;
      state.userTransactions = payload;
    });
    builder.addCase(fetchUserBankTransactionsHistory.rejected, (state, { payload }) => {
      state.isRequestingTransactions = false;
      state.requestingTransactionsErrorMessage = payload as string;
    });
    builder.addCase(fetchUserOfferingTransactionsHistory.pending, (state) => {
      state.userOfferingsRequestMeta.loading = true;
    });
    builder.addCase(fetchUserOfferingTransactionsHistory.fulfilled, (state, { payload }) => {
      state.userOfferings = payload;
      state.userOfferingsRequestMeta.loading = false;
      state.userOfferingsRequestMeta.loaded = true;
      state.userOfferingsRequestMeta.error = null;
    });
    builder.addCase(fetchUserOfferingTransactionsHistory.rejected, (state, { payload }) => {
      state.userOfferingsRequestMeta.loading = false;
      state.userOfferingsRequestMeta.error = payload as string;
      state.userOfferingsRequestMeta.loaded = false;
    });
  },
});

export function sendBankTransaction(transactionData) {
  return async (dispatch) => {
    dispatch(sendWithdrawal());
    return backendAPI
      .post('/bank-transaction', transactionData)
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === ApiStatus.SUCCESS) {
          dispatch(sendTransactionSuccess());
          dispatch(hideDialogs());
          dispatch(createNotification('TODO: Better success message'));
        } else {
          dispatch(sendTransactionFailure(status));
          dispatch(hideDialogs());
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(sendTransactionFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

/**
 * Receive the bank transactions for a given user
 *
 */
export const fetchUserBankTransactionsHistory = createAppAsyncThunk(
  'userBankTransactions/transactionsHistory',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<BankTransaction>>('/bank/transaction')
      .then(({ data }) => data)
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Receive the offering transactions for a given user
 *
 */
export const fetchUserOfferingTransactionsHistory = createAppAsyncThunk(
  'userBankTransactions/offeringsHistory',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<Array<OfferingTransaction>>('/initial-sale/investment')
      .then(({ data }) => data)
      .catch(() => {
        const errorDescription = i18nInstance.t('account_trade_offerings_fetching_error');
        dispatch(createNotification(errorDescription, 'error'));
        return rejectWithValue(errorDescription);
      });
  }
);

export function sendWithdrawalTransaction(hash) {
  return async (dispatch) => {
    dispatch(sendWithdrawal());
    return backendAPI
      .post('/bank/withdrawal', { transactionHash: hash })
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === ApiStatus.SUCCESS) {
          dispatch(sendWithdrawalSuccess());
          dispatch(hideDialogs());
          dispatch(createNotification('Withdrawal submitted'));
        } else {
          dispatch(sendWithdrawalFailure(status));
          dispatch(hideDialogs());
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(sendWithdrawalFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  sendTransactionFailure,
  sendTransactionRequest,
  sendTransactionSuccess,
  sendWithdrawal,
  sendWithdrawalFailure,
  sendWithdrawalSuccess,
  updateTransactionStatus,
} = userBankTransactionsSlice.actions;
export default userBankTransactionsSlice.reducer;
