export const genericErrorReasons = {
  network_error: 'network_error',
  blockchain_indexed_data_fetch_error: 'blockchain_indexed_data_fetch_error',
  password_missmatch: 'password_missmatch',
  company_not_exists: 'company_not_exists',
  password_blank: 'password_blank',
  password_confirm_blank: 'password_confirm_blank',
  email_blank: 'email_blank',
};

export const loginErrorReasons = {
  login_wrong_credentials: 'login_wrong_credentials',
  login_account_not_activated: 'login_account_not_activated',
  login_no_company: 'login_no_company',
  login_company_not_exists: 'login_company_not_exists',
  login_share_personal_info: 'login_share_personal_info',
  login_something_went_wrong: 'login_something_went_wrong',
};

export const passwordResetErrorReasons = {
  reset_activate_first: 'reset_activate_first',
};

export enum ApiStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type RequestStatus = {
  status: ApiStatus;
  reason: string | null;
};

export enum ApiStatusCode {
  FAILURE,
  SUCCESS = 200,
}
