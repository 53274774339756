import { useEffect, useMemo, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Grid as MuiGrid, MenuItem, Select, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Loader from 'components/custom/Loader';
import dayjs from 'dayjs';

import { fetchPropertyStatistics } from 'store/properties';

import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import CapitalStackChart from './stats/CapitalStackChart';
import RevenueDistributionChart from './stats/RevenueDistributionChart';
import TokenHolderChart from './stats/TokenHolderChart';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RevenueDistributionMode } from 'types';
import { propertyDetailRequestsProcessingSelector } from 'store/selectors/properties';

const Grid = styled(MuiGrid)(spacing);

const BoldTypography = styled(Typography)`
  font-weight: 500;
`;

const RoundedGrid = styled(Grid)`
  background-color: ${(props) => props.theme.body.secondary};
  border-radius: 0.6rem;
`;

const CompactSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const PropertyStats = () => {
  const dispatch = useAppDispatch();
  const { propertyAddress: address } = useParams();
  const { t } = useTranslation();

  const [selectedCapitalStackDate, setSelectedCapitalStackDate] = useState('');
  const [revenueDistributionsMode, setRevenueDistributionsMode] = useState<RevenueDistributionMode>(
    RevenueDistributionMode.MONTHLY
  );

  const { onChainProperties } = useAppSelector((state) => state.properties);

  const isProcessingPropertyDetailRequests = useAppSelector(
    propertyDetailRequestsProcessingSelector
  );

  const onChainProperty = onChainProperties[address ?? ''] ?? null;
  const capitalStacks = useMemo(
    () => (onChainProperty ? onChainProperty?.capitalStacks : []),
    [onChainProperty]
  );
  const tokenHolders = onChainProperty ? onChainProperty?.holders : [];

  const capitalStacksTimestamps =
    capitalStacks.length > 0
      ? capitalStacks.map((capitalStack) => Number(capitalStack.timestamp))
      : [];

  const firstTimestamp =
    capitalStacksTimestamps.length > 0 ? Math.min(...capitalStacksTimestamps) : 0;

  // TODO ChangeEvent should be replaced by SelectChangeEvent after migration to MUI v5
  const handleCapitalStackDateChange = (event: ChangeEvent<{ value: unknown }>) =>
    setSelectedCapitalStackDate(event.target.value as string);

  const handleRevenueDistributionModeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const mode = event.target.value as RevenueDistributionMode;
    setRevenueDistributionsMode(mode);
  };

  const timeStampToDateConverter = (timeStamp: number) =>
    dayjs.unix(timeStamp).format('YYYY-MM-DD');

  useEffect(() => {
    address ? dispatch(fetchPropertyStatistics(address)) : null;
  }, [address, dispatch]);

  useEffect(() => {
    setSelectedCapitalStackDate(capitalStacks.length > 0 ? capitalStacks[0].timestamp : '');
  }, [capitalStacks, onChainProperties]);

  if (isProcessingPropertyDetailRequests) {
    return (
      <Grid container item xs direction="column" justifyContent="center" alignItems="center">
        <Loader size="6rem" />
      </Grid>
    );
  }
  return (
    <Grid container item>
      <Grid container item direction="column" spacing={4}>
        <Grid container item xs style={{ flex: '3' }}>
          <Grid container item spacing={4}>
            <Grid container item direction="column" xs={12} md={6}>
              <RoundedGrid container item xs={12} direction="column">
                <Grid container item justifyContent="space-between" p={5}>
                  <BoldTypography variant="h3">
                    {t('marketplace_property_stats_capital_stack')}
                  </BoldTypography>
                  <CompactSelect
                    name="selectedCapitalStackDate"
                    inputProps={{
                      id: 'selectedCapitalStackDate',
                      style: { fontSize: '1.0625rem' },
                    }}
                    value={selectedCapitalStackDate}
                    variant="outlined"
                    onChange={handleCapitalStackDateChange}
                    disabled={capitalStacks.length === 0}
                  >
                    {capitalStacks.length > 0 ? (
                      capitalStacks?.map(({ timestamp }) => (
                        <MenuItem key={`capital_stack_${timestamp}`} value={timestamp}>
                          <Typography variant="h4">
                            {Number(timestamp) === firstTimestamp
                              ? t('marketplace_property_stats_capital_stack_initial')
                              : timeStampToDateConverter(Number(timestamp))}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key="capitalStack_none" value={0}>
                        <Typography variant="h4">None</Typography>
                      </MenuItem>
                    )}
                  </CompactSelect>
                </Grid>
                {capitalStacks.length > 0 ? (
                  <CapitalStackChart selectedDate={selectedCapitalStackDate} />
                ) : (
                  <Grid container item xs justifyContent="center" alignItems="center">
                    <ZeroItemsLayout
                      desc={t('no_capital_stacks')}
                      hint={t('no_capital_stacks_hint')}
                      size={8}
                    />
                  </Grid>
                )}
              </RoundedGrid>
            </Grid>

            <Grid container item direction="column" xs={12} md={6}>
              <RoundedGrid container item xs={12} direction="column">
                <Grid container item justifyContent="space-between" p={5}>
                  <BoldTypography variant="h3">
                    {t('marketplace_property_stats_token_holders')}
                  </BoldTypography>
                  <a
                    href={`${process.env.REACT_APP_ETHERSCAN_URL}/token/tokenholderchart/${address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      pointerEvents: tokenHolders.length === 0 ? 'none' : 'auto',
                    }}
                  >
                    <Button
                      variant="outlined"
                      type="button"
                      size="small"
                      disabled={tokenHolders.length === 0}
                    >
                      {`${t('marketplace_property_stats_view_on')} Etherscan`}
                    </Button>
                  </a>
                </Grid>
                <TokenHolderChart />
              </RoundedGrid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          p={5}
          style={{
            flex: '2',
          }}
        >
          <RoundedGrid container item xs direction="column">
            <Grid container item justifyContent="space-between" p={5}>
              <BoldTypography variant="h3">
                {t('marketplace_property_stats_distributions_net_revenues')}
              </BoldTypography>
              <CompactSelect
                name="selectedRevenueDistributionDate"
                inputProps={{
                  id: 'selectedRevenueDistributionDate',
                  style: { fontSize: '1.0625rem' },
                }}
                value={revenueDistributionsMode}
                variant="outlined"
                onChange={handleRevenueDistributionModeChange}
              >
                <MenuItem value={RevenueDistributionMode.MONTHLY}>
                  <Typography variant="h4">{t('monthly')}</Typography>
                </MenuItem>
                <MenuItem value={RevenueDistributionMode.QUARTERLY}>
                  <Typography variant="h4">{t('quarterly')}</Typography>
                </MenuItem>
                <MenuItem value={RevenueDistributionMode.YEARLY}>
                  <Typography variant="h4">{t('yearly')}</Typography>
                </MenuItem>
              </CompactSelect>
            </Grid>
            <RevenueDistributionChart mode={revenueDistributionsMode} />
          </RoundedGrid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PropertyStats;
