export const CurrencyTypes = {
  EUR: 'EUR',
  GBP: 'GBP',
  AUD: 'AUD',
  USD: 'USD',
} as const;

export type CurrencyTypes = (typeof CurrencyTypes)[keyof typeof CurrencyTypes];

export type ExchangeRate = {
  [key in CurrencyTypes]?: number;
};

export type CoinsValueRequest = {
  expires: number;
  DAI: ExchangeRate;
};

export type CurrencyState = {
  selectedCurrency: { [key: string]: CurrencyTypes };
  daiToFiatRates: ExchangeRate;
  usdToOtherRates: {};
  isFetchingRates: boolean;
};
