import { createSlice } from '@reduxjs/toolkit';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { hideDialogs, showDialog } from 'store/dialogs';
import {
  approveInvestmentAllowance,
  checkInvestmentAllowance as checkOfferingInvestmentAllowance,
} from 'utils/web3';
import { RESET_STATE } from './sharedActions';
import type {
  SignedWalletData,
  RequestStatus,
  UpdateWalletData,
  UserWallets,
  UserWalletType,
} from 'types';
import { ApiStatus, DialogTypes, NOTIFICATION_TYPES, createAppAsyncThunk } from 'types';

const initialState: UserWalletType = {
  isInitialState: true,
  isFetchingWallets: false,
  isInitialFetchingDone: false,
  isUpdatingWalletAddress: false,
  fetchErrorMessage: null,
  updateWalletAddressErrorMessage: null,
  updateWalletAddressSuccess: false,
  walletType: '',
  wallets: [],
  filteredWallets: null,
  isUpdatingWalletName: false,
  updateWalletNameErrorMessage: null,
  hasCheckedOfferingInvestmentAllowance: false,
  isCheckingOfferingInvestmentAllowance: false,
  isApprovingOfferingInvestmentAllowance: false,
  hasSetOfferingInvestmentAllowance: false,
  needToDisconnect: false,
  hasDisconnected: false,
};

const userWalletSlice = createSlice({
  name: 'userWallet',
  initialState,
  reducers: {
    walletCheckAllowanceRequest(state) {
      state.isCheckingOfferingInvestmentAllowance = true;
    },
    walletCheckAllowanceComplete(state, action) {
      const { hasSetAllowance } = action.payload;

      state.isCheckingOfferingInvestmentAllowance = false;
      state.hasCheckedOfferingInvestmentAllowance = true;
      state.hasSetOfferingInvestmentAllowance = hasSetAllowance;
    },
    walletSetAllowanceRequest(state) {
      state.isApprovingOfferingInvestmentAllowance = true;
    },
    walletSetAllowanceComplete(state, action) {
      const { hasSetAllowance } = action.payload;
      state.isApprovingOfferingInvestmentAllowance = false;
      state.hasSetOfferingInvestmentAllowance = hasSetAllowance;
    },
    toggleWalletFilter(state, action) {
      const { address } = action.payload;

      if (!state.filteredWallets) state.filteredWallets = [];

      const isWalletFiltered: boolean = state.filteredWallets.includes(address);
      if (isWalletFiltered) {
        state.filteredWallets = state.filteredWallets.filter((item: string) => item !== address);
      } else {
        state.filteredWallets.push(address);
      }
    },
    toggleFilterForAllWallets(state, action) {
      const { shouldDeselectAll } = action.payload;
      if (shouldDeselectAll) {
        state.filteredWallets = [];
      } else {
        state.filteredWallets = state.wallets.map((wallet) => wallet.address);
      }
    },
    walletDisconnected(state) {
      state.hasDisconnected = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchUserWallets.pending, (state) => {
      state.isFetchingWallets = true;
    });
    builder.addCase(fetchUserWallets.fulfilled, (state, { payload }) => {
      state.isFetchingWallets = false;
      // Sort wallets by oldest -> newest
      state.wallets = payload.sort((a, b) => (a.created > b.created ? 1 : -1));
      state.isInitialFetchingDone = true;
      state.fetchErrorMessage = null;
      state.isInitialState = false;
    });
    builder.addCase(fetchUserWallets.rejected, (state, { payload }) => {
      state.isFetchingWallets = false;
      state.isInitialFetchingDone = true;
      state.fetchErrorMessage = payload as string;
    });
    builder.addCase(userAddWalletRequest.pending, (state) => {
      state.isUpdatingWalletAddress = true;
      state.needToDisconnect = false;
      state.hasDisconnected = false;
    });
    builder.addCase(userAddWalletRequest.fulfilled, (state) => {
      state.isUpdatingWalletAddress = false;
      state.updateWalletAddressErrorMessage = null;
    });
    builder.addCase(userAddWalletRequest.rejected, (state, { payload }) => {
      state.isUpdatingWalletAddress = false;
      state.updateWalletAddressErrorMessage = payload as string;
      state.needToDisconnect = true;
    });
    builder.addCase(userUpdateWalletNameRequest.pending, (state) => {
      state.isUpdatingWalletName = true;
    });
    builder.addCase(userUpdateWalletNameRequest.fulfilled, (state) => {
      state.isUpdatingWalletName = false;
      state.updateWalletNameErrorMessage = null;
    });
    builder.addCase(userUpdateWalletNameRequest.rejected, (state, { payload }) => {
      state.isUpdatingWalletName = false;
      state.updateWalletNameErrorMessage = payload as string;
    });
  },
});

export function checkOfferingInvestmentAllowanceRedux(spenderAddress: string) {
  return async (dispatch) => {
    dispatch(walletCheckAllowanceRequest());
    const hasSetAllowance = await checkOfferingInvestmentAllowance(spenderAddress);

    dispatch(walletCheckAllowanceComplete({ hasSetAllowance }));
  };
}

export function approveOfferingInvestmentAllowanceRedux() {
  return async (dispatch) => {
    dispatch(walletSetAllowanceRequest());
    const transactionSuccessful = await approveInvestmentAllowance();
    if (transactionSuccessful) {
      dispatch(walletSetAllowanceComplete({ hasSetAllowance: true }));
    } else {
      dispatch(walletSetAllowanceComplete({ hasSetAllowance: false }));
      dispatch(
        createNotification(i18nInstance.t('notification_allowance_approval_failed'), 'error')
      );
    }
  };
}

/**
 * Receive the wallets that belong to the user
 *
 */
export const fetchUserWallets = createAppAsyncThunk(
  'userWallet/wallets',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .get<UserWallets>('/account/wallet')
      .then((response) => response.data)
      .catch(() => {
        const errorMessage = i18nInstance.t('network_error');
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Add a wallet to the user's account
 *
 */
export const userAddWalletRequest = createAppAsyncThunk(
  'userWallet/addWallet',
  async (addWalletData: SignedWalletData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/account/wallet', addWalletData)
      .then(({ data }) => {
        const { status, reason } = data;

        if (status !== ApiStatus.SUCCESS) {
          throw new Error(`unsuccessful-wallet-addition-request: ${reason}`);
        }

        dispatch(hideDialogs());
        dispatch(showDialog({ type: DialogTypes.WALLET_REGISTRATION_INITIATED }));
        dispatch(fetchUserWallets());

        return;
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = i18nInstance.t('add_new_wallet_request_error');
        dispatch(createNotification(errorMessage, 'error'));
        dispatch(hideDialogs());
        return rejectWithValue(errorMessage);
      });
  }
);

/**
 * Update the name of a wallet that belongs to the user
 *
 */
export const userUpdateWalletNameRequest = createAppAsyncThunk(
  'userWallet/updateWalletName',
  async (updateWalletData: UpdateWalletData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .put('/account/wallet', updateWalletData)
      .then(({ data }) => {
        const { status, reason } = data;

        if (status !== ApiStatus.SUCCESS) {
          throw new Error(`unsuccessful-wallet-rename-request: ${reason}`);
        }

        dispatch(createNotificationByType(NOTIFICATION_TYPES.WALLET_NAME_CHANGED));
        dispatch(fetchUserWallets());
        dispatch(hideDialogs());
        return;
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = i18nInstance.t('wallet_name_change_request_error');
        dispatch(createNotification(errorMessage, 'error'));
        dispatch(hideDialogs());
        return rejectWithValue(errorMessage);
      });
  }
);

export function setWalletDisconnected() {
  return async (dispatch) => {
    dispatch(walletDisconnected());
  };
}

export const {
  walletCheckAllowanceComplete,
  walletCheckAllowanceRequest,
  walletSetAllowanceComplete,
  walletSetAllowanceRequest,
  walletDisconnected,
  toggleWalletFilter,
  toggleFilterForAllWallets,
} = userWalletSlice.actions;
export default userWalletSlice.reducer;
