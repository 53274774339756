import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const NoteSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 48.281 47.969"
    >
      <path
        d="M47.045,8.627 C47.037,8.636 47.026,8.638 47.018,8.647 C47.010,8.656 47.007,8.668 46.998,8.677 L26.871,28.676 C26.864,28.684 26.853,28.686 26.845,28.693 C26.797,28.739 26.736,28.768 26.678,28.804 C26.620,28.839 26.567,28.880 26.506,28.902 C26.496,28.906 26.489,28.914 26.478,28.918 L17.421,31.917 C17.317,31.952 17.210,31.969 17.103,31.969 C16.841,31.969 16.583,31.867 16.392,31.676 C16.122,31.408 16.028,31.012 16.148,30.652 L19.167,21.653 C19.171,21.643 19.179,21.636 19.183,21.626 C19.205,21.566 19.245,21.515 19.279,21.459 C19.316,21.399 19.347,21.337 19.394,21.287 C19.401,21.279 19.403,21.270 19.411,21.262 L39.537,1.264 C39.540,1.261 39.544,1.260 39.548,1.257 C39.551,1.254 39.552,1.250 39.555,1.247 C40.433,0.415 41.691,-0.031 42.971,-0.002 C44.363,0.038 45.682,0.649 46.686,1.718 C48.983,4.153 48.522,6.943 47.045,8.627 ZM45.216,3.084 C44.402,2.217 43.513,2.014 42.912,1.997 C42.177,1.979 41.430,2.235 40.944,2.694 L21.002,22.509 L18.694,29.388 L25.617,27.095 L45.537,7.301 C46.347,6.366 46.748,4.707 45.216,3.084 ZM26.160,7.972 L2.008,7.972 L2.008,45.969 L40.000,45.969 L40.000,21.969 C40.000,21.417 40.444,20.969 41.000,20.969 C41.556,20.969 42.000,21.417 42.000,21.969 L42.000,46.969 C42.000,47.521 41.813,47.969 41.257,47.969 L1.002,47.969 C0.446,47.969 -0.004,47.521 -0.004,46.969 L-0.004,6.972 C-0.004,6.420 0.446,5.972 1.002,5.972 L26.160,5.972 C26.716,5.972 27.166,6.420 27.166,6.972 C27.166,7.524 26.716,7.972 26.160,7.972 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default NoteSVG;
