export enum RevenueDistributionMode {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export type RevDistChartDataFormat = {
  period: string;
  revenueDistributed: number;
};
