import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const MonitorSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 60 49"
    >
      <path
        d="M57.066,38.000 L31.000,38.000 L31.000,47.000 L42.000,47.000 C42.553,47.000 43.000,47.448 43.000,48.000 C43.000,48.552 42.553,49.000 42.000,49.000 L18.000,49.000 C17.447,49.000 17.000,48.552 17.000,48.000 C17.000,47.448 17.447,47.000 18.000,47.000 L29.000,47.000 L29.000,38.000 L2.934,38.000 C1.316,38.000 0.000,36.702 0.000,35.105 L0.000,2.895 C0.000,1.298 1.316,0.000 2.934,0.000 L57.066,0.000 C58.684,0.000 60.000,1.298 60.000,2.895 L60.000,35.105 C60.000,36.702 58.684,38.000 57.066,38.000 ZM58.000,2.895 C58.000,2.401 57.581,2.000 57.066,2.000 L2.934,2.000 C2.419,2.000 2.000,2.401 2.000,2.895 L2.000,35.105 C2.000,35.590 2.428,36.000 2.934,36.000 L57.066,36.000 C57.581,36.000 58.000,35.599 58.000,35.105 L58.000,2.895 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MonitorSVG;
