import { getContractAddressesForChainOrThrow } from '@0x/contract-addresses';
import type { TransactionResponse } from 'ethers';
import { Contract, Signature as SignatureEthers } from 'ethers';
import { getProvider } from './helper';
import { handleAllowance } from './allowance';
import { ZeroExABI } from 'contracts';
import type { ModifiedOrders, OrderState, OrderWrapper, Signature } from 'types';
import { LimitOrderBI, LimitOrderEIP712Domain, OrderStatusBI, SignatureType } from 'types';
export const TX_DEFAULTS = { gas: 800000n, gasPrice: BigInt(20e9) };

function getEIP712Domain(chainId: number, verifyingContract: string) {
  return { chainId, verifyingContract, name: 'ZeroEx', version: '1.0.0' };
}

export async function isOrderValid(order: OrderWrapper, chainId: number): Promise<boolean> {
  const orderState = await getOrderState(order, chainId);

  const {
    orderInfo: { status },
    remainingFillableAmount,
    isValidSignature,
  } = orderState;

  return (
    (status as unknown as bigint) === BigInt(OrderStatusBI.Fillable) &&
    remainingFillableAmount > 0n &&
    isValidSignature
  );
}

export async function cancelOrder(order: OrderWrapper, chainId: number): Promise<string> {
  const provider = getProvider();
  const signer = await provider.getSigner();
  const { exchangeProxy } = getContractAddressesForChainOrThrow(chainId);
  const exchangeContract = new Contract(exchangeProxy, ZeroExABI, signer);

  const transaction = await exchangeContract.cancelLimitOrder(order.limitOrder);
  return transaction.wait();
}

export function createLimitOrder(
  makerAddress: string,
  makerAmount: bigint,
  takerAmount: bigint,
  bsptTokenAddress: string,
  expirationTimeSeconds: number,
  chainId: number
): LimitOrderBI {
  const { exchangeProxy } = getContractAddressesForChainOrThrow(chainId);

  const daiTokenAddress = process.env.REACT_APP_DAI_ADDRESS as string;

  const salt = BigInt(Date.now());
  const expiry = BigInt(expirationTimeSeconds);

  const orderBI = new LimitOrderBI({
    makerToken: bsptTokenAddress,
    takerToken: daiTokenAddress,
    makerAmount,
    takerAmount,
    maker: makerAddress,
    expiry,
    salt,
    chainId,
    verifyingContract: exchangeProxy,
  });

  return orderBI;
}

export async function signOrder(limitOrder: LimitOrderBI): Promise<OrderWrapper | null> {
  try {
    const provider = getProvider();
    const signer = await provider.getSigner();

    const domainNew = getEIP712Domain(limitOrder.chainId, limitOrder.verifyingContract);

    const types = {
      LimitOrder: LimitOrderEIP712Domain,
    };

    // Get signature
    const rawSignature = await signer.signTypedData(domainNew, types, limitOrder.getMessage());
    const { v, r, s } = SignatureEthers.from(rawSignature);
    const signature = {
      v,
      r,
      s,
      signatureType: SignatureType.EIP712,
    } as Signature;

    const orderWrapped: OrderWrapper = {
      limitOrder,
      signature,
    };

    /*  const isValid = await isOrderValid(orderWrapped, chainId);
    console.log(`IsNewOrderValid: ${isValid}`);
 */
    return orderWrapped;
  } catch (error: unknown) {
    console.error(error);
  }
  return null;
}

export async function getOrderState(order: OrderWrapper, chainId: number): Promise<OrderState> {
  const provider = getProvider();
  const { exchangeProxy } = getContractAddressesForChainOrThrow(chainId);
  const exchangeContract = new Contract(exchangeProxy, ZeroExABI, provider);
  const [orderInfo, remainingFillableAmount, isValidSignature] =
    await exchangeContract.getLimitOrderRelevantState(order.limitOrder, order.signature);

  return {
    orderInfo,
    remainingFillableAmount,
    isValidSignature,
  };
}

export async function batchFillOrders(
  bsptTokenAddress: string,
  orders: ModifiedOrders,
  originalOrdersMap,
  formValues,
  chainId: number
): Promise<
  Array<{
    fillerWallet: string;
    signature: Signature;
    txHash: string;
    volume: string;
  }>
> {
  const provider = getProvider();
  const signer = await provider.getSigner();
  const userWallet = await signer.getAddress();
  const { exchangeProxy } = getContractAddressesForChainOrThrow(chainId);

  // TODO: Sort types
  const { daiAmountBI } = formValues;

  const handleAllowanceResultDAI = await handleAllowance(
    process.env.REACT_APP_DAI_ADDRESS as string,
    userWallet,
    exchangeProxy,
    daiAmountBI
  );
  if (!handleAllowanceResultDAI) {
    throw new Error('Order: Allowance issue');
  }

  const signedOrders = orders.map((o) => o.order);

  const orderValidationPromises = signedOrders.map((order) => {
    return new Promise((resolve, reject) => {
      isOrderValid(order, chainId)
        .then((result) => {
          resolve({ isValid: result, orderSalt: order.limitOrder.salt });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  });

  const validationResults = (await Promise.all(orderValidationPromises)) as Array<{
    isValid: boolean;
    orderSalt: bigint;
  }>;

  const validOrders = signedOrders.filter((order) => {
    const validationResultIndex = validationResults.findIndex((validation) => {
      return validation.orderSalt === order.limitOrder.salt;
    });

    const currentOrderValidationResult = validationResults[validationResultIndex];
    return currentOrderValidationResult.isValid;
  });

  if (validOrders.length === 0) {
    throw new Error('Order: No valid orders');
  }

  const ordersToFill = [] as Array<OrderWrapper>;
  const ordersAmountFill = [] as Array<bigint>;
  const ordersSignatures = [] as Array<Signature>;

  let remainingDAI = daiAmountBI;

  for (let i = 0; i < validOrders.length; i += 1) {
    const takerAssetAmount = validOrders[i].takerAssetAmount;
    if (remainingDAI > takerAssetAmount) {
      ordersAmountFill.push(takerAssetAmount);
      remainingDAI = remainingDAI - takerAssetAmount;
    } else {
      ordersAmountFill.push(remainingDAI);
      remainingDAI = -1n;
    }
    ordersSignatures.push(validOrders[i].signature);
    ordersToFill.push(originalOrdersMap[Number(validOrders[i].limitOrder.salt)]);
    if (remainingDAI <= 0n) {
      break;
    }
  }

  const pureLimitOrders = ordersToFill.map((order) => {
    return { ...order.limitOrder };
  });

  const revertIfIncomplete = true;
  const exchangeContract = new Contract(exchangeProxy, ZeroExABI, signer);
  const protocolFeeMultiplierBI = await exchangeContract.getProtocolFeeMultiplier();

  // IMPORTANT NOTE
  // We'll only be using the singular fill limit order for the development environment
  // until batch filling is implemented by 0x on Sepolia
  try {
    const transaction = (
      chainId === 11155111
        ? await exchangeContract.fillLimitOrder(
            pureLimitOrders[0],
            ordersSignatures[0],
            ordersAmountFill[0]
          )
        : await exchangeContract.batchFillLimitOrders(
            pureLimitOrders,
            ordersSignatures,
            ordersAmountFill,
            revertIfIncomplete,
            { value: calculateProtocolFee(protocolFeeMultiplierBI, BigInt(ordersToFill.length)) }
          )
    ) as TransactionResponse;

    const transactionReceipe = await transaction.wait();
    if (!transactionReceipe) throw new Error('Transaction failed');

    let totalTakerAssetFillAmountBI = BigInt(0);
    ordersAmountFill.forEach((takerAssetFillAmount) => {
      totalTakerAssetFillAmountBI = totalTakerAssetFillAmountBI + takerAssetFillAmount;
    });

    return ordersToFill.map((order, index) => {
      return {
        fillerWallet: userWallet,
        signature: order.signature,
        txHash: transactionReceipe.hash,
        volume: ordersAmountFill[index].toString(),
      };
    });
  } catch (error) {
    const errorMessage = 'order-fulfillment-error';
    throw new Error(errorMessage);
  }
}

export const calculateProtocolFee = (
  multiplier: bigint,
  ordersAmount: bigint,
  gasPrice = TX_DEFAULTS.gasPrice
): bigint => {
  return multiplier * gasPrice * ordersAmount;
};
