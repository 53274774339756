import { useCallback, useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatBigInt, getCurrentValuation, getOfferingValuation } from 'utils/format';
import { formatDaiToFiatString } from 'utils/currency';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartWrapper } from 'components/marketplace/stats/ChartWrapper';
import { PROPERTY_STATUS, useAppSelector, type TChartData } from 'types';
import { onChainPropertiesDataSelector, propertyTokenSelector } from 'store/selectors/properties';
import { type ChartOptions } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BAR_THICKNESS = 40;

const DIVISION_VALUE = 1000;

const options: ChartOptions<'bar'> = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      labels: {
        title: {
          color: '#000',
          align: 'right',
          offset: 25,
          font: {
            size: 16,
          },
          formatter(_, ctx) {
            return ctx.dataset.data[0] &&
              typeof ctx.dataset.data[0] === 'number' &&
              ctx.dataset.data[0] > 0
              ? ctx.dataset.label
              : '';
          },
        },
        value: {
          color: '#000',
          align: 'left',
          offset: 25,
          font: {
            size: 20,
            weight: 500,
          },
          formatter(_, ctx) {
            return ctx.dataset.data &&
              typeof ctx.dataset.data[0] === 'number' &&
              ctx.dataset.data[0] > 0
              ? `${ctx.dataset.data[0].toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}%`
              : '';
          },
        },
      },
    },
    title: {
      display: true,
      color: '#000',
      font: { size: 20 },
      position: 'bottom',
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      display: false,
    },
    y: {
      stacked: true,
      display: false,
      max: 100,
    },
  },
};

const CapitalStackChart: FC<{ selectedDate: string }> = ({ selectedDate }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { address } = useAppSelector(propertyTokenSelector);
  const onChainProperties = useAppSelector(onChainPropertiesDataSelector);

  const onChainOfferings = useAppSelector((store) => store.properties.onChainOfferings);
  const onChainOffering = onChainOfferings[address.toLowerCase()];
  const propertyStatus = useAppSelector((store) => store.properties.property.propertyStatus);

  const isPreSaleProperty = useMemo(() => {
    const now = dayjs();
    const startPreSale = onChainOffering?.presaleStart ?? 0;
    const endPreSale = onChainOffering?.presaleEnd ?? 0;

    if (startPreSale && endPreSale) {
      return now >= dayjs.unix(startPreSale) && now < dayjs.unix(endPreSale);
    }

    return false;
  }, [onChainOffering]);

  const [data, setData] = useState<TChartData>();

  const updateDataset = useCallback(() => {
    const onChainProperty = onChainProperties[address] || null;

    const isOfferingProperty = propertyStatus === PROPERTY_STATUS.OFFERING;

    const displayedValuation = isOfferingProperty
      ? getOfferingValuation(onChainOffering)
      : getCurrentValuation(onChainProperty);

    const capitalStacks = onChainProperty?.capitalStacks ?? [];
    if (capitalStacks?.length === 0) return;
    let data = capitalStacks[0];
    if (selectedDate && capitalStacks.length) {
      const newData = capitalStacks?.find(
        (capitalStack) => capitalStack.timestamp === selectedDate
      );
      if (newData) {
        data = newData;
      }
    }

    const seniorDebt = formatBigInt(data.seniorDebt) / DIVISION_VALUE;
    const juniorDebt = formatBigInt(data.juniorDebtBI) / DIVISION_VALUE;
    const mezzanine = formatBigInt(data.mezzanine) / DIVISION_VALUE;
    const cap = formatBigInt(data.cap) / DIVISION_VALUE;
    const preferredEquity = formatBigInt(data.preferredEquity) / DIVISION_VALUE;
    const commonEquity = formatBigInt(data.commonEquity) / DIVISION_VALUE;

    const newData = {
      datasets: [
        {
          label: 'Debt (Senior)',
          data: [seniorDebt],
          backgroundColor: '#323232',
          hoverBackgroundColor: '#323232',
          maxBarThickness: BAR_THICKNESS,
        },
        {
          label: 'Debt (Junior)',
          data: [juniorDebt],
          backgroundColor: '#565656',
          hoverBackgroundColor: '#565656',
          maxBarThickness: BAR_THICKNESS,
        },
        {
          label: 'Mezzanine',
          data: [mezzanine],
          backgroundColor: '#b0b0b0',
          hoverBackgroundColor: '#b0b0b0',
          maxBarThickness: BAR_THICKNESS,
        },
        {
          label: t('tokenization'),
          data: [cap],
          backgroundColor: theme.palette.primary.main,
          hoverBackgroundColor: theme.palette.primary.main,
          maxBarThickness: BAR_THICKNESS,
        },
        {
          label: 'Equity (Preferred)',
          data: [preferredEquity],
          backgroundColor: '#8c8c8c',
          hoverBackgroundColor: '#8c8c8c',
          maxBarThickness: BAR_THICKNESS,
        },
        {
          label: 'Equity (Common)',
          data: [commonEquity],
          backgroundColor: '#d4d4d4',
          hoverBackgroundColor: '#d4d4d4',
          maxBarThickness: BAR_THICKNESS,
        },
      ],
      labels: [''],
    };

    if (data === capitalStacks[0]) {
      options.plugins?.title
        ? (options.plugins.title.text = `${t('valuation')}: ${displayedValuation.toLocaleString(
            'en-US',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )} DAI (${formatDaiToFiatString(displayedValuation, 0, null, isPreSaleProperty)})`)
        : null;
    }

    setData(newData);
  }, [
    address,
    onChainProperties,
    selectedDate,
    t,
    theme.palette.primary.main,
    propertyStatus,
    isPreSaleProperty,
    onChainOffering,
  ]);

  useEffect(() => {
    updateDataset();
  }, [updateDataset]);

  return (
    <Grid container item xs justifyContent="flex-start" alignItems="center">
      {data ? (
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      ) : null}
    </Grid>
  );
};
export default CapitalStackChart;
