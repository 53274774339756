import styled from 'styled-components';

export const ChartWrapper = styled.div`
  height: 90%;
  width: 100%;
  position: relative;

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 20rem;
  }
`;
