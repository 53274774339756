import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'types';
import { fetchPropertyIssuer } from 'store/properties';
import Loader from 'components/custom/Loader';
import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import IssuerInfo from 'components/marketplace/propertyInfo/IssuerInfo';
import InvestmentHighlights from 'components/marketplace/propertyInfo/InvestmentHighlights';
import {
  propertiesIssuerLoadingSelector,
  propertiesIssuerSelector,
  propertyHighlightsSelector,
} from 'store/selectors/properties';

const BoldTypography = styled(Typography)`
  width: 100%;
  font-weight: 500;
`;

const HighlightGrid = styled(Grid)`
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.body.secondary};
`;

const PropertyAbout = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { propertyAddress } = useParams();

  const issuerInfo = useAppSelector(propertiesIssuerSelector);
  const highlights = useAppSelector(propertyHighlightsSelector);
  const isRequestingIssuer = useAppSelector(propertiesIssuerLoadingSelector);

  const { fetchingComplete: isIssuerDataFetchingComplete } = issuerInfo.meta;

  const [expandedCategory, setExpandedCategory] = useState('');

  const handleChange = (categoryId: string) => (_event: ChangeEvent<{}>, expanded: boolean) =>
    setExpandedCategory(() => (expanded ? categoryId : ''));

  // For some reason, there were two requests for the issuer info if the useEffect deps were
  // just the 'propertyAddress' and the 'dispatch' method. I had to implement additional checks in
  // order to prevent the double request from happening.
  useEffect(() => {
    if (propertyAddress && !isRequestingIssuer && !isIssuerDataFetchingComplete) {
      dispatch(fetchPropertyIssuer(propertyAddress));
    }
  }, [propertyAddress, isRequestingIssuer, isIssuerDataFetchingComplete, dispatch]);

  return (
    <Grid container item spacing={4}>
      <Grid container item xs={12} md={6}>
        <HighlightGrid container direction="column" item>
          <BoldTypography variant="h2" color="primary" gutterBottom>
            {/* TODO align the hight with issued by, try to keep the line when you expend the accordion */}
            {t('marketplace_property_about_investment_highlights')}
          </BoldTypography>
          {highlights?.length ? (
            <InvestmentHighlights
              expandedCategory={expandedCategory}
              handleChange={handleChange}
              highlights={highlights}
            />
          ) : (
            <ZeroItemsLayout
              style={{ padding: '0' }}
              size={10}
              desc={t('marketplace_property_about_investment_empty_highlights')}
              hint={t('marketplace_property_about_investment_empty_highlights_hint')}
            />
          )}
        </HighlightGrid>
      </Grid>
      <Grid container item xs={12} md={6}>
        {isRequestingIssuer ? <Loader size="6rem" /> : <IssuerInfo data={issuerInfo} />}
      </Grid>
    </Grid>
  );
};

export default PropertyAbout;
