import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const EnvelopeSVG: FC<TBaseIconProps & { className?: string }> = ({
  className,
  fillColor,
  height = '100%',
  width = '100%',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 48 32"
      className={className}
      width={width}
      height={height}
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M43.934,32.000 L4.066,32.000 C1.824,32.000 0.000,30.206 0.000,28.000 L0.000,4.000 C0.000,1.794 1.824,0.000 4.066,0.000 L43.934,0.000 C46.176,0.000 48.000,1.794 48.000,4.000 L48.000,28.000 C48.000,30.206 46.176,32.000 43.934,32.000 ZM2.000,28.000 C2.000,29.103 2.927,30.000 4.066,30.000 L43.934,30.000 C45.073,30.000 46.000,29.103 46.000,28.000 L46.000,4.000 C46.000,3.834 45.973,3.674 45.933,3.520 L24.651,21.760 C24.463,21.920 24.232,22.000 24.000,22.000 C23.768,22.000 23.537,21.920 23.349,21.760 L2.067,3.520 C2.027,3.674 2.000,3.834 2.000,4.000 L2.000,28.000 ZM4.066,2.000 C3.860,2.000 3.665,2.038 3.477,2.093 L24.000,19.683 L44.523,2.093 C44.335,2.038 44.140,2.000 43.934,2.000 L4.066,2.000 Z"
      />
    </svg>
  );
};

export default EnvelopeSVG;
