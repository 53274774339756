import type { WalletTypeKeys } from './wallet';

export const USER_WALLET_STATUS = {
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED',
  ON_BLOCKCHAIN: 'ON_BLOCKCHAIN',
  PENDING: 'PENDING',
  /** @deprecated Should not be used anymore */
  ETH_CHECKED: 'ETH_CHECKED',
  /** @deprecated Should not be used anymore. Refer to {@link USER_WALLET_STATUS.ON_BLOCKCHAIN|ON_BLOCKCHAIN} status */
  WHITELISTED: 'WHITELISTED',
} as const;

export type UserWalletStatusTypes = (typeof USER_WALLET_STATUS)[keyof typeof USER_WALLET_STATUS];

/**
 *
 * Bank transaction history record that shows details about the user's
 * exchange of fiat for DAI by making a bank TX to Blocksquare.
 *
 * There were legal problems with this, so these are not
 * used currently. Could change at a later date though.
 *
 */
export type WalletBankTransactionHistoric = {
  amount: number;
  bankTransactionStatus: string;
  bankTransactionType: string;
  created: Date;
  currency: string;
  received: number;
  receivedCurrency: string;
  uniqID: string;
};

export type UserWallet = {
  address: string;
  addressStatus: UserWalletStatusTypes;
  addressType: string;
  bankTransactions: WalletBankTransactionHistoric[];
  created: Date;
  name: string;
  referenceNumber: string;
};

export type UserWallets = Array<UserWallet>;

export type UserWalletType = {
  isInitialState: boolean;
  isFetchingWallets: boolean;
  isInitialFetchingDone: boolean;
  isUpdatingWalletAddress: boolean;
  fetchErrorMessage: string | null;
  updateWalletAddressErrorMessage: string | null;
  updateWalletAddressSuccess: boolean;
  walletType: string;
  wallets: UserWallets;
  filteredWallets: string[] | null;
  isUpdatingWalletName: boolean;
  updateWalletNameErrorMessage: string | null;
  hasCheckedOfferingInvestmentAllowance: boolean;
  isCheckingOfferingInvestmentAllowance: boolean;
  isApprovingOfferingInvestmentAllowance: boolean;
  hasSetOfferingInvestmentAllowance: boolean;
  needToDisconnect: boolean;
  hasDisconnected: boolean;
};

export type SignedWalletData = {
  signature: string;
  msg: string;
  walletType: WalletTypeKeys;
};

export type UpdateWalletData = {
  address: string;
  name: string;
};
