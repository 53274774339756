import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const DocumentSvg: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 36 47.938"
    >
      <path
        d="M36.000,13.993 C36.000,14.028 35.984,14.057 35.980,14.091 L35.980,45.915 C35.980,47.031 35.072,47.939 33.956,47.939 L2.036,47.939 C0.913,47.939 -0.001,47.026 -0.001,45.904 L-0.001,2.050 C-0.001,0.927 0.913,0.015 2.036,0.015 L21.988,0.015 C22.033,0.015 22.071,0.035 22.115,0.040 C22.160,0.046 22.200,0.054 22.244,0.067 C22.415,0.113 22.574,0.186 22.695,0.308 L35.579,13.178 C35.610,13.200 35.626,13.239 35.655,13.264 C35.684,13.291 35.706,13.322 35.732,13.353 C35.887,13.528 36.000,13.740 36.000,13.993 ZM2.000,1.938 L1.998,45.904 L34.000,45.938 L34.000,14.938 L22.000,14.938 C21.448,14.938 20.988,14.544 20.988,13.993 L20.988,1.938 L2.000,1.938 ZM22.987,3.423 L22.987,12.994 L32.568,12.994 L22.987,3.423 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DocumentSvg;
