import { createSlice } from '@reduxjs/toolkit';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { RESET_STATE } from './sharedActions';
import type { RegistrationData, RegistrationState, RequestStatus } from 'types';
import { ApiStatus, NOTIFICATION_TYPES, createAppAsyncThunk, genericErrorReasons } from 'types';

const initialState: RegistrationState = {
  isRegistering: false,
  isRequestingResendActivation: false,
  alreadyRegistered: false,
  email: null,
  errorMessage: null,
  errorMessageResendActivation: null,
  token: {
    isVerifying: false,
    errorMessage: null,
    verified: false,
  },
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    resetVerificationState(state) {
      state.token.verified = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(registerUserRequest.pending, (state) => {
      state.isRegistering = true;
      state.email = null;
    });
    builder.addCase(registerUserRequest.fulfilled, (state, { payload }) => {
      state.isRegistering = false;
      state.alreadyRegistered = true;
      state.email = payload;
    });
    builder.addCase(registerUserRequest.rejected, (state, { payload }) => {
      state.isRegistering = false;
      state.email = null;
      state.errorMessage = payload as string;
    });
    builder.addCase(tokenActivationRequest.pending, (state) => {
      state.token.isVerifying = true;
      state.token.errorMessage = null;
    });
    builder.addCase(tokenActivationRequest.fulfilled, (state) => {
      state.token.isVerifying = false;
      state.token.verified = true;
    });
    builder.addCase(tokenActivationRequest.rejected, (state, { payload }) => {
      state.token.isVerifying = false;
      state.token.errorMessage = payload as string;
    });
    builder.addCase(requestNewActivationEmailRequest.pending, (state) => {
      state.isRequestingResendActivation = true;
      state.errorMessageResendActivation = null;
    });
    builder.addCase(requestNewActivationEmailRequest.fulfilled, (state, { payload }) => {
      state.isRequestingResendActivation = false;
      state.email = payload;
    });
    builder.addCase(requestNewActivationEmailRequest.rejected, (state, { payload }) => {
      state.isRequestingResendActivation = false;
      state.errorMessageResendActivation = payload as string;
    });
  },
});

/**
 * Request to register a new user
 *
 */
export const registerUserRequest = createAppAsyncThunk(
  'registration/user',
  async (registrationData: RegistrationData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/register', registrationData)
      .then(({ data }) => {
        const { status, reason } = data;

        if (status !== ApiStatus.SUCCESS) {
          return rejectWithValue(reason as string);
        }

        // Setting data for first user login welcome tour dialog
        // - see React Joyride configuraiton in Dashboard.js
        const userId = data.reason;
        window.localStorage.setItem(`firstLoginTourData-${userId}`, 'true');
        //

        return registrationData.email;
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Request to active an user account using the provided token
 *
 */
export const tokenActivationRequest = createAppAsyncThunk(
  'registration/tokenActivate',
  async (token: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>(`/activate/${token}`, { token })
      .then(({ data }) => {
        const { status, reason } = data;
        if (status !== ApiStatus.SUCCESS) return rejectWithValue(reason as string);
        return;
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Request to sent a new activation email for a given email
 *
 */
export const requestNewActivationEmailRequest = createAppAsyncThunk(
  'registration/newActivationEmail',
  async (email: string, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/resend-activation', { email })
      .then(({ data }) => {
        const { status, reason } = data;
        if (status !== ApiStatus.SUCCESS) {
          const failureMsg = i18nInstance.t(reason as string);
          dispatch(createNotification(failureMsg, 'error'));
          return rejectWithValue(failureMsg);
        }

        const successMsg = i18nInstance.t('verify_email_activation_link_re_sent');
        dispatch(createNotification(successMsg));
        return email;
      })
      .catch(() => {
        const errorMesssage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(errorMesssage);
      });
  }
);

export const { resetVerificationState } = registrationSlice.actions;
export default registrationSlice.reducer;
