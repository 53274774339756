import { addNotification } from 'store/notification';
import { NOTIFICATION_TYPES } from 'types/store';
import type { NotificationKeys, NotificationVariants } from 'types/store';
import { i18nInstance } from 'utils';

export const createNotification = (message: string, variant: NotificationVariants = 'success') => {
  return addNotification({
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant,
      autoHideDuration: 3000,
    },
  });
};

export const createNotificationByType = (type: NotificationKeys) => {
  let isError = false;
  let message = '';
  switch (type) {
    case NOTIFICATION_TYPES.BANK_ACCOUNT_SAVED:
      message = i18nInstance.t('notification_bank_account_saved');
      break;
    case NOTIFICATION_TYPES.NETWORK_ERROR:
      isError = true;
      message = i18nInstance.t('network_error');
      break;
    case NOTIFICATION_TYPES.ORDER_CREATION_ERROR:
      isError = true;
      message = i18nInstance.t('notification_create_order_error');
      break;
    case NOTIFICATION_TYPES.ORDER_FILLED:
      message = i18nInstance.t('notification_order_filled');
      break;
    case NOTIFICATION_TYPES.ORDER_PLACED:
      message = i18nInstance.t('notification_order_placed');
      break;
    case NOTIFICATION_TYPES.PASSWORD_CHANGED:
      message = i18nInstance.t('notification_password_changed');
      break;
    case NOTIFICATION_TYPES.WALLET_NAME_CHANGED:
      message = i18nInstance.t('notification_wallet_name_changed');
      break;
    case NOTIFICATION_TYPES.PASSWORD_FORGET_REQUESTED:
      message = i18nInstance.t('notification_password_reset_request_success');
      break;
    case NOTIFICATION_TYPES.SETTINGS_SAVED:
      message = i18nInstance.t('notification_settings_saved');
      break;
    default:
      throw new Error('Invalid TYPE for notification');
  }

  return addNotification({
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: isError ? 'error' : 'success',
      autoHideDuration: 3000,
    },
  });
};
