import { createSlice } from '@reduxjs/toolkit';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { hideDialogs } from 'store/dialogs';
import { RESET_STATE } from './sharedActions';
import type {
  ForgotPasswordData,
  PasswordState,
  RequestStatus,
  UpdatePasswordData,
  UpdatePasswordDataForToken,
} from 'types';
import { ApiStatus, NOTIFICATION_TYPES, createAppAsyncThunk, genericErrorReasons } from 'types';

const initialState: PasswordState = {
  isUpdatingPassword: false,
  isUpdatingPasswordByToken: false,
  passwordUpdateError: null,
  passwordUpdateByTokenError: null,
  isRequestingNewPassword: false,
  passwordRequestError: null,
  passwordRequestErrorType: null,
  newPasswordSent: false,
  newPasswordSet: false,
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    resetForgotPasswordFailureError(state) {
      state.passwordRequestErrorType = null;
      state.passwordRequestError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(forgotPasswordRequest.pending, (state) => {
      state.isRequestingNewPassword = true;
      state.passwordRequestError = null;
      state.newPasswordSent = false;
    });
    builder.addCase(forgotPasswordRequest.fulfilled, (state) => {
      state.isRequestingNewPassword = false;
      state.newPasswordSent = true;
    });
    builder.addCase(forgotPasswordRequest.rejected, (state, { payload }) => {
      state.isRequestingNewPassword = false;
      state.passwordRequestErrorType = payload as string;
      state.passwordRequestError = i18nInstance.t(payload as string);
    });
    builder.addCase(updatePasswordRequest.pending, (state) => {
      state.isUpdatingPassword = true;
      state.passwordUpdateError = null;
    });
    builder.addCase(updatePasswordRequest.fulfilled, (state) => {
      state.isUpdatingPassword = false;
    });
    builder.addCase(updatePasswordRequest.rejected, (state, { payload }) => {
      state.isUpdatingPassword = false;
      state.passwordUpdateError = i18nInstance.t(payload as string);
    });
    builder.addCase(setPasswordForTokenRequest.pending, (state) => {
      state.isUpdatingPasswordByToken = true;
      state.passwordUpdateByTokenError = null;
      state.newPasswordSent = false;
    });
    builder.addCase(setPasswordForTokenRequest.fulfilled, (state) => {
      state.isUpdatingPasswordByToken = false;
      state.newPasswordSet = true;
    });
    builder.addCase(setPasswordForTokenRequest.rejected, (state, { payload }) => {
      state.isUpdatingPasswordByToken = false;
      state.passwordUpdateByTokenError = i18nInstance.t(payload as string);
      state.newPasswordSet = false;
    });
  },
});

/**
 * Request to update the password for a given user
 *
 */
export const updatePasswordRequest = createAppAsyncThunk(
  'password/updatePassword',
  async (passwordData: UpdatePasswordData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/account/change-password', passwordData)
      .then(({ data }) => {
        const { reason, status } = data;
        if (status !== ApiStatus.SUCCESS) {
          dispatch(createNotification(i18nInstance.t(reason as string), 'error'));
          return rejectWithValue(reason as string);
        }

        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_CHANGED));
        return;
      })
      .catch(() => {
        const networkError = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

/**
 * Request the password reset email for a given user
 *
 */
export const forgotPasswordRequest = createAppAsyncThunk(
  'password/forgotPassword',
  async (forgotPasswordData: ForgotPasswordData, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/forgot-password', forgotPasswordData)
      .then(({ data }) => {
        const { reason, status } = data;
        if (status !== ApiStatus.SUCCESS) {
          return rejectWithValue(reason as string);
        }

        dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_FORGET_REQUESTED));
        return data;
      })
      .catch(() => {
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(genericErrorReasons.network_error);
      });
  }
);

/**
 * Update the password for a given user using a token
 *
 */
export const setPasswordForTokenRequest = createAppAsyncThunk(
  'password/setPasswordForToken',
  async (passwordData: UpdatePasswordDataForToken, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<RequestStatus>('/reset-password', passwordData)
      .then(({ data }) => {
        const { reason, status } = data;
        if (status !== ApiStatus.SUCCESS) {
          dispatch(createNotification(i18nInstance.t(reason as string), 'error'));
          return rejectWithValue(reason as string);
        }
        dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_CHANGED));
        return;
      })
      .catch(() => {
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(genericErrorReasons.network_error);
      });
  }
);

export const { resetForgotPasswordFailureError } = passwordSlice.actions;
export default passwordSlice.reducer;
