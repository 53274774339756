import type { OrderBy } from 'types';

interface RawPortfolioRevenueData {
  property: {
    id: string;
    token: {
      symbol: string;
    };
    cp: {
      id: string;
    };
  };
  pendingRevenue: string;
}

interface RawPortfolioPropertyData {
  amount: string;
  property: {
    id: string;
    token: {
      name: string;
      symbol: string;
      totalSupply: string;
    };
    cp: {
      id: string;
    };
    tokenValuation: string;
    propertyValuation: string;
  };
}

interface RawPortfolioWallet {
  id: string;
  pendingRevenue: Array<RawPortfolioRevenueData>;
  properties: Array<RawPortfolioPropertyData>;
}

/**
 * The Portfolio query response type consists of information about each of user's wallets.
 *
 * Namely, information on what property tokens this wallet owns and how much distributed
 * revenue it is eligible to withdraw for each of those tokens.
 *
 */
export interface RawPortfolioQueryRes {
  user: {
    wallets: Array<RawPortfolioWallet>;
  };
}

// End of raw request type portion
//////////////////////////////////

export interface PortfolioRevenueData {
  property: {
    id: string;
    token: {
      symbol: string;
    };
  };
  pendingRevenue: bigint;
}

export interface PortfolioPropertyData {
  amount: bigint;
  property: {
    id: string;
    token: {
      name: string;
      symbol: string;
      totalSupply: bigint;
    };
    cp: {
      id: string;
    };
    tokenValuation: bigint;
    propertyValuation: bigint;
  };
}

export interface PortfolioWallet {
  id: string;
  pendingRevenue: Array<PortfolioRevenueData>;
  properties: Array<PortfolioPropertyData>;
}

export class PortfolioQueryRes {
  constructor(rawPortfolioQueryRes: RawPortfolioQueryRes) {
    if (!rawPortfolioQueryRes.user || !rawPortfolioQueryRes.user.wallets) {
      throw new Error('improper-query-response-structure');
    }

    const {
      user: { wallets },
    } = rawPortfolioQueryRes;

    if (!Array.isArray(wallets)) {
      throw new Error('improper-portfolio-wallet-array');
    }

    wallets.forEach((wallet) => {
      const { pendingRevenue, properties } = wallet;
      if (!Array.isArray(pendingRevenue) || !Array.isArray(properties)) {
        throw new Error('improper-portfolio-wallet-children-arrays');
      }
    });

    this.user = {
      wallets: rawPortfolioQueryRes.user.wallets.map((wallet) => {
        const { pendingRevenue, properties, id: walletAddress } = wallet;

        const properPendingRevenue = pendingRevenue.map((revenueEntry) => {
          return {
            property: {
              id: revenueEntry?.property?.id || '',
              token: {
                symbol: revenueEntry?.property?.token?.symbol || '',
              },
              cp: {
                id: revenueEntry?.property?.cp?.id || '',
              },
            },
            pendingRevenue: BigInt(revenueEntry?.pendingRevenue || 0),
          };
        });

        const properProperties = properties.map((propertyEntry) => {
          return {
            amount: BigInt(propertyEntry?.amount || 0),
            property: {
              id: propertyEntry?.property?.id || '',
              token: {
                name: propertyEntry?.property?.token.name || '',
                symbol: propertyEntry?.property?.token.symbol || '',
                totalSupply: BigInt(propertyEntry?.property?.token?.totalSupply || 0),
              },
              cp: {
                id: propertyEntry?.property?.cp?.id || '',
              },
              tokenValuation: BigInt(propertyEntry?.property?.tokenValuation || 0),
              propertyValuation: BigInt(propertyEntry?.property?.propertyValuation || 0),
            },
          };
        });

        return {
          id: walletAddress,
          pendingRevenue: properPendingRevenue,
          properties: properProperties,
        };
      }),
    };
  }

  user: {
    wallets: Array<PortfolioWallet>;
  };
}

export interface ReformattedRevenueInfoEntry {
  propertyId: string;
  perWalletAmounts: Array<{ walletAddress: string; amount: bigint }>;
  pendingRevenue: bigint;
}

export interface AdaptedPortfolioProperty {
  id: string;
  amount: number;
  valuation: number;
  companyId: string;
  revenueInfo: ReformattedRevenueInfoEntry;
  name: string;
  symbol: string;
}

export type PortfolioPropertySortMethods = 'amount' | 'value';

export type PortfolioPropertySortOrders = OrderBy.Desc | OrderBy.Asc;

export interface PerWalletRevenue {
  address: string;
  revenues: Array<PortfolioRevenueData>;
}

export interface PortfolioState {
  pendingRevenue: number;
  perWalletRevenues: Array<PerWalletRevenue>;
  isRequestingProperties: boolean;
  errorMessageProperties: string | null;
  portfolioQueryRes: PortfolioQueryRes | null;
}
