import PropertyTradesTable from 'components/marketplace/PropertyTrades';
import PropertyLocation from 'components/marketplace/PropertyLocation';
import PropertyDocuments from 'components/marketplace/PropertyDocuments';
import PropertyStats from 'components/marketplace/PropertyStats';
import PropertyAbout from 'components/marketplace/PropertyAbout';

export enum Pages {
  ABOUT = 'about',
  TRADE = 'trade',
  LOCATION = 'location',
  DOCUMENTS = 'documents',
  STATS = 'charts',
}

export const pagesComponents = {
  [Pages.ABOUT]: PropertyAbout,
  [Pages.TRADE]: PropertyTradesTable,
  [Pages.LOCATION]: PropertyLocation,
  [Pages.DOCUMENTS]: PropertyDocuments,
  [Pages.STATS]: PropertyStats,
};

export type TMenuNavBarProps = {
  pageMap: Pages[];
  pageHiddenOnMobile: Pages;
  activePage: Pages;
  handlePageChange: (value: Pages) => void;
};
