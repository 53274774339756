import { BrowserProvider, JsonRpcProvider } from 'ethers';

const getNetworkName = () => {
  switch (process.env.REACT_APP_CHAIN_ID) {
    case '1':
      return 'mainnet';
    case '11155111':
      return 'sepolia';
    case '5':
      return 'goerli';
    default:
      throw new Error('Invalid network');
  }
};

export const jsonRpcProvider = new JsonRpcProvider(
  `https://${getNetworkName()}.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
);

let globalProvider: BrowserProvider | JsonRpcProvider = jsonRpcProvider;

// TODO: Figure out the right type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setWeb3Library(provider: any) {
  if (provider) {
    globalProvider = new BrowserProvider(provider.provider);
  } else {
    globalProvider = jsonRpcProvider;
  }
}

export function getProvider(): BrowserProvider | JsonRpcProvider {
  return globalProvider;
}

export async function signPersonalMessage(message: string): Promise<string> {
  const provider = getProvider();
  const signer = await provider.getSigner();
  return await signer.signMessage(message);
}
