import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';

const initialState = {
  nextRoute: '',
};

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    nextRouteChange(state, { payload }: PayloadAction<string>) {
      state.nextRoute = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { nextRouteChange } = routeSlice.actions;
export default routeSlice.reducer;
