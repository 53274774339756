import { Helmet } from 'react-helmet';
import { getCompanyInformation } from 'utils/company';

export default function CustomHead() {
  const { title, faviconImage } = getCompanyInformation();

  return (
    <Helmet titleTemplate={`${title} - %s`} defaultTitle={title || ''}>
      <link id="favicon" rel="shortcut icon" href={faviconImage} />
    </Helmet>
  );
}
