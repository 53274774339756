import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';
import type {
  BuyConfirmDialogData,
  BuyOfferingTokensData,
  ChangeWalletNameDialogData,
  DialogData,
  DialogTypes,
  DialogsState,
  FailedTransactionDialogData,
  PendingTransactionDialogData,
} from 'types';

const intitialDialogData: DialogData = {
  buyConfirm: {
    token: '',
    amount: 0,
    transactionHash: '',
  },
  buyOfferingTokens: {
    address: '',
  },
  changeWalletName: {
    address: '',
    walletName: '',
  },
  failedTransaction: {
    errorMessage: '',
  },
  pendingTransaction: {
    type: '',
  },
};

const initialState: DialogsState = {
  activeDialog: null,
  activeDialogData: intitialDialogData,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    showDialog(
      state,
      {
        payload,
      }: PayloadAction<{
        type: DialogTypes;
        buyConfirmData?: BuyConfirmDialogData;
        buyOfferingTokensData?: BuyOfferingTokensData;
        changeWalletNameData?: ChangeWalletNameDialogData;
        failedTransactionData?: FailedTransactionDialogData;
        pendingTransactionData?: PendingTransactionDialogData;
      }>
    ) {
      const {
        type,
        buyConfirmData,
        buyOfferingTokensData,
        changeWalletNameData,
        failedTransactionData,
        pendingTransactionData,
      } = payload;
      const newDialogData = { ...intitialDialogData };
      if (buyConfirmData) newDialogData.buyConfirm = buyConfirmData;
      if (buyOfferingTokensData) newDialogData.buyOfferingTokens = buyOfferingTokensData;
      if (changeWalletNameData) newDialogData.changeWalletName = changeWalletNameData;
      if (failedTransactionData) newDialogData.failedTransaction = failedTransactionData;
      if (pendingTransactionData) newDialogData.pendingTransaction = pendingTransactionData;
      state.activeDialog = type;
      state.activeDialogData = newDialogData;
    },
    hideDialogs(state) {
      state.activeDialog = null;
      state.activeDialogData = intitialDialogData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { hideDialogs, showDialog } = dialogsSlice.actions;
export default dialogsSlice.reducer;
