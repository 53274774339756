import { lazy } from 'react';
import type { CustomRouteWrapper } from 'types';

const Kyc = lazy(() => import('pages/home/Kyc'));
const KycCallback = lazy(() => import('pages/home/KycCallback'));

const kycRoute: CustomRouteWrapper = {
  id: 'kyc',
  path: '/kyc',
  component: Kyc,
};

const kycCallbackRoute: CustomRouteWrapper = {
  id: 'KYCCallback',
  path: '/kyc/callback',
  name: 'KycCallback',
  component: KycCallback,
};

export const kycRoutes = [kycRoute];
export const kycCallbackRoutes = [kycCallbackRoute];
