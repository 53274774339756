import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const NoBorderCheckSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31.997429 21.985085"
      preserveAspectRatio="xMidYMid"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillColor}
        d="m 13.155304,21.666085 a 1,1 0 0 1 -0.71,0.319 h -0.022 a 0.999,0.999 0 0 1 -0.702,-0.288 l -11.41199993,-11.25 A 1,1 0 1 1 1.7133041,9.0230849 L 12.392304,19.550085 30.279304,0.30408493 a 1,1 0 0 1 1.464,1.36199997 z"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};
export default NoBorderCheckSVG;
