import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const EyeglassesSVG: FC<TBaseIconProps> = ({ fillColor, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 59.719 36.844"
    >
      <path
        d="M56.482,33.789 C54.476,35.809 51.548,36.833 47.781,36.833 L44.712,36.833 C44.699,36.833 44.688,36.833 44.676,36.832 C37.161,36.751 33.649,28.981 32.978,24.406 C32.703,24.087 31.562,22.898 29.862,22.898 C28.171,22.898 27.035,24.072 26.753,24.399 C26.084,28.973 22.571,36.751 15.053,36.832 C15.042,36.833 15.029,36.833 15.017,36.833 L11.948,36.833 C8.181,36.833 5.254,35.809 3.247,33.789 C-0.050,30.472 -0.017,25.326 0.001,22.560 L0.003,22.022 C0.003,21.439 0.064,20.917 0.170,20.437 C-0.014,20.145 -0.055,19.773 0.118,19.446 L9.077,2.525 C9.161,2.384 10.493,0.231 13.110,0.013 C15.489,-0.176 18.031,1.268 20.667,4.332 C21.025,4.749 20.979,5.378 20.562,5.736 C20.147,6.096 19.516,6.048 19.158,5.631 C16.991,3.114 14.953,1.856 13.275,1.997 C11.685,2.129 10.818,3.492 10.809,3.506 L3.753,16.835 C5.881,16.128 8.924,15.930 12.944,15.930 C18.289,15.930 25.636,17.780 26.728,21.848 C27.519,21.348 28.564,20.907 29.862,20.907 C31.163,20.907 32.209,21.349 33.000,21.851 C34.091,17.781 41.439,15.930 46.786,15.930 C50.805,15.930 53.847,16.128 55.976,16.835 L48.892,3.456 C48.889,3.455 48.034,2.117 46.432,1.995 C44.760,1.872 42.727,3.125 40.572,5.631 C40.214,6.048 39.587,6.096 39.168,5.736 C38.751,5.378 38.704,4.749 39.063,4.332 C41.698,1.268 44.238,-0.175 46.620,0.013 C49.237,0.231 50.568,2.384 50.624,2.475 L59.612,19.446 C59.785,19.773 59.743,20.144 59.560,20.436 C59.666,20.916 59.727,21.438 59.727,22.022 L59.729,22.560 C59.748,25.325 59.780,30.471 56.482,33.789 ZM12.944,17.921 C2.523,17.921 1.994,19.479 1.994,22.022 L1.992,22.573 C1.975,25.223 1.946,29.656 4.660,32.387 C6.279,34.016 8.731,34.842 11.948,34.842 L14.935,34.842 C21.928,34.842 24.890,26.297 24.890,22.898 C24.890,19.965 18.594,17.921 12.944,17.921 ZM57.738,22.573 L57.736,22.022 C57.736,19.479 57.207,17.921 46.786,17.921 C41.135,17.921 34.840,19.965 34.840,22.898 C34.840,26.297 37.801,34.842 44.795,34.842 L47.781,34.842 C50.998,34.842 53.451,34.016 55.071,32.386 C57.784,29.655 57.756,25.222 57.738,22.573 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EyeglassesSVG;
