import type { AdaptedPortfolioProperty, PropertyDocument } from 'types/store';
import { desc } from 'utils/portfolioSort/desc';

export type PortfolioPropertySortMethods = 'amount' | 'value' | 'date' | 'type';
type PortfolioPropertySortOrders = 'asc' | 'desc';

type Element = AdaptedPortfolioProperty | PropertyDocument;

export const getSorting = (
  order: PortfolioPropertySortOrders,
  orderBy: PortfolioPropertySortMethods
) => {
  return order === 'desc'
    ? (a: Element, b: Element) => desc(a, b, orderBy)
    : (a: Element, b: Element) => -desc(a, b, orderBy);
};
