export const UserWhitelistStatusTypes = {
  CHECKED: 'CHECKED',
  DENIED: 'DENIED',
  PENDING: 'PENDING',
  WHITELISTED: 'WHITELISTED',
} as const;

export type UserWhitelistStatusTypes =
  (typeof UserWhitelistStatusTypes)[keyof typeof UserWhitelistStatusTypes];

export type UserState = {
  isInitialState: boolean;
  isFetchingUserInformations: boolean;
  hasFetchedInitialUserInformation: boolean;
  errorFetchingUserInformations: string | null;
  preferredLanguage: string;
  email: string;
  birth: string;
  country: string;
  firstName: string;
  lastName: string;
  userId: string;
  // shows us if the user's wallets can hold this CP's property tokens
  cpWhitelistingStatus: UserWhitelistStatusTypes | null;
};

export type UserInformation = {
  birth?: string;
  country?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  identifier: string;
  status: UserWhitelistStatusTypes;
  languageCode: string;
};
