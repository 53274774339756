import sortByTimestamp from 'utils/newestTimestampSort';

export type CapitalStackRaw = {
  cap: string;
  commonEquity: string;
  id: string;
  juniorDebt: string;
  mezzanine: string;
  preferredEquity: string;
  seniorDebt: string;
  timestamp: string;
};

type HolderRaw = {
  amount: string;
  wallet: {
    id: string;
  };
};

type Holder = {
  amount: bigint;
  id: string;
};

type TokenRaw = {
  name: string;
  symbol: string;
  totalHolders: number;
  totalSupply: string;
};

export type OnChainOfferingRaw = {
  id: string;
  maxInvestment: string;
  minInvestment: string;
  presaleMaxInvestment: string;
  presaleMinInvestment: string;
  presaleStart: number;
  presaleEnd: number;
  saleStart: number;
  saleEnd: number;
  price: string;
  property: {
    token: TokenRaw;
    capitalStacks: Array<CapitalStackRaw>;
  };
};

export type OnChainOfferingsRaw = {
  propertyOfferings: Array<OnChainOfferingRaw> | null;
};

export type OnChainPropertyRaw = {
  capitalStacks: Array<CapitalStackRaw>;
  holders: Array<HolderRaw>;
  id: string;
  ipfs: string;
  propertyValuation: string;
  token: TokenRaw;
  tokenValuation: string;
};

export type OnChainPropertiesRaw = {
  properties: Array<OnChainPropertyRaw> | null;
};

export type PropertyIssuerRequest = {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  walletAddress: string;
  websiteUrl: string;
  licencedIssuerStats: {
    averageHolder: number;
    averageYield: number;
    listedProperties: number;
    profitDistributed: number;
    totalInvestors: number;
    totalValuation: number;
  };
};

export class Token {
  constructor(tokenRaw: TokenRaw) {
    this.name = tokenRaw?.name ?? '';
    this.symbol = tokenRaw?.symbol ?? '';
    this.totalSupply = BigInt(tokenRaw?.totalSupply ?? 0);
    this.totalHolders = tokenRaw?.totalHolders ?? 0;
  }

  name: string;
  symbol: string;
  totalHolders: number;
  totalSupply: bigint;
}

export class CapitalStack {
  constructor(capitalStackRaw: CapitalStackRaw) {
    this.id = capitalStackRaw.id ?? '';
    this.cap = BigInt(capitalStackRaw.cap ?? 0);
    this.commonEquity = BigInt(capitalStackRaw.commonEquity ?? 0);
    this.preferredEquity = BigInt(capitalStackRaw.preferredEquity ?? 0);
    this.mezzanine = BigInt(capitalStackRaw.mezzanine ?? 0);
    this.juniorDebtBI = BigInt(capitalStackRaw.juniorDebt ?? 0);
    this.seniorDebt = BigInt(capitalStackRaw.seniorDebt ?? 0);
    this.timestamp = capitalStackRaw.timestamp ?? 0;
  }

  cap: bigint;
  commonEquity: bigint;
  id: string;
  juniorDebtBI: bigint;
  mezzanine: bigint;
  preferredEquity: bigint;
  seniorDebt: bigint;
  timestamp: string;
}

export class OnChainOffering {
  constructor(onChainOfferingRaw: OnChainOfferingRaw) {
    this.id = onChainOfferingRaw.id ?? '';
    this.maxInvestment = BigInt(onChainOfferingRaw.maxInvestment ?? 0);
    this.minInvestment = BigInt(onChainOfferingRaw.minInvestment ?? 0);
    this.presaleMaxInvestment = BigInt(onChainOfferingRaw.presaleMaxInvestment ?? 0);
    this.presaleMinInvestment = BigInt(onChainOfferingRaw.presaleMinInvestment ?? 0);
    this.presaleStart = onChainOfferingRaw.presaleStart ?? 0;
    this.presaleEnd = onChainOfferingRaw.presaleEnd ?? 0;
    this.saleStart = onChainOfferingRaw.saleStart ?? 0;
    this.saleEnd = onChainOfferingRaw.saleEnd ?? 0;
    this.price = Number(onChainOfferingRaw.price) ?? 0;
    this.property = {
      token: new Token(onChainOfferingRaw.property.token),
      capitalStacks:
        onChainOfferingRaw.property.capitalStacks?.map(
          (capitalStackRaw) => new CapitalStack(capitalStackRaw)
        ) ?? [],
    };
  }

  id: string;
  maxInvestment: bigint;
  minInvestment: bigint;
  presaleMaxInvestment: bigint;
  presaleMinInvestment: bigint;
  presaleStart: number;
  presaleEnd: number;
  saleStart: number;
  saleEnd: number;
  price: number;
  property: {
    token: Token;
    capitalStacks: Array<CapitalStack>;
  };
}
export class IssuerData {
  constructor(rawData: PropertyIssuerRequest) {
    this.name = rawData.name || '';
    this.address = rawData.address || '';
    this.latitude = rawData.latitude || 0;
    this.longitude = rawData.longitude || 0;
    this.walletAddress = rawData.walletAddress || '';
    this.websiteUrl = rawData.websiteUrl || '';
    this.licencedIssuerStats = {
      averageHolder: rawData.licencedIssuerStats.averageHolder || 0,
      averageYield: rawData.licencedIssuerStats.averageYield || 0,
      listedProperties: rawData.licencedIssuerStats.listedProperties || 0,
      profitDistributed: rawData.licencedIssuerStats.profitDistributed || 0,
      totalInvestors: rawData.licencedIssuerStats.totalInvestors || 0,
      totalValuation: rawData.licencedIssuerStats.totalValuation || 0,
    };
  }

  name: string;
  address: string;
  latitude: number;
  longitude: number;
  licencedIssuerStats: {
    averageHolder: number;
    averageYield: number;
    listedProperties: number;
    profitDistributed: number;
    totalInvestors: number;
    totalValuation: number;
  };
  walletAddress: string;
  websiteUrl: string;
}

export type OnChainOfferings = Record<string, OnChainOffering>;

export class OnChainProperty {
  constructor(onChainPropertyRaw: OnChainPropertyRaw) {
    this.id = onChainPropertyRaw.id;
    this.ipfs = onChainPropertyRaw.ipfs;
    this.tokenValuation = BigInt(onChainPropertyRaw.tokenValuation ?? 0);
    this.propertyValuation = BigInt(onChainPropertyRaw.propertyValuation ?? 0);
    this.token = new Token(onChainPropertyRaw.token);
    this.capitalStacks = sortByTimestamp(onChainPropertyRaw.capitalStacks).map(
      (capitalStackRaw) => new CapitalStack(capitalStackRaw)
    );
    this.holders =
      onChainPropertyRaw.holders?.map((rawHolder) => {
        const id = rawHolder.wallet?.id ?? '';
        const amount = BigInt(rawHolder.amount ?? 0);
        return {
          amount,
          id,
        };
      }) ?? [];
  }
  id: string;
  capitalStacks: Array<CapitalStack>;
  ipfs: string;
  propertyValuation: bigint;
  token: Token;
  tokenValuation: bigint;
  holders: Array<Holder>;
}
export type PropertyIssuer = {
  address: string;
  latitude: number;
  longitude: number;
  licencedIssuerStats: {
    averageHolder: number;
    averageYield: number;
    listedProperties: number;
    profitDistributed: number;
    totalInvestors: number;
    totalValuation: number;
  };
  name: string;
  walletAddress: string;
  websiteUrl: string;
  meta: {
    fetchingComplete: boolean;
  };
};

export type OnChainProperties = Record<string, OnChainProperty>;

export type PropertyDocument = {
  name: string;
  /**
   * The file path to the document
   */
  file: string;
  documentTypeEnum: string;
  created: string;
};

export type PropertyStatistic = {
  availableTokens: number;
  averageRevenue: number;
  averageYearlyRevenue: number;
  profitDistributed: number;
  projectedYield: number;
  propertyValuation: number;
  staticYield: number;
  tokenValuation: number;
  valuation: number;
};

export type PropertyStatisticsCapitalStack = {
  commonEquity: number;
  preferredEquity: number;
  mezzanine: number;
  juniorDebt: number;
  seniorDebt: number | null;
  tokenization: number;
  changed: string;
};

export type PropertyStatisticsTokenBalances = {
  balance: number;
  address: string;
};

export type PropertyStatisticsRevenueDistributions = {
  amount: number;
  date: string;
  from: string;
  to: string;
};

export type PropertyStatistics = {
  capitalStacks: Array<PropertyStatisticsCapitalStack>;
  tokenBalances: Array<PropertyStatisticsTokenBalances>;
  revenueDistributions: Array<PropertyStatisticsRevenueDistributions>;
  valuation: number;
};

export type PropertyHighlight = {
  id: string;
  text: string;
  type: string;
};

export type PropertyToken = {
  address: string;
  created: string;
  currentValuation: number;
  numberOfDecimals: number;
  symbol: string;
  totalSupply: number;
};

export const PROPERTY_STATUS = {
  TRADING: 'TRADING',
  OFFERING: 'OFFERING',
  SOON: 'SOON',
  LISTED: 'LISTED',
  UNLISTED: 'UNLISTED',
} as const;

export type PropertyStatusKeys = (typeof PROPERTY_STATUS)[keyof typeof PROPERTY_STATUS];

/**
 * The shared base between {@link SimpleProperty} and {@link Property}
 */
type BaseProperty = {
  /**
   * The file path to the image
   */
  featuredImage: string | null;
  propertyName: string;
  propertyStatistic: PropertyStatistic;
  token: PropertyToken;
};

/**
 * A simple property type that is used for the views like marketplace, featured properties and offerings overview
 */
export type SimpleProperty = BaseProperty & {
  idLicencedIssuer: number;
  idProperty: number;
  propertyListingType: string;
  propertyStatus: PropertyStatusKeys;
  tokenHolders: number;

  startPresale: null | number;
  endPresale: null | number;

  startSale: null | number;
  endSale: null | number;

  maxInvestment: null | number;
  minInvestment: null | number;

  price: null | number;
};

export type Property = BaseProperty & {
  address: string;
  highlights: Array<PropertyHighlight>;
  images: Array<string>;
  latitude: number;
  longitude: number;
  offeringPrice: number;
  propertyStatus: PropertyStatusKeys | null;
};

export type PropertyRaw = Property & {
  highlights: {
    [key: string]: string;
  };
};

export type TChartData = {
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    hoverBackgroundColor: string;
    maxBarThickness: number;
  }[];
  labels: string[];
};

export type PropertiesState = {
  documents: Array<PropertyDocument>;
  statistics: PropertyStatistics;
  featuredProperties: Array<SimpleProperty>;
  properties: Array<SimpleProperty>;
  onChainProperties: OnChainProperties;
  onChainOfferings: OnChainOfferings;
  isRequestingOnChainProperties: boolean;
  isRequestingOnChainOfferings: boolean;
  onChainPropertiesRequestError: null | string;
  onChainOfferingsRequestError: null | string;
  offeringProperties: Array<SimpleProperty>;
  property: Property;
  issuer: PropertyIssuer;
  errorMessageIssuer: null | string;
  isRequestingIssuer: boolean;
  isRequestingFeaturedProperties: boolean;
  errorMessageFeaturedProperties: null | string;
  isRequestingOfferingProperties: boolean;
  errorMessageOfferingProperties: null | string;
  isRequestingProperties: boolean;
  errorMessageProperties: null | string;
  isRequestingProperty: boolean;
  errorMessageProperty: null | string;
  isRequestingDocuments: boolean;
  errorMessageDocuments: null | string;
  isRequestingStatistics: boolean;
  errorMessageStatistics: null | string;
  isRequestingOnChainProperty: boolean;
  errorMessageRequestOnChainProperty: null | string;
  isRequestingOnChainOffering: boolean;
  errorMessageRequestOnChainOffering: null | string;
};
