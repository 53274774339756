import type { CurrencyTypes } from './currency';

export const configFetchErrorTypes = {
  invalidData: 'invalidData',
  serverError: 'serverError',
} as const;

export type CompanyConfigurationRequest = {
  brandColor: string;
  brandImage: string;
  faviconImage: string;
  title: string;
  terms: string;
  policy: string;
  defaultLanguage: string; // langCode like 'en', 'sl'...
  defaultBankType: string;
  defaultCurrency: string;
  brandAccentColor: string;
};

export type CompanyCPBytesGraphRequest = {
  certifiedPartners: Array<{
    id: string;
  }>;
};

export type CompanyDefaultRouteRequest = {
  handle: string;
};

export type CompanyDetailsRequest = {
  languages: Array<{
    language: string;
    code: string;
  }>;
  defaultCurrency: string;
};

export type CompanyTermsAndPolicyRequest = {
  terms: string;
  policy: string;
};

export type CompanyState = {
  defaultCompanyRoute: string;
  company: string;
  cpBytes: string;
  policy: string;
  terms: string;
  languages: Languages;
  offeringCurrency: CurrencyTypes;
  configuration: {
    title: string;
    images: { brandImage: string; faviconImage: string };
    theme: { identifier: string; brandColor: string } | null;
    defaultLanguage: string;
  };
  companyConfigurationFetchError: boolean;
  companyConfigurationFetchErrorType: string;
  defaultCompanyRouteFetchError: boolean;
  defaultCompanyRouteNotFound: boolean;
  defaultCompanyRouteRequestProcessed: boolean;
  isFetchingDefaultCompanyRoute: boolean;
  isUpdatingCompany: boolean;
  updateCompanyErrorMessage: string | null;
  isRequestingOnChainData: boolean;
  onChainRequestErrorMessage: string | null;
  isRequestingLanguages: boolean;
  languagesRequestError: string | null;
  isRequestingTermsAndPolicy: boolean;
  errorRequestingTermsAndPolicy: string | null;
  isRequestingCurrency: boolean;
  errorRequestingCurrency: string | null;
  isRequestingCompanyDetails: boolean;
  errorRequestingCompanyDetails: string | null;
};

export type Languages = Array<{
  countryCode: string;
  language: string;
}>;
