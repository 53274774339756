import { createSlice } from '@reduxjs/toolkit';
import { backendAPI, createNotification, createNotificationByType, i18nInstance } from 'utils';
import { RESET_STATE } from './sharedActions';
import type { KycRequest, KycUrlRequest, UserKycState } from 'types';
import {
  KYC_STATUS,
  NOTIFICATION_TYPES,
  createAppAsyncThunk,
  genericErrorReasons,
  ApiStatusCode,
  UserKycErrorIds,
} from 'types';

const initialState: UserKycState = {
  isInitialState: true,
  isAccredited: false,
  isRequestingKYCStatus: false,
  hasFetchedInitialKYCStatus: false,
  kycStatus: KYC_STATUS.NEW,
  step: 0,
  remainingAttempts: 0,
  errorMessageKycStatus: null,
  kycUrl: null,
  isRequestingKycUrl: false,
  kycUrlErrorMessage: null,
};

const userKycSlice = createSlice({
  name: 'userKyc',
  initialState,
  reducers: {
    incrementKycStep(state) {
      state.step += 1;
    },
    decrementKycStep(state) {
      state.step -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchUserKyc.pending, (state) => {
      state.isRequestingKYCStatus = true;
    });
    builder.addCase(fetchUserKyc.fulfilled, (state, { payload }) => {
      state.errorMessageKycStatus = null;
      state.isAccredited = payload.accredited;
      state.kycStatus = payload.kycStatusEnum;
      state.remainingAttempts = payload.remainingAttempts;
      state.isRequestingKYCStatus = false;
      state.hasFetchedInitialKYCStatus = true;
    });
    builder.addCase(fetchUserKyc.rejected, (state, { payload }) => {
      // Ignore the rejection in case the thunk failed due to the initial state already being established
      if (payload === UserKycErrorIds.initialStateAlreadyEstablished) return;

      state.isRequestingKYCStatus = false;
      state.hasFetchedInitialKYCStatus = true;
      state.errorMessageKycStatus = payload as string;
    });
    builder.addCase(fetchUserKycUrl.pending, (state) => {
      state.isRequestingKycUrl = true;
    });
    builder.addCase(fetchUserKycUrl.fulfilled, (state, { payload }) => {
      state.kycUrl = payload.reason;
      state.kycUrlErrorMessage = null;
      state.isRequestingKycUrl = false;
    });
    builder.addCase(fetchUserKycUrl.rejected, (state, { payload }) => {
      state.isRequestingKycUrl = false;
      state.kycUrlErrorMessage = payload as string;
    });
  },
});

// possible kyc status fetch failure reasons:

// kyc_done
// kyc_couldnt_start
// kyc_too_many_failed

/**
 * Receive the kyc status for the current user
 *
 */
export const fetchUserKyc = createAppAsyncThunk(
  'userKyc/kyc',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { userKyc } = getState();

    if (!userKyc.isInitialState)
      return rejectWithValue(UserKycErrorIds.initialStateAlreadyEstablished);

    return backendAPI
      .get<KycRequest>('/account/kyc')
      .then(({ data, status }) => {
        const { reason } = data;
        if (status !== ApiStatusCode.SUCCESS) {
          throw new Error(`unsuccessful-kyc-info-request: ${reason}`);
        }
        return data;
      })
      .catch((error) => {
        console.error(error);
        const networkError = i18nInstance.t('network_error');
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        return rejectWithValue(networkError);
      });
  }
);

// possible kyc url getting failure reasons:

// kyc_generic_error
// kyc_too_many_failed
// kyc_couldnt_process
// kyc_selfie_missmatch
// kyc_failed
// kyc_too_young

/**
 * Receive the kyc status for the current user
 *
 */
export const fetchUserKycUrl = createAppAsyncThunk(
  'userKyc/kycUrl',
  async (_, { dispatch, rejectWithValue }) => {
    return backendAPI
      .post<KycUrlRequest>('/user/kyc')
      .then(({ data, status }) => {
        const { reason } = data;
        if (status !== ApiStatusCode.SUCCESS) {
          throw new Error(`unsuccessful-kyc-url-request: ${reason}`);
        }
        return data;
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(createNotification(errorMessage, 'error'));
        return rejectWithValue(errorMessage);
      });
  }
);

export const { incrementKycStep, decrementKycStep } = userKycSlice.actions;
export default userKycSlice.reducer;
