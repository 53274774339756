import { formatDaiToFiatString } from 'utils/currency';
import { getOrderState, isOrderValid } from 'utils/web3';
import { DECIMALS_18_BI } from 'utils/constants';
import type {
  TooltipData,
  HistoricTrade,
  ModifiedOrderWrapper,
  ModifiedOrders,
  OrderWrapper,
} from 'types';
import { parseUnits } from 'ethers';
import type { ChartOptions } from 'chart.js';
import { calculateCurrentValuation, formatBigInt } from './format';

const MAX_PROPERTY_TOKENS_SUPPLY = 100000;

/**
 * For the purposes of the Open Sell Orders table.
 *
 * Updates taker and maker amounts to reflect their real-time state.
 *
 * The important part is that this function should be passed only
 * already validated orders.
 *
 * @param orders - The orders received from the backend
 * @param chainId - The chainId of the currently used blockchain
 * @returns Return an array of modified orders
 *
 * NOTE - this function could be used to modularize the advanced version
 * of this function called 'modifyOrders'
 */
export async function updateOrderAmounts(
  validOrders: Array<OrderWrapper>,
  chainId: number
): Promise<ModifiedOrders> {
  const newOrders: ModifiedOrders = [];

  for (let i = 0; i < validOrders.length; i += 1) {
    const order = { ...validOrders[i] };
    if (!order.ratio) {
      console.error('Invalid order ratio');
      continue;
    }

    const orderState = await getOrderState(order, chainId);
    const { remainingFillableAmount } = orderState;

    const orderWrapper: ModifiedOrderWrapper = Object.assign(order, {
      takerAssetAmount: remainingFillableAmount,
      makerAssetAmount:
        (remainingFillableAmount * DECIMALS_18_BI) / parseUnits(order.ratio.toString(), 18),
      salt: order.limitOrder.salt,
    });

    newOrders.push({
      order: orderWrapper,
      selected: false,
    });
  }
  return newOrders;
}

/**
 * Modifies orders to add additional data like valuation, token valuation and up-to-date
 * taker and maker amounts.
 *
 * @param orders - The orders received from the backend
 * @param lastTrade - The last trade that happened for the selected property
 * @param averageYearlyRevenue - The average yearly revenue in DAI per BSPT token for a property
 * @param propertyValuation - The property valuation of the selected property
 * @param staticYield - The static, predicted, yield used until some revenue gets distributed
 * @param totalSupplyBigInt - The total supply of tokens out there in WEI (proportional to the percentage of property tokenized)
 * @param chainId - The chainId of the currently used blockchain
 * @returns Return an array of modified orders
 *
 */
export async function modifyOrders(
  orders: Array<OrderWrapper>,
  lastTrade: HistoricTrade,
  averageYearlyRevenue: number,
  propertyValuation: number,
  staticYield: number,
  totalSupplyBigInt: bigint,
  chainId: number
): Promise<ModifiedOrders> {
  const newOrders: ModifiedOrders = [];

  let previousTrade: {
    valuation: number;
    tokenValuation: number;
  } = {
    valuation: lastTrade.valuation,
    tokenValuation: lastTrade.tokenValuation,
  };

  for (let i = 0; i < orders.length; i += 1) {
    const order = { ...orders[i] };
    if (await isOrderValid(order, chainId)) {
      if (!order.ratio) throw new Error('Invalid order ratio');

      const orderState = await getOrderState(order, chainId);
      const { remainingFillableAmount } = orderState;

      const orderWrapper: ModifiedOrderWrapper = Object.assign(order, {
        takerAssetAmount: remainingFillableAmount,
        makerAssetAmount:
          (remainingFillableAmount * DECIMALS_18_BI) / parseUnits(order.ratio.toString(), 18),
        salt: order.limitOrder.salt,
      });

      const currentOrderBsptAmount = formatBigInt(orderWrapper.makerAssetAmount);
      const totalSupply = formatBigInt(totalSupplyBigInt);

      const currentOrderTokenValuation =
        ((MAX_PROPERTY_TOKENS_SUPPLY - currentOrderBsptAmount) * previousTrade.tokenValuation) /
          MAX_PROPERTY_TOKENS_SUPPLY +
        currentOrderBsptAmount * (orderWrapper.ratio as number);

      const currentValuation = calculateCurrentValuation(
        currentOrderTokenValuation,
        propertyValuation,
        totalSupply
      );

      // NOTE:
      // The 'averageYearlyRevenue' actually represents the revenue in DAI per single property token
      // in the course of a year.
      // In case 'averageYearlyRevenue' isn't calculated because no revenues were distributed yet,
      // we use the static yield in combination with property valuation and total supply to
      // get a simulated 'averageYearlyRevenue' that we in the end divide by the ratio to get
      // a simulated yield

      const calculatedYield =
        averageYearlyRevenue > 0
          ? averageYearlyRevenue / (orderWrapper.ratio as number)
          : (propertyValuation * (staticYield / 100)) /
            MAX_PROPERTY_TOKENS_SUPPLY /
            (orderWrapper.ratio as number);

      newOrders.push({
        order: orderWrapper,
        selected: false,
        valuation: currentValuation,
        yield: calculatedYield * 100,
      });

      previousTrade = {
        valuation: currentValuation,
        tokenValuation: currentOrderTokenValuation,
      };
    }
  }
  return newOrders;
}

// filters chart data by timespan ( 1w | 1m | 1y | all )
export const filterOptions = {
  oneW: 604800,
  oneM: 2.628e6,
  oneY: 3.156e7,
  all: 'all',
};

// Value of the filter argument must be a string that equals one of the
// properties in the filterOptions object above
export function filterChartData(data, filter) {
  // The array already comes pre-sorted from the back-end, but in case you will
  // want to sort the array in the front-end, this is the code
  /*
  const sortedArray = [...data].sort(
    (a, b) => Date.parse(a.transactionDate) - Date.parse(b.transactionDate)
  );
  */

  const sortedArray = [...data].reverse();

  // Second operand is for making sure that, provided our filter argument,
  // there is such a filter option in our filterOptions object
  if (filter !== filterOptions.all && filterOptions[filter]) {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    const filteredArray = sortedArray.filter((tx) => {
      const parsedDate = Date.parse(tx.transactionDate);
      const txTimestamp = Math.floor(parsedDate / 1000);

      // Current timestamp - selected filter timestamp
      const filteringStartTimestamp = currentTimestamp - filterOptions[filter];

      // If tx timestamp is more than that, return true
      return txTimestamp >= filteringStartTimestamp;
    });

    return filteredArray;
  }

  return sortedArray;
}

export function checkAvailableFilters(graphData) {
  const sortedData = [...graphData].reverse();
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const availableFilters = ['all'];

  Object.keys(filterOptions).forEach((option) => {
    if (filterOptions[option] !== 'all') {
      const filteredArray = sortedData.filter((tx) => {
        const parsedDate = Date.parse(tx.transactionDate);
        const txTimestamp = Math.floor(parsedDate / 1000);

        // Current timestamp - selected filter timestamp
        const filteringStartTimestamp = currentTimestamp - filterOptions[option];

        // If tx timestamp is more than that, return true
        return txTimestamp >= filteringStartTimestamp;
      });

      if (filteredArray.length > 1) {
        availableFilters.push(option);
      }
    }
  });

  return availableFilters;
}

export function createDataLine(label, value, currency?: string) {
  const dataSpanLabel = document.createElement('span');
  dataSpanLabel.innerText = label;
  dataSpanLabel.innerText += ':';
  dataSpanLabel.style.fontSize = '14px';
  dataSpanLabel.style.textAlign = 'left';
  dataSpanLabel.style.color = 'inherit';

  const currencyText = currency ? ` ${currency}` : '';

  const dataSpan = document.createElement('span');
  dataSpan.innerText = `${value}${currencyText}`;
  dataSpan.style.marginLeft = '50px';
  dataSpan.style.fontSize = '14px';
  dataSpan.style.textAlign = 'right';
  dataSpan.style.color = 'inherit';

  const dataLine = document.createElement('div');
  dataLine.style.display = 'flex';
  dataLine.style.justifyContent = 'space-between';
  dataLine.style.alignItems = 'center';
  dataLine.appendChild(dataSpanLabel);
  dataLine.appendChild(dataSpan);

  return dataLine;
}

export function generateLineGraphOptions(
  primaryColor,
  contrastText,
  graphDataPoints,
  tooltipData: TooltipData[]
): ChartOptions<'line'> {
  return {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'x',
    },
    layout: {
      padding: {
        top: 5,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external(context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.backgroundColor = primaryColor;
            tooltipEl.style.borderRadius = '0.6rem';
            tooltipEl.style.padding = '10px';
            tooltipEl.style.transition = 'all .2s';
            tooltipEl.style.color = contrastText;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // You can probably set caret position in a way where the tooltip
          // would be shown to the left side of the mouse in case the tooltip would go out of the right side
          // of the canvas and get squeezed by lack of space on the right

          // Set caret Position
          tooltipEl.classList.remove('top', 'bottom', 'center', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          tooltipEl.classList.remove('left', 'center', 'right');
          if (tooltipModel.xAlign) {
            tooltipEl.classList.add(tooltipModel.xAlign);
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            while (tooltipEl.lastChild) {
              tooltipEl.lastChild.remove();
            }

            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            titleLines.forEach((title) => {
              const tooltipHeader = document.createElement('p');
              tooltipHeader.innerText = title;
              tooltipHeader.style.marginTop = '0';
              tooltipHeader.style.marginBottom = '10px';
              tooltipHeader.style.fontSize = '14px';
              tooltipHeader.style.fontWeight = '500';
              tooltipHeader.style.color = 'inherit';
              tooltipHeader.style.display = 'block';
              tooltipEl?.appendChild(tooltipHeader);
            });

            bodyLines.forEach(() => {
              const {
                tooltip: { dataPoints },
              } = context;
              const { dataIndex } = dataPoints[0];
              const data = tooltipData[dataIndex];

              const totalSizeLine = createDataLine(data.totalSizeLabel, data.totalSize, 'BSPT');
              const totalVolumeLine = createDataLine(
                data.totalVolumeLabel,
                data.totalVolume,
                'DAI'
              );
              const effectivePriceLine = createDataLine(
                data.effectivePriceLabel,
                data.effectivePrice,
                'DAI'
              );
              const DAIValuation = data.valuation;
              let fiatValuation = formatDaiToFiatString(DAIValuation, 2);

              // The following lump of code adds a space between the fiat valuation amount and currency
              fiatValuation = `${fiatValuation.slice(
                0,
                fiatValuation.length - 1
              )} ${fiatValuation.slice(fiatValuation.length - 1)}`;

              const valuationDataLine = createDataLine(data.valuationLabel, fiatValuation);

              tooltipEl?.appendChild(totalSizeLine);
              tooltipEl?.appendChild(totalVolumeLine);
              tooltipEl?.appendChild(effectivePriceLine);
              tooltipEl?.appendChild(valuationDataLine);
            });
          }

          const position = context.chart.canvas.getBoundingClientRect();

          if (tooltipModel.xAlign === 'left' || tooltipModel.xAlign === 'center') {
            const rightPositioned = position.left + window.pageXOffset + tooltipModel.caretX;

            tooltipEl.style.left = `${rightPositioned}px`;
          }

          if (tooltipModel.xAlign === 'right') {
            const elementWidth = tooltipEl.offsetWidth;
            const leftPositioned = position.left + tooltipModel.caretX - elementWidth;

            tooltipEl.style.left = `${leftPositioned}px`;
          }

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.top = `${position.top + window.scrollY + tooltipModel.caretY}px`;
          tooltipEl.style.pointerEvents = 'none';
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        suggestedMin: Array.isArray(graphDataPoints)
          ? Math.min(...graphDataPoints) * 0.9965
          : undefined,
        suggestedMax: Array.isArray(graphDataPoints)
          ? Math.max(...graphDataPoints) * 1.002
          : undefined,
      },
    },
  };
}

// This function takes in the amount of property tokens that the user wants to buy
// and returns back the amount of DAI that this amount of prop tokens is worth
export const calculateAmountToInvestFromTokensToReceive = (
  inputValueBI: bigint,
  orders: ModifiedOrders
) => {
  let lastOrderIndex = -1;
  let amountToInvestBI = 0n;
  let tokensToReceiveBI = inputValueBI;

  if (tokensToReceiveBI > 0n) {
    for (let i = 0; i < orders.length; i += 1) {
      amountToInvestBI += orders[i].order.takerAssetAmount;
      tokensToReceiveBI -= orders[i].order.makerAssetAmount;
      if (tokensToReceiveBI <= 0n) {
        lastOrderIndex = i;
        break;
      }
    }
  }

  if (lastOrderIndex > -1) {
    const ratio = orders[lastOrderIndex].order.ratio as number;
    const orderRatioInWEI = -parseUnits(ratio.toString(), 18);
    amountToInvestBI = amountToInvestBI - (tokensToReceiveBI * orderRatioInWEI) / DECIMALS_18_BI;
  }

  return {
    amountToInvestBI,
    lastOrderIndex,
  };
};

// This function takes in the amount of DAI that the user wants to pay for the Tokens
// and returns back the amount of BSPT Tokens that this amount of DAI can buy
export const calculateTokensToReceiveFromAmountToInvest = (
  inputValueBI: bigint,
  orders: ModifiedOrders
) => {
  let lastOrderIndex = -1;
  let tokensToReceiveBI = 0n;
  let amountToInvestBI = inputValueBI;
  if (amountToInvestBI > 0n) {
    for (let i = 0; i < orders.length; i += 1) {
      tokensToReceiveBI += orders[i].order.makerAssetAmount;
      amountToInvestBI -= orders[i].order.takerAssetAmount;
      if (amountToInvestBI <= 0n) {
        lastOrderIndex = i;
        break;
      }
    }
  }

  if (lastOrderIndex > -1) {
    const ratio = orders[lastOrderIndex].order.ratio as number;
    const orderRatioInWEI = -parseUnits(ratio.toString(), 18);
    tokensToReceiveBI = tokensToReceiveBI - (amountToInvestBI * DECIMALS_18_BI) / orderRatioInWEI;
  }

  return {
    tokensToReceiveBI,
    lastOrderIndex,
  };
};
