import type { MouseEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Grid, TableBody, TablePagination, Typography, useTheme } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'types';
import type { PropertyDocument, TOrderSort } from 'types';
import { DocumentSVG, DownloadSVG, DownloadDocSVG } from 'components/icons';

import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import StatusChip from 'components/chips/StatusChip';
import EnhancedTableHead from './EnhancedTableHead';
import Loader from 'components/custom/Loader';
import { StyledTable } from 'components/marketplace/PropertyTrades';
import { TableWrapper, TableBodyRow, TableCell } from 'components/marketplace/TableStyledUtil';
import {
  propertiesDocumentsLoadingSelector,
  propertiesDocumentsSelector,
} from 'store/selectors/properties';
import { fetchPropertyDocuments } from 'store/properties';
import { stableSort, getSorting } from 'utils/portfolioSort';
import type { PortfolioPropertySortMethods } from 'utils/portfolioSort/getSorting';

const PAGINATION_MIN_LIMIT = 5;
const PAGINATION_MAX_LIMIT = 7;
const TAB_INDEX = -1;

const DocName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-bottom: 0.5rem;
  }
`;

const ChipGrid = styled(Grid)`
  & > * {
    margin: 0.3rem 0.3rem 0.3rem 0;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const ResponsiveTableCell = styled(TableCell)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const DownloadDocAnchor = styled.a`
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const DocIconWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const DocumentsTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { propertyAddress: address } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_MAX_LIMIT);
  const [order, setOrder] = useState<TOrderSort>('desc');
  const [orderBy, setOrderBy] = useState<PortfolioPropertySortMethods>('date');

  const documents = useAppSelector(propertiesDocumentsSelector);
  const loading = useAppSelector(propertiesDocumentsLoadingSelector);

  useEffect(() => {
    if (address) dispatch(fetchPropertyDocuments(address));
  }, [address, dispatch]);

  const sortedAndPaginatedDocs = useMemo(() => {
    if (documents.length) {
      return stableSort(documents, getSorting(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
  }, [documents, orderBy, order, page, rowsPerPage]);

  const handleRequestSort = (method: PortfolioPropertySortMethods) => {
    let newOrder: TOrderSort = 'desc';
    if (orderBy === method && order === 'desc') newOrder = 'asc';
    setOrder(newOrder);
    setOrderBy(method);
  };

  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, page: number) => setPage(page);

  const handleChangeRowsPerPage = ({ target }) => setRowsPerPage(target.value);

  if (loading) {
    return (
      <Grid container item direction="column" md={8} lg={9}>
        <Loader size={'3rem'} />
      </Grid>
    );
  }

  return (
    <TableWrapper item container justifyContent="space-between">
      <StyledTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={documents.length}
        />
        <TableBody>
          {sortedAndPaginatedDocs && sortedAndPaginatedDocs.length ? (
            sortedAndPaginatedDocs.map((document: PropertyDocument, index: number) => {
              const { file, name, created, documentTypeEnum } = document;
              return (
                <TableBodyRow hover tabIndex={TAB_INDEX} key={`documents${index}`}>
                  <TableCell align="center">
                    <DocIconWrapper>
                      <DocumentSVG
                        fillColor={theme.palette.primary.main}
                        height="2rem"
                        width="2rem"
                      />
                    </DocIconWrapper>
                    <DownloadDocAnchor href={file} target="_blank" rel="noopener noreferrer">
                      <DownloadDocSVG
                        fillColor={theme.palette.primary.main}
                        height="2rem"
                        width="2rem"
                      />
                    </DownloadDocAnchor>
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <DocName variant="h4">{name}</DocName>
                      <ChipGrid>
                        <StatusChip label={documentTypeEnum} style={{ marginRight: '0.5rem' }} />
                        <StatusChip label={created} />
                      </ChipGrid>
                    </Grid>
                  </TableCell>
                  <ResponsiveTableCell align="right">
                    <Grid container direction="column">
                      <Typography variant="h4">
                        <StatusChip label={documentTypeEnum} m={1} />
                      </Typography>
                    </Grid>
                  </ResponsiveTableCell>
                  <ResponsiveTableCell align="right">
                    <Grid container direction="column">
                      <Typography style={{ minWidth: '6rem' }} variant="h4">
                        {created}
                      </Typography>
                    </Grid>
                  </ResponsiveTableCell>
                  <ResponsiveTableCell align="right">
                    <Grid container justifyContent="center">
                      <a href={file} target="_blank" rel="noopener noreferrer">
                        <DownloadSVG fillColor="#000" height="1.5rem" width="1.5rem" />
                      </a>
                    </Grid>
                  </ResponsiveTableCell>
                </TableBodyRow>
              );
            })
          ) : (
            <TableBodyRow tabIndex={TAB_INDEX} key="noPropertyDocumentsFound">
              <TableCell colSpan={PAGINATION_MIN_LIMIT} align="center">
                <ZeroItemsLayout
                  desc={t('marketplace_property_documents_no_entries')}
                  hint={t('marketplace_property_documents_no_entries_hint')}
                />
              </TableCell>
            </TableBodyRow>
          )}
        </TableBody>
      </StyledTable>
      {documents.length >= PAGINATION_MIN_LIMIT ? (
        <TablePagination
          rowsPerPageOptions={[PAGINATION_MIN_LIMIT, PAGINATION_MAX_LIMIT]}
          component="div"
          count={documents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </TableWrapper>
  );
};

export default DocumentsTable;
