import type { MouseEvent, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel as MuiTableSortLabel,
  Typography,
} from '@material-ui/core';
import type { TOrderSort } from 'types/account';
import type { PortfolioPropertySortMethods } from 'utils/portfolioSort/getSorting';

const TableSortLabel = styled(MuiTableSortLabel)`
  svg {
    color: ${(props) => props.theme.palette.primary.main} !important;
  }
`;

const ResponsiveTableCell = styled(TableCell)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

type TProps = {
  onRequestSort: (method: PortfolioPropertySortMethods, event: MouseEvent<HTMLSpanElement>) => void;
  order: TOrderSort | undefined;
  orderBy: string;
  rowCount?: number;
};

const EnhancedTableHead: FC<TProps> = ({ onRequestSort, order, orderBy }) => {
  const { t } = useTranslation();

  const createSortHandler =
    (method: PortfolioPropertySortMethods) => (event: MouseEvent<HTMLSpanElement>) => {
      onRequestSort(method, event);
    };

  const rows: Array<{ id: PortfolioPropertySortMethods; label: string }> = [
    {
      id: 'type',
      label: t('type').toUpperCase(),
    },
    {
      id: 'date',
      label: t('date').toUpperCase(),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <ResponsiveTableCell />
        <ResponsiveTableCell />
        {rows.map((row) => (
          <TableCell key={row.id} align="right" sortDirection={orderBy === row.id ? order : false}>
            <TableSortLabel
              active={orderBy === row.id}
              direction={orderBy === row.id ? order : 'asc'}
              onClick={createSortHandler(row.id)}
            >
              <Typography variant="subtitle2" color="primary">
                {row.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
        <ResponsiveTableCell key="actions" align="center">
          <Typography variant="subtitle2" color="primary">
            {t('marketplace_property_documents_actions')}
          </Typography>
        </ResponsiveTableCell>
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
