import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const DownloadSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 60 52"
    >
      <path
        d="M45.123,43.992 C45.081,43.998 45.040,44.000 44.998,44.000 C44.501,44.000 44.070,43.629 44.008,43.123 C43.939,42.575 44.329,42.076 44.877,42.008 C52.236,41.096 58.000,34.823 58.000,27.727 C58.000,19.856 51.510,13.453 43.533,13.453 C43.241,13.453 42.947,13.471 42.656,13.502 C42.200,13.553 41.762,13.279 41.606,12.842 C39.304,6.357 33.085,2.000 26.133,2.000 C17.091,2.000 9.734,9.258 9.734,18.180 C9.734,18.935 9.800,19.700 9.934,20.520 C10.014,21.007 9.727,21.479 9.257,21.632 C4.917,23.047 2.000,27.030 2.000,31.543 C2.000,37.309 6.755,42.000 12.600,42.000 L15.000,42.000 C15.552,42.000 16.000,42.448 16.000,43.000 C16.000,43.552 15.552,44.000 15.000,44.000 L12.600,44.000 C5.652,44.000 -0.000,38.412 -0.000,31.543 C-0.000,26.441 3.119,21.911 7.844,20.019 C7.770,19.391 7.734,18.784 7.734,18.180 C7.734,8.155 15.988,0.000 26.133,0.000 C33.672,0.000 40.443,4.574 43.220,11.459 C43.325,11.455 43.429,11.453 43.533,11.453 C52.613,11.453 60.000,18.753 60.000,27.727 C60.000,35.814 53.465,42.958 45.123,43.992 ZM22.707,42.293 L29.000,48.586 L29.000,29.000 C29.000,28.448 29.448,28.000 30.000,28.000 C30.552,28.000 31.000,28.448 31.000,29.000 L31.000,48.586 L37.293,42.293 C37.684,41.902 38.316,41.902 38.707,42.293 C39.098,42.684 39.098,43.316 38.707,43.707 L30.707,51.707 C30.615,51.799 30.504,51.872 30.382,51.923 C30.260,51.973 30.130,52.000 30.000,52.000 C29.870,52.000 29.740,51.973 29.618,51.923 C29.495,51.872 29.385,51.799 29.293,51.707 L21.293,43.707 C20.902,43.316 20.902,42.684 21.293,42.293 C21.684,41.902 22.316,41.902 22.707,42.293 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DownloadSVG;
