import type { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import type { TBaseProps as Props } from 'types/custom';

const InfoLabel = styled(Typography)`
  font-size: 0.8rem;
  font-family: 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 1.2;
`;

const InfoLabelTypography: FC<Props> = ({ className, style, children, align, color }) => {
  return (
    <InfoLabel className={className} style={style} align={align} color={color}>
      {children}
    </InfoLabel>
  );
};

export default InfoLabelTypography;
