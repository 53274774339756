import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const HourGlassSVG: FC<TBaseIconProps> = ({
  style,
  fillColor,
  height = '100%',
  width = '100%',
}) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 32 48"
      width={width}
      height={height}
    >
      <path
        d="M31.000,2.000 L28.000,2.000 L28.000,13.572 C28.000,13.689 27.970,13.796 27.934,13.900 C27.467,19.359 22.894,22.529 19.483,23.997 C22.903,25.459 27.469,28.639 27.934,34.103 C27.970,34.206 28.000,34.314 28.000,34.430 L28.000,46.000 L31.000,46.000 C31.552,46.000 32.000,46.447 32.000,47.000 C32.000,47.553 31.552,48.000 31.000,48.000 L1.000,48.000 C0.448,48.000 0.000,47.553 0.000,47.000 C0.000,46.447 0.448,46.000 1.000,46.000 L4.000,46.000 L4.000,34.430 C4.000,34.314 4.030,34.206 4.066,34.103 C4.531,28.639 9.097,25.459 12.517,23.997 C9.106,22.529 4.533,19.359 4.066,13.900 C4.030,13.796 4.000,13.689 4.000,13.572 L4.000,2.000 L1.000,2.000 C0.448,2.000 0.000,1.553 0.000,1.000 C0.000,0.447 0.448,0.000 1.000,0.000 L31.000,0.000 C31.552,0.000 32.000,0.447 32.000,1.000 C32.000,1.553 31.552,2.000 31.000,2.000 ZM6.000,35.000 L6.000,46.000 L26.000,46.000 L26.000,35.000 C26.000,27.572 16.969,25.033 15.995,25.000 C15.031,25.033 6.000,27.572 6.000,35.000 ZM26.000,2.000 L6.000,2.000 L6.000,13.000 C6.000,20.428 15.031,22.967 16.005,23.000 C16.969,22.967 26.000,20.428 26.000,13.000 L26.000,2.000 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default HourGlassSVG;
