import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const i18nInstance = i18n;

export function initI18n() {
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // Use this for cache busting reasons.
      backend: { queryStringParams: { v: process.env.REACT_APP_VERSION as string } },
    });
}
