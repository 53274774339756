import { HourGlassSVG, CheckSVG, BanSVG } from 'components/icons';
import { OfferingTransactionStatusTypes } from 'types/store';

// const RegularStatusText = styled.p`
//   padding: 0.4rem;
//   text-align: center;
//   white-space: nowrap;
//   border: 1px solid ${(props) => props.theme.palette.primary.main};
//   border-radius: 3px;
//   color: ${(props) => props.theme.palette.primary.main};
// `;
//
// const Canceled = styled.p`
//   padding: 0.3rem;
//   color: red;
//   border: 1px solid red;
//   border-radius: 3px;
//   text-align: center;
// `;
//
// const ConfirmPaymentBtn = styled(Button).attrs({
//   variant: 'contained',
// })`
//   && {
//     padding: 1rem;
//     background-color: ${(props) => props.theme.palette.primary.main};
//     color: ${(props) => props.theme.sidebar.color};
//     &.MuiButton-label {
//       font-weight: bold;
//     }
//     &.Mui-disabled {
//       background-color: #acacac;
//       opacity: 0.6;
//     }
//     &.MuiButton-contained:hover {
//       background-color: ${(props) => props.theme.palette.background.default};
//     }
//   }
// `;

export const proFormaStatusMap = {
  [OfferingTransactionStatusTypes.PENDING]: {
    icon: HourGlassSVG,
    color: '166, 162, 151',
    tooltip: 'confirm_payment_tooltip',
    btnTitle: 'confirm_payment',
    title: 'pending_status',
    statusDescription: 'pending_status_pro_forma',
  },
  [OfferingTransactionStatusTypes.PAYED]: {
    icon: CheckSVG,
    title: 'payment_confirmed',
    color: '2, 136, 209',
    tooltip: 'awaiting_approval_tooltip',
    statusDescription: 'awaiting_approval_description',
  },
  [OfferingTransactionStatusTypes.PAYMENT_CONFIRMED]: {
    icon: CheckSVG,
    title: 'payment_received',
    color: '56, 142, 60',
    tooltip: 'payment_confirmed_tooltip',
    statusDescription: 'payment_confirmed_description',
  },
  [OfferingTransactionStatusTypes.CANCELED]: {
    icon: BanSVG,
    title: 'tx_canceled',
    color: '207, 0, 0',
    tooltip: null,
    statusDescription: 'canceled_status_pro_forma',
  },
  [OfferingTransactionStatusTypes.MINTED]: {
    icon: HourGlassSVG,
    title: 'tx_minted',
    color: '245, 124, 0',
    tooltip: null,
    statusDescription: 'minted_status_pro_forma',
  },
  [OfferingTransactionStatusTypes.CONFIRMED]: {
    icon: HourGlassSVG,
    title: 'confirmed',
    color: '31, 181, 76',
    tooltip: null,
    statusDescription: 'minted_status_pro_forma',
  },
};
