import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { WalletTypeKeys, WalletState } from 'types/store';

const initialState: WalletState = {
  type: null,
  account: null,
  showedDialogAlready: false,
  handledWalletReconnect: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletType(state, { payload }: PayloadAction<WalletTypeKeys>) {
      state.type = payload;
    },
    setAccountAndType(
      state,
      { payload }: PayloadAction<{ account: string | null; type: WalletTypeKeys | null }>
    ) {
      const { account, type } = payload;
      state.account = account;
      state.type = type;
      state.showedDialogAlready = false;
    },
    setShowedDialog(state) {
      state.showedDialogAlready = true;
    },
    setHandledWalletReconnect(state) {
      state.handledWalletReconnect = true;
    },
  },
});

export const { setWalletType, setAccountAndType, setShowedDialog, setHandledWalletReconnect } =
  walletSlice.actions;
export default walletSlice.reducer;
