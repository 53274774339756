import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Grid, Typography } from '@material-ui/core';
import { displayHexString, formatBigInt } from 'utils/format';
import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import { ChartWrapper } from 'components/marketplace/stats/ChartWrapper';
import { useAppSelector } from 'types';
import { onChainPropertiesDataSelector, propertyTokenSelector } from 'store/selectors/properties';

ChartJS.register(ArcElement, Tooltip, Legend);

const PERCENTAGE_CONVERT = 100;
const SIZE_PROPORTION = 8;

const DoughnutInner = styled.div`
  display: inline-block;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20px;
  text-align: center;
  z-index: 0;

  &.top25 {
    top: 25%;
  }
`;

const BoldTypography = styled(Typography)`
  font-weight: 600;
`;

const colors = [
  '#323232',
  '#8c8c8c',
  '#444444',
  '#c2c2c2',
  '#7a7a7a',
  '#d4d4d4',
  '#686868',
  '#b0b0b0',
  '#565656',
  '#9e9e9e',
];

const TokenHolderChart = () => {
  const { t } = useTranslation();

  const [addresses, setAddresses] = useState<string[]>([]);
  const [balances, setBalances] = useState<number[]>([]);
  const [data, setData] = useState<ChartData<'doughnut', number[], string>>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [holderAddress, setHolderAddress] = useState('');
  const [percentage, setPercentage] = useState(0);

  const { address } = useAppSelector(propertyTokenSelector);
  const onChainProperties = useAppSelector(onChainPropertiesDataSelector);
  const onChainProperty = onChainProperties[address] ?? null;

  const options: ChartOptions<'doughnut'> = useMemo(() => {
    return {
      plugins: {
        datalabels: { display: false },
        title: {
          display: true,
          text: '',
          font: { size: 20 },
          position: 'bottom',
          color: '#000',
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      maintainAspectRatio: false,
      cutout: 110,
      onClick: (e, item) => {
        if (item[0]) {
          setSelectedIndex(item[0].index);
        }
      },
      onHover: (_event, elements, chart) => {
        if (elements[0] && elements[0].element) {
          chart.canvas.style.cursor = 'pointer';
        } else {
          chart.canvas.style.cursor = 'default';
        }
      },
    };
  }, []);

  const updateChartInformation = useCallback(() => {
    const totalSupply = onChainProperty ? formatBigInt(onChainProperty.token.totalSupply) : NaN;

    const address = addresses[selectedIndex];
    const balance = balances[selectedIndex];

    const percentage = (balance * PERCENTAGE_CONVERT) / totalSupply;
    setHolderAddress(address);
    setPercentage(percentage);
  }, [selectedIndex, onChainProperty, addresses, balances]);

  const updateDataset = useCallback(() => {
    const tokenHolders = onChainProperty?.holders ?? [];
    const totalHolders = onChainProperty ? onChainProperty.token.totalHolders : NaN;
    const totalSupply = onChainProperty?.token?.totalSupply
      ? formatBigInt(onChainProperty.token.totalSupply)
      : NaN;

    if (tokenHolders.length === 0) return;

    const addresses = tokenHolders.map((propertyWallet) => propertyWallet.id);
    setAddresses(addresses);
    const balances = tokenHolders.map((propertyWallet) => formatBigInt(propertyWallet.amount));
    setBalances(balances);
    const tempTotalBalance = balances.reduce((a, b) => a + b, 0);
    const remainingBalance = totalSupply - tempTotalBalance;
    if (remainingBalance > 0) {
      addresses.push(t('other_accounts'));
      balances.push(remainingBalance);
    }

    const newData = {
      labels: addresses,
      datasets: [
        {
          data: balances,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };
    setData(newData);

    options.plugins?.title
      ? (options.plugins.title.text = `${t(
          'marketplace_property_stats_token_holders'
        )}: ${totalHolders}`)
      : null;
    setSelectedIndex(0);
  }, [onChainProperty, options, t]);

  useEffect(() => {
    updateDataset();
  }, [updateDataset]);

  useEffect(() => {
    updateChartInformation();
  }, [updateChartInformation]);

  return (
    <Grid container item xs justifyContent="center" alignItems="center">
      <ChartWrapper>
        {data ? (
          <>
            <DoughnutInner className={percentage ? '' : 'top25'}>
              <div>
                <BoldTypography variant="h1">
                  {`${percentage.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}%`}
                </BoldTypography>
                {holderAddress ? (
                  <Typography variant="h4">
                    {holderAddress === t('other_accounts')
                      ? holderAddress
                      : displayHexString(holderAddress)}
                  </Typography>
                ) : null}
              </div>
            </DoughnutInner>
            <Doughnut id="tokenHolderChartCanvas" data={data} options={options} />
          </>
        ) : (
          <ZeroItemsLayout
            desc={t('marketplace_property_stats_token_holders_empty')}
            hint={t('marketplace_property_stats_token_holders_empty_hint')}
            size={SIZE_PROPORTION}
          />
        )}
      </ChartWrapper>
    </Grid>
  );
};
export default TokenHolderChart;
