import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs, showDialog } from 'store/dialogs';
import { batchFillOrders, isOrderValid } from 'utils/web3';
import { RESET_STATE } from './sharedActions';
import type {
  HistoricTradeRaw,
  HistoricTrades,
  OrderWrapper,
  OrderWrapperRaw,
  TradeState,
  UserTradeHistoryEntryRaw,
  UserTradeHistoryEntry,
  ModifiedOrders,
} from 'types';
import { LimitOrderBI, DialogTypes, NOTIFICATION_TYPES } from 'types';
import {
  i18nInstance,
  formatBigInt,
  backendAPI,
  createNotificationByType,
  createNotification,
} from 'utils';
import { updateOrderAmounts } from 'utils/marketplaceUtils';

const initialState: TradeState = {
  history: [],
  orders: [],
  allUserOrders: [],
  userHistory: [],
  isPostingFillOrder: false,
  isPostingSellOrder: false,
  isRequestingOrders: false,
  isRequestingHistory: false,
  userTradeHistoryRequestMeta: {
    loaded: false,
    loading: false,
    error: null,
  },
  userActiveOrdersRequestMeta: {
    loaded: false,
    loading: false,
    error: null,
  },
  errorMessageHistory: null,
  errorMessageOrders: null,
  errorMessagePostFillOrder: null,
  errorMessagePostSellOrder: null,
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    historyFetchRequest(state) {
      state.isRequestingHistory = true;
    },
    historyFetchSuccess(state, { payload }: PayloadAction<HistoricTrades>) {
      state.history = payload;
      state.isRequestingHistory = false;
      state.errorMessageHistory = null;
    },
    historyFetchFailure(state, { payload }: PayloadAction<string>) {
      state.isRequestingHistory = false;
      state.errorMessageHistory = payload;
    },
    postFillOrderRequest(state) {
      state.isPostingFillOrder = true;
    },
    postFillOrderSuccess(state) {
      state.isPostingFillOrder = false;
    },
    postFillOrderFailure(state, { payload }: PayloadAction<string>) {
      state.errorMessagePostFillOrder = payload;
      state.isPostingFillOrder = false;
    },
    postSellOrderRequest(state) {
      state.isPostingSellOrder = true;
    },
    postSellOrderSuccess(state) {
      state.isPostingSellOrder = false;
    },
    postSellOrderFailure(state, { payload }: PayloadAction<string>) {
      state.errorMessagePostSellOrder = payload;
      state.isPostingSellOrder = false;
    },
    ordersFetchRequest(state) {
      state.isRequestingOrders = true;
    },
    ordersFetchSuccess(state, { payload }: PayloadAction<Array<OrderWrapper>>) {
      state.isRequestingOrders = false;
      state.orders = payload;
      state.errorMessageOrders = null;
    },
    ordersFetchFailure(state, { payload }: PayloadAction<string>) {
      state.errorMessageOrders = payload;
      state.isRequestingOrders = false;
    },
    userTradeHistoryFetchPending(state) {
      state.userTradeHistoryRequestMeta = {
        ...state.userTradeHistoryRequestMeta,
        loading: true,
        loaded: false,
      };
    },
    userTradeHistoryFetchSuccess(state, { payload }: PayloadAction<Array<UserTradeHistoryEntry>>) {
      state.userHistory = payload;
      state.userTradeHistoryRequestMeta = {
        ...state.userTradeHistoryRequestMeta,
        loading: false,
        loaded: true,
      };
    },
    userTradeHistoryFetchFailure(state, { payload }: PayloadAction<string>) {
      state.userTradeHistoryRequestMeta = {
        ...state.userTradeHistoryRequestMeta,
        loading: false,
        loaded: false,
        error: payload,
      };
    },
    userActiveOrdersFetchPending(state) {
      state.userActiveOrdersRequestMeta = {
        ...state.userActiveOrdersRequestMeta,
        loading: true,
      };
    },
    userActiveOrdersFetchSuccess(state, { payload }: PayloadAction<ModifiedOrders>) {
      state.allUserOrders = payload;
      state.userActiveOrdersRequestMeta = {
        ...state.userActiveOrdersRequestMeta,
        loading: false,
        loaded: true,
      };
    },
    userActiveOrdersFetchFailure(state, { payload }: PayloadAction<string>) {
      state.userActiveOrdersRequestMeta = {
        ...state.userActiveOrdersRequestMeta,
        loading: false,
        error: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

// TODO: CONVERT TO thunk
export function tokenHistoryFetchRequest(tokenAddress: string) {
  return async (dispatch) => {
    dispatch(historyFetchRequest());
    return backendAPI
      .get<Array<HistoricTradeRaw>>(`/trade/history/${tokenAddress}`)
      .then((response) => {
        const { data } = response;

        const trades: HistoricTrades = data.map((tradeRaw) => {
          const { volume, cost, ...trade } = tradeRaw;
          return Object.assign(trade, {
            cost: BigInt(cost),
            volume: BigInt(volume),
          });
        });

        dispatch(historyFetchSuccess(trades));
      })
      .catch((error) => {
        console.error(error);
        const networkError = i18nInstance.t('network_error');
        dispatch(historyFetchFailure(networkError));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userTradeHistoryFetchRequest() {
  return async (dispatch) => {
    dispatch(userTradeHistoryFetchPending());
    return backendAPI
      .get<Array<UserTradeHistoryEntryRaw>>('/trade/history/user')
      .then((response) => {
        const { data } = response;
        const correctTypeData = data.map((historyEntry) => {
          return {
            ...historyEntry,
            cost: BigInt(historyEntry.cost),
            volume: BigInt(historyEntry.volume),
          };
        });
        dispatch(userTradeHistoryFetchSuccess(correctTypeData));
      })
      .catch((error) => {
        const errorDescription = i18nInstance.t('account_trade_history_fetching_error');
        console.error(error);
        dispatch(userTradeHistoryFetchFailure(errorDescription));
        dispatch(createNotification(errorDescription, 'error'));
      });
  };
}
export function userActiveOrdersFetchRequest(chainId: number) {
  return async (dispatch) => {
    dispatch(userActiveOrdersFetchPending());
    return backendAPI
      .get('/trade/user')
      .then(async (response) => {
        const { data: ordersRaw } = response;
        const orders = ordersRaw?.map((order) => {
          const { signature, limitOrder } = order;
          const { takerTokenFeeAmount, expiry, salt, makerAmount, takerAmount, ...limitOrderTemp } =
            limitOrder;
          const limitOrderData = Object.assign(limitOrderTemp, {
            takerTokenFeeAmount: BigInt(takerTokenFeeAmount),
            expiry: BigInt(expiry),
            salt: BigInt(salt),
            makerAmount: BigInt(makerAmount),
            takerAmount: BigInt(takerAmount),
          });
          const orderRatio =
            formatBigInt(limitOrderData.takerAmount) / formatBigInt(limitOrderData.makerAmount);
          return {
            limitOrder: new LimitOrderBI({ ...limitOrderData }),
            signature,
            ratio: orderRatio,
          };
        });
        const validOrders: Array<OrderWrapper> = await filter(orders, async (order) => {
          const isValid = await isOrderValid(order, chainId);
          return isValid;
        });
        const upToDateValidOrders = await updateOrderAmounts(validOrders, chainId);
        dispatch(userActiveOrdersFetchSuccess(upToDateValidOrders));
      })
      .catch((error) => {
        console.error(error);
        const errorDescription = i18nInstance.t('account_trade_orders_fetching_error');
        dispatch(userActiveOrdersFetchFailure(errorDescription));
        dispatch(createNotification(errorDescription, 'error'));
      });
  };
}
// Filter with async function
async function filter(arr, callback) {
  // eslint-disable-next-line symbol-description
  const fail = Symbol();
  return (
    await Promise.all(arr.map(async (item) => ((await callback(item)) ? item : fail)))
  ).filter((i) => i !== fail);
}

export function tokenOrdersFetchRequest(tokenAddress: string, chainId: number) {
  return async (dispatch) => {
    dispatch(ordersFetchRequest());
    return backendAPI
      .get<Array<OrderWrapperRaw>>(`/trade/${tokenAddress}`)
      .then(async (response) => {
        const { data: ordersRaw } = response;
        const orders = ordersRaw
          .map((order) => {
            const { signature, limitOrder } = order;
            const {
              takerTokenFeeAmount,
              expiry,
              salt,
              makerAmount,
              takerAmount,
              ...limitOrderTemp
            } = limitOrder;
            const limitOrderData = Object.assign(limitOrderTemp, {
              takerTokenFeeAmount: BigInt(takerTokenFeeAmount),
              expiry: BigInt(expiry),
              salt: BigInt(salt),
              makerAmount: BigInt(makerAmount),
              takerAmount: BigInt(takerAmount),
            });

            const orderRatio =
              formatBigInt(limitOrderData.takerAmount) / formatBigInt(limitOrderData.makerAmount);

            return {
              limitOrder: new LimitOrderBI({ ...limitOrderData }),
              signature,
              ratio: orderRatio,
            };
          })
          .sort((order1, order2) => {
            return order1.ratio - order2.ratio;
          });

        const validOrders: Array<OrderWrapper> = await filter(orders, async (order) => {
          const isValid = await isOrderValid(order, chainId);
          return isValid;
        });
        dispatch(ordersFetchSuccess(validOrders));
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(ordersFetchFailure(networkError));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function postSellOrder(orderData, chainId: number) {
  return async (dispatch) => {
    dispatch(postSellOrderRequest());
    if (orderData === null) {
      const orderError = i18nInstance.t('notification_create_order_error');
      dispatch(postSellOrderFailure(orderError));
      dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_CREATION_ERROR));
      return null;
    }
    return backendAPI
      .post('/trade', orderData)
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(postSellOrderSuccess());
          dispatch(hideDialogs());
          dispatch(tokenHistoryFetchRequest(orderData.makerToken));
          dispatch(tokenOrdersFetchRequest(orderData.makerToken, chainId));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_PLACED));
        } else {
          dispatch(postSellOrderFailure(data.reason));
          dispatch(hideDialogs());
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18nInstance.t('network_error');
        dispatch(postSellOrderFailure(networkError));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        dispatch(hideDialogs());
      });
  };
}

type BuyOrderFormValues = {
  tokensToReceive: string;
  symbol: string;
  price: string;
  daiAmountBI: bigint;
};

export function fillOrders(
  bsptTokenAddress,
  orders,
  originalOrdersMap,
  formValues: BuyOrderFormValues,
  chainId: number,
  callback: () => void
) {
  return async (dispatch) => {
    dispatch(postFillOrderRequest());
    batchFillOrders(bsptTokenAddress, orders, originalOrdersMap, formValues, chainId)
      .then((tradeData) => {
        dispatch(postFillOrderSuccess());
        dispatch(tokenOrdersFetchRequest(bsptTokenAddress, chainId));
        dispatch(hideDialogs());
        return dispatch(
          showDialog({
            type: DialogTypes.CONFIRM_BUY,
            buyConfirmData: {
              amount: parseFloat(formValues.tokensToReceive),
              token: formValues.symbol,
              transactionHash: tradeData[0].txHash,
            },
          })
        );
      })
      .catch(() => {
        dispatch(hideDialogs());
        const orderError = i18nInstance.t('dialog_order_failed');
        dispatch(postFillOrderFailure(orderError));
        return dispatch(
          showDialog({
            type: DialogTypes.TRANSACTION_FAILED,
            failedTransactionData: {
              errorMessage: orderError,
            },
          })
        );
      })
      .finally(() => {
        callback();
      });
  };
}

export const {
  historyFetchFailure,
  historyFetchRequest,
  historyFetchSuccess,
  ordersFetchFailure,
  ordersFetchRequest,
  ordersFetchSuccess,
  postFillOrderFailure,
  postFillOrderRequest,
  postFillOrderSuccess,
  postSellOrderFailure,
  postSellOrderRequest,
  postSellOrderSuccess,
  userTradeHistoryFetchPending,
  userTradeHistoryFetchSuccess,
  userTradeHistoryFetchFailure,
  userActiveOrdersFetchPending,
  userActiveOrdersFetchSuccess,
  userActiveOrdersFetchFailure,
} = tradeSlice.actions;
export default tradeSlice.reducer;
