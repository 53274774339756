import type { FC } from 'react';
import type { TCloseSVGProps as TProps } from 'types/icons';

const CloseSVG: FC<TProps> = ({
  className,
  fillColor,
  height = '100%',
  width = '100%',
  strokeWidth = '2px',
  onClick = undefined,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      width={width}
      height={height}
      className={className}
      onClick={onClick}
    >
      <path
        d="M1 1l20 20M21 1L1 21"
        fill="none"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CloseSVG;
