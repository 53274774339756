import { memo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import makeBlockie from 'ethereum-blockies-base64';
import styled from 'styled-components';
import { Grid, Link, Typography, useTheme } from '@material-ui/core';
import GoogleMapReact from 'google-map-react/dist/index.modern';
import { GlobeSVG, MapSVG } from 'components/icons';
import ZeroItemsLayout, { HeaderTypography } from 'components/layout/ZeroItemsLayout';
import InfoTaglineTypography from 'components/typography/InfoTaglineTypography';
import InfoLabelTypography from 'components/typography/InfoLabelTypography';
import { formatDaiToFiatString } from 'utils/currency';
import { displayHexString } from 'utils/format';
import InfoValueTypography from 'components/typography/InfoValueTypography';
import type { PropertyIssuer as TProps } from 'types';

const AboutIssuerGrid = styled(Grid)`
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.body.secondary};
`;

const GlobeWrapper = styled(Grid)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.0625rem solid ${(props) => props.theme.body.primary};
`;

const Blockie = styled.img`
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
`;

const BoldTypography = styled(Typography)`
  font-weight: 500;
`;

const FactsGrid = styled(Grid)`
  padding: 1rem;
  opacity: 0.8;
`;

const FactWrapper = styled(Grid)`
  padding: 0.8rem 0;
`;

const GoogleMapReactWrapper = styled(Grid)`
  overflow: hidden;

  min-height: 5rem;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: 12rem;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex: none;
  }
`;

const NoDataParagraph = styled(HeaderTypography)`
  text-align: center;
  margin-inline: auto;
  color: inherit;
`;

const LargeInfoValueTypography = styled(InfoValueTypography)`
  font-size: 2rem;
`;

const createMapOptions = () => {
  return {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };
};

const handleApiLoaded = (map, maps, place: { lat: number; lng: number }) => {
  return new maps.Marker({
    position: {
      lat: place.lat,
      lng: place.lng,
    },
    map,
  });
};

const ETHERSCAN_ADDRESS_URL = `${process.env.REACT_APP_ETHERSCAN_URL}/address/`;

const IssuerInfo: FC<{ data: TProps }> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    address,
    name,
    websiteUrl,
    latitude,
    longitude,
    walletAddress,
    licencedIssuerStats: {
      averageHolder,
      averageYield,
      listedProperties,
      profitDistributed,
      totalInvestors,
      totalValuation,
    },
  } = data;

  const center = {
    lat: latitude,
    lng: longitude,
  };
  return (
    <AboutIssuerGrid container item>
      <Grid container item xs direction="column">
        {/* TODO: chang this to 1 rem to brevent map breaking */}
        <Grid container item direction="column" style={{ padding: '1rem' }}>
          <BoldTypography color="primary" variant="h2">
            {t('marketplace_property_about_issued_by')}
          </BoldTypography>
          <Grid container item xs style={{ marginTop: '1rem' }}>
            {walletAddress || address || name || websiteUrl ? (
              <Grid
                container
                item
                xs
                spacing={4}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid container item xs={2} alignItems="center">
                  {walletAddress ? <Blockie src={makeBlockie(walletAddress)} /> : null}
                </Grid>
                <Grid container item direction="column" xs={8}>
                  <BoldTypography variant="h4">{name}</BoldTypography>
                  {walletAddress ? (
                    <Link
                      href={`${ETHERSCAN_ADDRESS_URL}${walletAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="h4">{displayHexString(walletAddress, 8)}</Typography>
                    </Link>
                  ) : null}
                  <Typography variant="h4">{address}</Typography>
                </Grid>
                <Grid container item xs={2} alignItems="center" justifyContent="flex-end">
                  <Link href={websiteUrl} target="_blank" rel="noopener noreferrer">
                    <GlobeWrapper container item justifyContent="center" alignItems="center">
                      <GlobeSVG fillColor={theme.palette.primary.main} height="50%" width="50%" />
                    </GlobeWrapper>
                  </Link>
                </Grid>
              </Grid>
            ) : (
              <NoDataParagraph>{t('marketplace_property_issuer_info_empty')}</NoDataParagraph>
            )}
          </Grid>
        </Grid>
        <GoogleMapReactWrapper container item xs>
          {longitude && latitude ? (
            <GoogleMapReact
              options={createMapOptions}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              }}
              defaultCenter={center}
              defaultZoom={5}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, center)}
            />
          ) : (
            <ZeroItemsLayout
              style={{ background: '#e2dfdf' }}
              Icon={MapSVG}
              size={9}
              desc={t('property_no_location_hint')}
              hint={t('marketplace_table_empty_hint')}
            />
          )}
        </GoogleMapReactWrapper>
      </Grid>
      <FactsGrid container item direction="column">
        <Grid container item xs>
          <FactWrapper
            container
            item
            xs={5}
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <InfoTaglineTypography>&nbsp;</InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {listedProperties.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">
              {t('marketplace_property_about_listed_properties')}
            </InfoLabelTypography>
          </FactWrapper>
          <FactWrapper
            container
            item
            xs={7}
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <InfoTaglineTypography>
              {`${totalValuation.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} DAI`}
            </InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {formatDaiToFiatString(totalValuation)}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">{t('total_valuation')}</InfoLabelTypography>
          </FactWrapper>
          <FactWrapper
            container
            item
            xs={5}
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <InfoTaglineTypography>&nbsp;</InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {`${Number(averageYield).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}%`}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">
              {t('marketplace_property_about_average_apy')}
            </InfoLabelTypography>
          </FactWrapper>
          <FactWrapper
            container
            item
            xs={7}
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <InfoTaglineTypography>
              {`${profitDistributed.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} DAI`}
            </InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {formatDaiToFiatString(profitDistributed)}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">{t('profits_distributed')}</InfoLabelTypography>
          </FactWrapper>
          <FactWrapper
            container
            item
            xs={5}
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <InfoTaglineTypography>&nbsp;</InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {Number(totalInvestors).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">
              {t('marketplace_property_about_total_investors')}
            </InfoLabelTypography>
          </FactWrapper>
          <FactWrapper
            container
            item
            xs={7}
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <InfoTaglineTypography>
              {`${averageHolder.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} DAI`}
            </InfoTaglineTypography>
            <LargeInfoValueTypography align="right">
              {formatDaiToFiatString(averageHolder)}
            </LargeInfoValueTypography>
            <InfoLabelTypography align="right">{t('average_holder')}</InfoLabelTypography>
          </FactWrapper>
        </Grid>
      </FactsGrid>
    </AboutIssuerGrid>
  );
};

export default memo(IssuerInfo);
