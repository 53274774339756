import axios from 'axios';
import { store } from 'store/index';

export const backendAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

// Add authentication token to request headers if user is logged in
backendAPI.interceptors.request.use((config) => {
  const token = checkAndGetToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

/* api.interceptors.request.use((request) => {
  console.log('Starting Request', request);
  return request;
});

api.interceptors.response.use((response) => {
  console.log('Response:', response);
  return response;
}); */

// NOTE
// This is a temporary solution.
// A much better solution would be to only use an interceptor
// for endpoints that actually require authorization or to set up external functions
// generated by OpenAPI and handle authorization in a way that's more in-line with those methods
function checkAndGetToken(): string | null {
  const { token, expireAt } = store.getState()?.authentication ?? { token: null, expireAt: null };

  if (expireAt && token) {
    if (Date.now() > expireAt * 1000) {
      return null;
    }

    return token;
  }

  return null;
}
