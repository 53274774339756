import type { ChangeEvent, FC } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import styled from 'styled-components';

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
`;
const Accordion = styled(MuiAccordion)`
  box-shadow: none;
`;

const AccordionWrapper = styled.div`
  max-width: 100%;
  margin-top: 2rem;
`;

const BoldTypography = styled(Typography)`
  font-weight: 500;
`;

type TProps = {
  expandedCategory: string;
  handleChange: (categoryId: string) => (_event: ChangeEvent<{}>, expanded: boolean) => void;
  highlights: { id: string; text: string; type: string }[];
};

const InvestmentHighlights: FC<TProps> = ({ expandedCategory, handleChange, highlights }) => {
  return (
    <>
      <Typography variant="h4" align="justify">
        {highlights
          .filter((category) => {
            return category.type === 'Investment highlights';
          })
          .map((category) => {
            return category.text;
          })}
      </Typography>
      <AccordionWrapper>
        {highlights
          .filter((category) => {
            return category.type !== 'Investment highlights';
          })
          .map((category) => {
            return (
              <Accordion
                expanded={expandedCategory === category.id}
                onChange={handleChange(category.id)}
                key={`panel-${category.id}`}
                square
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <BoldTypography variant="h4">{category.type}</BoldTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="h4"
                    align="justify"
                    style={{ maxWidth: '100%', wordBreak: 'break-word' }}
                  >
                    {category.text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </AccordionWrapper>
    </>
  );
};

export default InvestmentHighlights;
