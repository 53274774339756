import type { ReactElement } from 'react';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { getCompanyDefaultRoute } from 'store/company';
import {
  authenticationRoutes,
  dashboardRoutes,
  kycRoutes,
  kycCallbackRoutes,
  offeringProFormaRoutes,
} from 'routes';
import Loader from '../custom/Loader';
import { useAppDispatch, useAppSelector } from 'types';
import InitErrorHandler from 'components/routes/InitErrorHandler';

const AuthLayout = lazy(() => import('components/routes/layouts/Auth'));
const DashboardLayout = lazy(() => import('components/routes/layouts/DashboardLayout'));
const KycLayout = lazy(() => import('components/routes/layouts/Kyc'));
const DefaultLayout = lazy(() => import('components/routes/layouts/DefaultLayout'));
const Page404 = lazy(() => import('../../pages/auth/Page404'));
const AppSetupErrorPage = lazy(() => import('../../pages/auth/AppSetupError'));
const NoMobile = lazy(() => import('../../pages/auth/NoMobile'));
const RouteContentWrapper = lazy(() => import('components/routes/RouteContentWrapper'));

const Company404 = () => {
  const { company } = useParams();
  return <Navigate to={`/${company}/404`} />;
};

const renderSingleRoute = (Layout, route) => {
  return (
    <Route
      key={route.id}
      path={`/:company${route.path}`}
      element={
        <Suspense fallback={<Loader size="6rem" centerLoader />}>
          <RouteContentWrapper layout={Layout} childComponent={route.component} />
        </Suspense>
      }
    />
  );
};

const renderRoutes = (Layout, routes) =>
  routes.map((route) => {
    let renderedRoutes = new Array<ReactElement>();
    if (route.children) {
      renderedRoutes = route.children.map((childrenRoute) => {
        return renderSingleRoute(Layout, childrenRoute);
      });
    }
    renderedRoutes.push(renderSingleRoute(Layout, route));
    return renderedRoutes;
  });

const RoutesHandler = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company);

  const {
    defaultCompanyRoute,
    defaultCompanyRouteFetchError,
    defaultCompanyRouteNotFound,
    defaultCompanyRouteRequestProcessed,
    isFetchingDefaultCompanyRoute,
    companyConfigurationFetchErrorType,
  } = company;

  const shouldRenderInitErrorHandler =
    defaultCompanyRouteNotFound ||
    companyConfigurationFetchErrorType ||
    defaultCompanyRouteFetchError;

  // In case the defaultCompany isn't set inside of the company Redux state slice, we go and
  // fetch what the default company is
  useEffect(() => {
    if (
      !defaultCompanyRoute &&
      !defaultCompanyRouteFetchError &&
      !defaultCompanyRouteRequestProcessed
    ) {
      dispatch(getCompanyDefaultRoute());
    }
  }, [
    defaultCompanyRoute,
    defaultCompanyRouteFetchError,
    defaultCompanyRouteRequestProcessed,
    dispatch,
  ]);

  if (isFetchingDefaultCompanyRoute || !defaultCompanyRouteRequestProcessed) {
    return <Loader size="6rem" centerLoader />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/error"
          element={
            <Suspense fallback={<Loader size="6rem" centerLoader />}>
              <AppSetupErrorPage />
            </Suspense>
          }
        />

        <Route
          path="/404"
          element={
            <Suspense fallback={<Loader size="6rem" centerLoader />}>
              <Page404 />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={<Navigate to={`/${defaultCompanyRoute ? defaultCompanyRoute : '404'}`} />}
        />

        {renderRoutes(DashboardLayout, dashboardRoutes)}
        {renderRoutes(DashboardLayout, kycRoutes)}
        {renderRoutes(KycLayout, kycCallbackRoutes)}
        {renderRoutes(AuthLayout, authenticationRoutes)}
        {renderRoutes(DefaultLayout, offeringProFormaRoutes)}
        <Route
          path="/:company/404"
          element={
            <Suspense fallback={<Loader size="6rem" centerLoader />}>
              <Page404 />
            </Suspense>
          }
        />
        <Route
          path="/:company/noMobile"
          element={
            <Suspense fallback={<Loader size="6rem" centerLoader />}>
              <NoMobile />
            </Suspense>
          }
        />
        <Route path="/:company/*" element={<Company404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {shouldRenderInitErrorHandler ? (
        <Routes>
          <Route path="*" element={<InitErrorHandler />} />
        </Routes>
      ) : null}
    </Router>
  );
};

export default RoutesHandler;
