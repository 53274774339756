import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const InfoSVG: FC<TBaseIconProps & { className?: string }> = ({
  className,
  fillColor,
  height = '100%',
  width = '100%',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 47.656 48"
      className={className}
    >
      <path
        d="M40.666 40.97C36.162 45.503 30.173 48 23.803 48c-6.371 0-12.36-2.497-16.864-7.03-9.298-9.358-9.298-24.584 0-33.94C11.443 2.497 17.432 0 23.803 0c6.37 0 12.359 2.497 16.863 7.03 4.506 4.532 6.987 10.558 6.987 16.97 0 6.41-2.481 12.437-6.987 16.97zM39.261 8.444C35.132 4.288 29.642 2 23.803 2c-5.84 0-11.33 2.288-15.459 6.444-8.523 8.577-8.523 22.533 0 31.112C12.473 43.712 17.963 46 23.803 46c5.839 0 11.329-2.288 15.458-6.444 4.13-4.155 6.405-9.68 6.405-15.556 0-5.877-2.275-11.402-6.405-15.556zM26.785 38h-5.962a.997.997 0 01-.994-1c0-.553.445-1 .994-1h1.833V22h-1.833a.997.997 0 01-.994-1c0-.552.445-1 .994-1h2.981c.549 0 .994.448.994 1a.871.871 0 01-.06.3.982.982 0 01-.082.184V36h2.129c.549 0 .994.447.994 1 0 .552-.445 1-.994 1zm-3.168-22a2.994 2.994 0 01-2.982-3c0-1.654 1.338-3 2.982-3 1.644 0 2.981 1.346 2.981 3 0 1.655-1.337 3-2.981 3zm0-4a.998.998 0 00-.994 1c0 .552.446 1 .994 1a.998.998 0 00.993-1c0-.551-.446-1-.993-1z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default InfoSVG;
