import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const EuroSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 27.75 47.875"
    >
      <path
        d="M27.376,21.803 L10.471,47.175 C10.338,47.584 9.968,47.872 9.534,47.872 C9.487,47.872 9.440,47.869 9.393,47.862 C8.933,47.797 8.608,47.427 8.556,46.988 C8.549,46.957 8.554,46.925 8.550,46.892 C8.549,46.839 8.533,46.789 8.541,46.735 L8.549,46.681 C8.553,46.635 8.557,46.590 8.568,46.545 L11.179,28.148 C11.176,28.148 11.172,28.077 11.149,28.052 C11.117,28.017 11.117,27.875 10.875,27.875 L1.875,27.875 C1.097,27.875 0.477,27.614 0.178,27.025 C-0.124,26.426 -0.018,25.682 0.464,25.034 L18.485,0.409 C18.812,-0.036 19.438,-0.133 19.885,0.190 C20.173,0.399 20.298,0.732 20.277,1.062 C20.279,1.124 20.295,1.184 20.285,1.248 L17.527,18.729 C17.512,18.825 17.522,18.874 17.527,18.883 C17.538,18.896 17.677,18.875 17.875,18.875 L25.875,18.875 C26.650,18.875 27.244,19.275 27.556,19.845 C27.872,20.422 27.807,21.136 27.376,21.803 ZM17.875,20.875 C17.123,20.875 16.424,20.671 16.000,20.175 C15.601,19.708 15.440,19.083 15.548,18.415 L17.697,4.792 L1.875,25.875 L10.875,25.875 C11.636,25.875 12.174,26.219 12.618,26.696 C13.027,27.135 13.219,27.712 13.170,28.361 L11.150,42.608 L25.875,20.875 L17.875,20.875 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EuroSVG;
