export const BankAccountTypes = {
  EMPTY: '',
  AU: 'AU',
  IBAN: 'IBAN',
  UK: 'UK',
  US: 'US',
} as const;

export type BankAccountTypes = (typeof BankAccountTypes)[keyof typeof BankAccountTypes];

export type UserBankAccountState = {
  bankAccountType: BankAccountTypes;
  bankAddress: string;
  bankBIC: string;
  bankName: string;
  country: string;
  fetchError: string | null;
  iban: string;
  isFetching: boolean;
  isInitialFetchingDone: boolean;
  isInitialState: boolean;
  isUpdating: boolean;
  name: string;
  updateErrorMessage: string | null;
};

export type UserBankAccountUpdateData = {
  bankAccountType: BankAccountTypes;
  name: string;
  iban: string;
  bankName: string;
  bankBIC: string;
  bankAddress: string;
  country: string;
};
