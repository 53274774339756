import type { AdaptedPortfolioProperty, PropertyDocument } from 'types/store';

type Element = AdaptedPortfolioProperty | PropertyDocument;

export const stableSort = <EL extends Element>(
  array: Array<EL>,
  cmp: (a: EL, b: EL) => number
): Array<EL> => {
  const arrayCombinedPropertyAndIndex = array.map((el, index) => {
    // I explicitly typed the return value because it seems that TS isn't able to infer it yet
    // properly
    const returnValue: [EL, number] = [el, index];
    return returnValue;
  });

  arrayCombinedPropertyAndIndex.sort((a, b): number => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  const sortedArray = arrayCombinedPropertyAndIndex.map((el) => el[0]);
  return sortedArray;
};
