import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { initI18n } from 'utils';

import type { RootReducer } from './rootReducer';
import rootReducer from './rootReducer';

declare global {
  interface BigInt {
    toJSON: () => string;
    fromJSON: () => bigint;
  }
}

// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function () {
  return this.toString();
};

// eslint-disable-next-line no-extend-native
BigInt.prototype.fromJSON = function () {
  return BigInt(this as unknown as string);
};

const authenticationFilter = createWhitelistFilter('authentication', ['token', 'expireAt', 'site']);
const userFilter = createWhitelistFilter('user', ['preferredLanguage']);
const supportedCompanyLangsFilter = createWhitelistFilter('company', ['languages']);
const currencyFilter = createWhitelistFilter('currency', ['selectedCurrency']);
const walletFilter = createWhitelistFilter('wallet', ['account', 'type']);
const userWalletFilter = createWhitelistFilter('userWallet', ['filteredWallets']);

const persistConfig = {
  key: 'blocksquare-dashboard',
  storage,
  whitelist: ['authentication', 'user', 'currency', 'wallet', 'company', 'userWallet'],
  transforms: [
    authenticationFilter,
    userFilter,
    currencyFilter,
    walletFilter,
    supportedCompanyLangsFilter,
    userWalletFilter,
  ],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /* serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }, */
      serializableCheck: false,
    }),
  // This adds support for Redux DevTools in development mode
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store, {}, () => {
  initI18n();
});

export { store, persistor };
