import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backendAPI, i18nInstance } from 'utils';
import { createNotification } from 'utils/notification';
import { RESET_STATE } from './sharedActions';
import { createAppAsyncThunk, type UserInformation, type UserState } from 'types';

const initialState: UserState = {
  isInitialState: true,
  isFetchingUserInformations: false,
  hasFetchedInitialUserInformation: false,
  errorFetchingUserInformations: null,
  preferredLanguage: '',
  email: '',
  birth: '',
  country: '',
  firstName: '',
  lastName: '',
  userId: '',
  // shows us if the user's wallets can hold this CP's property tokens
  cpWhitelistingStatus: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updatePreferredLanguage(state, { payload }: PayloadAction<{ langCode: string }>) {
      const { langCode } = payload;
      state.preferredLanguage = langCode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
    builder.addCase(fetchUserInformations.pending, (state) => {
      state.isFetchingUserInformations = true;
      state.errorFetchingUserInformations = null;
    });
    builder.addCase(fetchUserInformations.fulfilled, (state, { payload }) => {
      state.isFetchingUserInformations = false;
      const { birth, country, email, firstName, lastName, identifier, status, languageCode } =
        payload;
      state.birth = birth || '';
      state.country = country || '';
      state.email = email;
      state.firstName = firstName || '';
      state.lastName = lastName || '';
      state.userId = identifier;
      state.cpWhitelistingStatus = status;
      state.hasFetchedInitialUserInformation = true;
      state.preferredLanguage = languageCode;
    });
    builder.addCase(fetchUserInformations.rejected, (state, { payload }) => {
      state.isFetchingUserInformations = false;
      state.hasFetchedInitialUserInformation = true;
      state.errorFetchingUserInformations = payload as string;
    });
  },
});

/**
 * Fetch the user informations
 *
 */
export const fetchUserInformations = createAppAsyncThunk(
  'user/userInformations',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      user: { email },
    } = getState();

    // We only fetch user information if there is nothing in the email field
    // In case we need to force a user information fetch request, we can easily do so
    // by providing an argument to this function and adding it into the conditional
    // statement
    if (!email) {
      return backendAPI
        .get<UserInformation>('/user')
        .then(({ data }) => data)
        .catch(() => {
          const errorMessage = i18nInstance.t('network_error');
          dispatch(createNotification(errorMessage, 'error'));
          return rejectWithValue(errorMessage);
        });
    }
    return rejectWithValue("User was already fetched, we don't need to fetch it again.");
  }
);

export const { updatePreferredLanguage } = userSlice.actions;
export default userSlice.reducer;
