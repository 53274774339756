import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';

const EnergySVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 96 96"
      width={width}
      height={height}
    >
      <g>
        <path
          d="M76.2471 43.53L40.8265 94.9098C40.6118 95.5495 40.0401 96 39.3673 96C39.3009 96 39.233 95.9952 39.1651 95.9872C38.4292 95.891 37.908 95.2833 37.8338 94.5619C37.8227 94.5042 37.8275 94.4464 37.8211 94.3871C37.8227 94.3246 37.8006 94.2653 37.8085 94.1995L37.8275 94.0472C37.8353 93.9847 37.8353 93.9238 37.8511 93.8629L42.3869 57.8905C42.4074 57.6003 42.2969 57.4319 42.2116 57.3373C41.9747 57.076 41.4677 55.9281 40.8897 55.9281H21.9381C20.4425 55.9281 19.7713 56.3016 19.2612 55.2194C18.7558 54.1517 18.9911 52.8755 19.904 51.7163L58.9854 0.617128C59.5192 -0.0770845 60.5031 -0.206949 61.1885 0.336557C61.6086 0.670036 61.7886 1.17667 61.7633 1.68009C61.7681 1.80194 61.7902 1.92058 61.7665 2.04563L55.0845 37.4745C55.0213 37.8417 55.1224 38.2393 55.3672 38.5343C55.5267 38.7283 56.2121 38.2922 56.6827 38.2922H72.4758C73.0933 38.2922 75.996 38.9591 76.7415 40.4101C77.2089 41.3175 77.0415 42.3677 76.2471 43.53ZM56.6827 41.4987C55.3909 41.4987 53.7863 41.5837 52.9651 40.5929C52.1233 39.5748 51.7679 38.2297 51.9938 36.9022L57.4866 7.7789L23.1131 52.7215H40.8897C42.3727 52.7215 43.674 54.2382 44.519 55.1697C45.2596 55.9858 45.6024 57.0423 45.5092 58.2207L41.7504 88.0414L74.0551 41.4987H56.6827Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default EnergySVG;
