import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const NoBorderInfoSVG: FC<TBaseIconProps & { className?: string }> = ({
  className,
  fillColor,
  height = '100%',
  width = '100%',
}) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 7.9500049 28.000001"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M 6.9560045,28 H 0.99400453 A 0.997,0.997 0 0 1 4.5271015e-6,27 c 0,-0.553 0.4450000028985,-1 0.9940000028985,-1 H 2.8270045 V 12 H 0.99400453 A 0.997,0.997 0 0 1 4.5271015e-6,11 c 0,-0.552 0.4450000028985,-1 0.9940000028985,-1 H 3.9750045 c 0.549,0 0.994,0.448 0.994,1 a 0.871,0.871 0 0 1 -0.06,0.3 0.982,0.982 0 0 1 -0.082,0.184 V 26 h 2.129 c 0.549,0 0.994,0.447 0.994,1 0,0.552 -0.445,1 -0.994,1 z m -3.168,-22 A 2.994,2.994 0 0 1 0.80600453,3 c 0,-1.654 1.33799997,-3 2.98199997,-3 1.644,0 2.981,1.346 2.981,3 0,1.655 -1.337,3 -2.981,3 z m 0,-4 a 0.998,0.998 0 0 0 -0.994,1 c 0,0.552 0.446,1 0.994,1 a 0.998,0.998 0 0 0 0.993,-1 c 0,-0.551 -0.446,-1 -0.993,-1 z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default NoBorderInfoSVG;
