export const UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_SET =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n;
// So, as long as the allowance is higher than 10^24 we will not prompt the user to set their allowance.
export const UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_CHECK_AGAINST = BigInt(10 ** 24);

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const DECIMALS_18_BI = BigInt(10 ** 18);

export const SALE_MODE = Object.freeze({
  PRE_SALE_START: 0,
  PRE_SALE_END: 1,
  MAIN_SALE_START: 2,
  MAIN_SALE_END: 3,
});

/**
 * In case a table has more row entries than specified by this constant,
 * pagination section should be triggered.
 */
export const TABLE_PAGINATION_TRIGGER = 5;

/**
 * Default rows per page for a generic table.
 */
export const DEFAULT_ROWS_PER_TABLE_PAGE = 7;

/**
 * Values for each step in the buy offering tokens dialog.
 *
 * @property {number} OVERVIEW_STEP The step where the user sees an overview of the current
 *  offering, including the token symbol, total number of tokens, the current price
 *  and so on
 *
 * @property {number} BUYING_FORM_STEP The step where the user can interact with the form
 *  ( PRE & MAIN sale )
 *
 * @property {number} PRE_SALE_CONFIRMATION_STEP (Only shown for Pre-sale properties),
 *  The final step where the user confirms their purchase and sees the total cost of the
 *  tokens before a pro-forma invoice is generated.
 *
 */
export const OFFERING_BUYING_DIALOG_STEP_VALUES = Object.freeze({
  OVERVIEW_STEP: 0,
  BUYING_FORM_STEP: 1,
  PRE_SALE_CONFIRMATION_STEP: 2,
});

/**
 * The price we store on the blockchain is multiplied by 100 to comply with the
 * blockchain's inability to handle floating points but with the help of
 * this multiplier we still retain the two decimal places.
 *
 * This is why we have to divide back what we receive from the blockchain/graph with 100 in order
 * to get the correct price with two decimals.
 */
export const PRICE_DECIMAL_MULTIPLIER = 100;

/**
 * Maximum amount of tokens that can be minted for a property.
 *
 * Useful for:
 * - calculating the valuation of offering properties which basically amounts to
 * the price of a single BSPT token multiplied by the maximum supply
 *
 * - all formulae depending on the maximum supply number
 */
export const MAX_SUPPLY = 100000;
