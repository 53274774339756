import type { RequestStatus } from '../api';

export const KYC_ERROR = {
  GENERIC_ERROR: 'kyc_generic_error',
  TOO_MANY_FAILED: 'kyc_too_many_failed',
  COULDNT_PROCESS: 'kyc_couldnt_process',
  SELFIE_MISSMATCH: 'kyc_selfie_missmatch',
  FAILED: 'kyc_failed',
  TOO_YOUNG: 'kyc_too_young',
  COULDNT_START: 'kyc_couldnt_start',
  DONE: 'kyc_done',
} as const;

export type KycErrorKeys = (typeof KYC_ERROR)[keyof typeof KYC_ERROR];

export const KYC_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DENIED: 'DENIED',
  NEW: 'NEW',
  PENDING: 'PENDING',
  WHITELISTED: 'WHITELISTED',
};

export const UserKycErrorIds = Object.freeze({
  initialStateAlreadyEstablished: 'user-kyc-initial-state-already-established',
});

export type KycStatusKeys = (typeof KYC_STATUS)[keyof typeof KYC_STATUS];

export type KycRequest = RequestStatus & {
  kycStatusEnum: KycStatusKeys;
  accredited: boolean;
  failedReason: string | null;
  zignsecURL: string | null;
  sessionID: string | null;
  lastTry: string | null;
  remainingAttempts: number;
};

export type KycUrlRequest = RequestStatus & {
  kycUrl: string;
};

export type UserKycState = {
  isInitialState: boolean;
  isAccredited: boolean;
  isRequestingKYCStatus: boolean;
  hasFetchedInitialKYCStatus: boolean;
  kycStatus: KycStatusKeys;
  step: number;
  remainingAttempts: number;
  errorMessageKycStatus: string | null;
  kycUrl: string | null;
  isRequestingKycUrl: boolean;
  kycUrlErrorMessage: string | null;
};
