import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';
import { backendAPI, createNotificationByType } from 'utils';
import type { CoinsValueRequest, CurrencyState, ExchangeRate } from 'types/store';
import { createAppAsyncThunk, CurrencyTypes, NOTIFICATION_TYPES } from 'types/store';

const initialState: CurrencyState = {
  selectedCurrency: { blocksquare: CurrencyTypes.EUR },
  daiToFiatRates: {},
  usdToOtherRates: {},
  isFetchingRates: false,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, (state) => {
      const newState = { ...initialState };
      newState.selectedCurrency = state.selectedCurrency;
      return newState;
    });
    builder.addCase(fetchExchangeRates.pending, (state) => {
      state.isFetchingRates = true;
    });
    builder.addCase(fetchExchangeRates.fulfilled, (state, { payload }) => {
      state.isFetchingRates = false;
      state.daiToFiatRates = payload.daiToFiatRates;
      state.usdToOtherRates = payload.usdToOtherRates;
    });
    builder.addCase(fetchExchangeRates.rejected, (state) => {
      state.isFetchingRates = false;
    });
    builder.addCase(changeCurrencyRequest.fulfilled, (state, { payload }) => {
      state.selectedCurrency[payload.company] = payload.currency;
    });
  },
});

/**
 * Receive the exchange rates for different currencies
 *
 */
export const fetchExchangeRates = createAppAsyncThunk(
  'currency/exchangeRate',
  async (_, { rejectWithValue }) => {
    return Promise.all([
      backendAPI.get<ExchangeRate>(`/info/exchange-rate`),
      backendAPI.get<CoinsValueRequest>(`/info/coins-value`),
    ])
      .then(([exchangeRatesResponse, coinsValuesResponse]) => {
        if (exchangeRatesResponse.data && coinsValuesResponse.data) {
          const { data: usdToOtherRates } = exchangeRatesResponse;
          const {
            data: { DAI: daiToFiatRates },
          } = coinsValuesResponse;

          // For the USD to other fiat currencies - we don't receive USD exchange rate
          // from the back-end since it's unnecessary ( 1USD === 1USD ),
          // but I have to set it here for the rest of the front-end to continue working normally when
          // deriving fiat values if user's settings already state that the preferred currency is USD
          usdToOtherRates.USD = 1;
          return { daiToFiatRates, usdToOtherRates };
        } else {
          return rejectWithValue("We didn't receive exchange rate data");
        }
      })
      .catch((error: Error) => rejectWithValue(error.message));
  }
);

/**
 * Receive the exchange rates for different currencies
 *
 */
export const changeCurrencyRequest = createAppAsyncThunk(
  'currency/changeCurrency',
  async (
    changeCurrencyData: {
      company: string;
      currency: CurrencyTypes;
    },
    { dispatch }
  ) => {
    dispatch(createNotificationByType(NOTIFICATION_TYPES.SETTINGS_SAVED));
    return changeCurrencyData;
  }
);

export default currencySlice.reducer;
