import styled from 'styled-components';
import { TableCell as MuiTableCell, TableRow, Grid as MuiGrid } from '@material-ui/core';
import { spacing } from '@material-ui/system';

const Grid = styled(MuiGrid)(spacing);

export const TableBodyRow = styled(TableRow)`
  border-top: 0.0625rem solid ${(props) => props.theme.palette.primary.main};
  border-bottom: 0.0625rem solid ${(props) => props.theme.palette.primary.main};
`;

export const TableCell = styled(MuiTableCell)`
  padding: 0.75rem;
`;

export const TableWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 1rem;

  overflow-x: auto;
  background-color: ${(props) => props.theme.body.secondary};
  border-radius: 0.6rem;

  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;

    background: none;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;

    background: ${(props) => props.theme.palette.primary.main};
  }
`;
