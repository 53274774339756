import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const MetamaskSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 96 96"
      width={width}
      height={height}
    >
      <path
        fill={fillColor}
        d="M96,61.5c0-0.2,0-0.3,0-0.4c0,0,0,0,0,0L81.2,7.3c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1
	c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0
	c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1
	c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0L48,40.8l-30.7-34c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1,0
	c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0C15.1,6.7,15,6.8,15,6.9
	c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0.1L0,61c0,0,0,0,0,0c0,0.1-0.1,0.3,0,0.4c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4
	c0,0,0,0,0,0c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0l46.5,26.9
	c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4-0.1
	c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2-0.1l46.5-26.9c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.2-0.4
	C96,61.8,96,61.7,96,61.5C96,61.5,96,61.5,96,61.5z M16.5,12.4l14.1,35.8L3.7,58.9L16.5,12.4z M50.6,42.4l24.8-27.5L62.6,47.2
	L50.6,42.4z M61.5,50L48,84.2L34.5,50L48,44.6L61.5,50z M33.4,47.2L20.7,14.9l24.8,27.5L33.4,47.2z M31.7,51.1L45,84.8L4.9,61.7
	L31.7,51.1z M64.3,51.1l26.8,10.6L51,84.8L64.3,51.1z M65.4,48.3l14.1-35.8l12.8,46.5L65.4,48.3z"
      />
    </svg>
  );
};

export default MetamaskSVG;
