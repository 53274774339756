import type { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import type { TBaseProps as Props } from 'types';

const TableTagline = styled(Typography)`
  opacity: 0.5;
  font-size: 0.9rem;
  line-height: 1.2;
`;

const TableTaglineTypography: FC<Props> = ({ style, children, align, color }) => {
  return (
    <TableTagline style={style} align={align || 'right'} color={color}>
      {children}
    </TableTagline>
  );
};

export default TableTaglineTypography;
