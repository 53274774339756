export const BANK_TRANSACTION_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
} as const;

export type BankTransactionTypeKeys =
  (typeof BANK_TRANSACTION_TYPES)[keyof typeof BANK_TRANSACTION_TYPES];

export const BankAccountTransactionStatusTypes = {
  CONFIRMED: 'CONFIRMED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
} as const;

export type BankAccountTransactionStatusTypes =
  (typeof BankAccountTransactionStatusTypes)[keyof typeof BankAccountTransactionStatusTypes];

export type BankTransaction = {
  date: string;
  type: BankTransactionTypeKeys;
  amount: number;
  currency: string;
  received: string;
  id: string;
  status: BankAccountTransactionStatusTypes;
  txHash: string;
};

export const OfferingTransactionStatusTypes = {
  CANCELED: 'CANCELED',
  MINTED: 'MINTED',
  PAYED: 'PAYED',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  PENDING: 'PENDING',
  // Check if this exists as swagger does not mention it as status
  CONFIRMED: 'CONFIRMED',
} as const;

export type OfferingTransactionStatusTypes =
  (typeof OfferingTransactionStatusTypes)[keyof typeof OfferingTransactionStatusTypes];

export type OfferingTransaction = {
  investmentAmount: number;
  investmentCurrency: string;
  proFormaCreated: string;
  property: string;
  status: OfferingTransactionStatusTypes;
  tokenAddress: string;
  tokensReceived: number;
  tokenSymbol: string;
  transactionHash: string;
  // Check if null can be a type here. Based on Swagger its not available
  transactionProcessedTime: string | null;
  wallet: string;
};

export type UserBankTransactionsState = {
  isSendingTransaction: boolean;
  sendTransactionErrorMessage: string | null;
  userTransactions: Array<BankTransaction>;
  userOfferings: Array<OfferingTransaction>;
  isRequestingTransactions: boolean;
  userOfferingsRequestMeta: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
  requestingTransactionsErrorMessage: string | null;
  isSendingWithdrawal: boolean;
  sendWithdrawalErrorMessage: string | null;
};
