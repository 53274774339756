import type { FC } from 'react';

const MapSVG: FC<{ strokeColor?: string; width?: string }> = ({
  strokeColor = '#acacac',
  width = '100%',
}) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 91.892197 73.775002"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={strokeColor}
        d="m 66.155846,25.452 c 0.005,0 0.011,0 0.016,0 2.087,0 4.049,-0.812 5.527,-2.286 1.482,-1.479 2.3,-3.445 2.302,-5.539 0.004,-4.323 -3.508,-7.844 -7.828,-7.85 -0.002,0 -0.005,0 -0.007,0 -4.319,0 -7.835,3.508 -7.84,7.823 -0.004,2.096 0.811,4.068 2.292,5.552 1.479,1.483 3.447,2.3 5.538,2.3 z m 0.009,-12.706 c 0.002,0 0.004,0 0.005,0 2.684,0.003 4.864,2.192 4.862,4.878 -0.001,1.3 -0.509,2.521 -1.429,3.439 -0.918,0.916 -2.137,1.419 -3.435,1.419 -0.003,0 -0.007,0 -0.01,0 -1.3,0 -2.521,-0.507 -3.44,-1.427 -0.92,-0.922 -1.426,-2.147 -1.424,-3.451 0.004,-2.679 2.188,-4.858 4.871,-4.858 z"
        id="path2543"
      />
      <path
        fill={strokeColor}
        d="m 91.772846,71.707 -15.028,-35.155 5.12,-8.19 c 0.055,-0.088 0.1,-0.181 0.133,-0.276 1.751,-2.905 2.676,-6.196 2.676,-9.538 0.008,-4.949 -1.908,-9.603 -5.396,-13.104 -3.489,-3.503 -8.137,-5.437 -13.088,-5.444 -0.008,0 -0.015,0 -0.022,0 -10.2,0 -18.51,8.292 -18.526,18.496 0,3.393 0.928,6.703 2.687,9.59 0.028,0.071 0.062,0.141 0.103,0.208 l 2.779,4.667 h -35.551 c -0.595,0 -1.132,0.354 -1.365,0.901 L 0.1188459,71.707 c -0.195,0.458 -0.148,0.985 0.126,1.401 0.275,0.416 0.74,0.667 1.239,0.667 H 90.410846 c 0.499,0 0.964,-0.25 1.239,-0.667 0.272,-0.416 0.318,-0.943 0.123,-1.401 z m -38.786,-44.963 c -1.556,-2.468 -2.378,-5.32 -2.378,-8.245 0.015,-8.567 6.991,-15.529 15.557,-15.529 0.007,0 0.013,0 0.02,0 4.156,0.006 8.06,1.629 10.989,4.57 2.928,2.94 4.537,6.848 4.53,11.004 0,0.001 0,0.001 0,0.002 0,2.891 -0.827,5.741 -2.393,8.241 -0.054,0.084 -0.097,0.173 -0.13,0.265 l -13.335,21.33 -8.768,-14.724 c -0.011,-0.018 -0.02,-0.036 -0.032,-0.053 l -3.928,-6.596 c -0.034,-0.092 -0.078,-0.181 -0.132,-0.265 z m -34.351,9.186 h 3.383 c 0.212,4.235 3.843,5.83 6.52,6.998 1.405,0.613 2.858,1.246 3.314,2.024 0.72,1.228 0.255,2.083 -1.155,4.041 -1.289,1.789 -2.894,4.015 -1.796,6.934 1.702,4.526 10.53,5.742 20.208,6.678 1.757,0.169 3.273,0.316 4.225,0.478 7.474,1.269 9.984,5.506 10.802,7.726 H 3.7298459 Z m 48.618,34.876 c -0.676,-2.552 -3.356,-8.944 -13.423,-10.652 -1.056,-0.179 -2.621,-0.331 -4.435,-0.506 -5.363,-0.519 -16.523,-1.598 -17.715,-4.768 -0.512,-1.36 0.208,-2.464 1.425,-4.152 1.298,-1.8 3.074,-4.266 1.308,-7.279 -0.944,-1.611 -2.848,-2.441 -4.688,-3.244 -2.793,-1.217 -4.529,-2.153 -4.72,-4.276 h 29.97 l 9.565,16.062 c 0.265,0.445 0.742,0.72 1.26,0.725 0.005,0 0.011,0 0.016,0 0.512,0 0.987,-0.263 1.259,-0.697 l 7.752,-12.4 13.332,31.187 z"
        id="path2545"
      />
    </svg>
  );
};

export default MapSVG;
