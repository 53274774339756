import type { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import type { TBaseProps as Props } from 'types';

const InfoTagline = styled(Typography)`
  opacity: 0.5;
  font-size: 0.9rem;
  font-family: 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 1.2;
`;

const InfoTaglineTypography: FC<Props> = ({ style, children, align, color }) => {
  return (
    <InfoTagline style={style} align={align || 'right'} color={color}>
      {children}
    </InfoTagline>
  );
};

export default InfoTaglineTypography;
