import { gql } from '@apollo/client';

export const GET_PORTFOLIO_QUERY = gql`
  query Portfolio($userId: String, $cpIdBytes: String) {
    user(id: $userId) {
      wallets {
        id
        properties(where: { property_: { cp: $cpIdBytes } }) {
          amount
          property {
            id
            cp {
              id
            }
            token {
              name
              symbol
              totalSupply
            }
            tokenValuation
            propertyValuation
          }
        }
        pendingRevenue(where: { property_: { cp: $cpIdBytes } }) {
          property {
            id
            cp {
              id
            }
            token {
              symbol
            }
          }
          pendingRevenue
        }
      }
    }
  }
`;
