import { BankAccountTypes } from 'types/store';
import { backendAPI, i18nInstance } from 'utils';

export interface CountryResponse {
  name: string;
  code: string;
}

export const getBankAccountIdentifierName = (bankAccountType: BankAccountTypes) => {
  switch (bankAccountType) {
    case BankAccountTypes.IBAN:
      return i18nInstance.t('bank_bic');
    case BankAccountTypes.US:
      return i18nInstance.t('routing_number');
    case BankAccountTypes.UK:
      return i18nInstance.t('bank_sort_code');
    case BankAccountTypes.AU:
      return i18nInstance.t('bank_state_branch_number');
    default:
      return i18nInstance.t('bank_bic');
  }
};

export const getBankAccountTypeName = (bankAccountType: BankAccountTypes) => {
  switch (bankAccountType) {
    case BankAccountTypes.IBAN:
    case BankAccountTypes.UK:
      return 'IBAN';
    case BankAccountTypes.AU:
    case BankAccountTypes.US:
      return i18nInstance.t('bank_account_number');
    default:
      return 'IBAN';
  }
};

export function countryFetchRequest(countryCode: string): Promise<CountryResponse> {
  return new Promise((resolve, reject) => {
    backendAPI
      .get('/country', {
        params: {
          code: countryCode,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.length === 1) {
          resolve(data[0] as CountryResponse);
        } else {
          reject(new Error('country-fetch-empty-response'));
        }
      })
      .catch(() => {
        reject(new Error('country-fetch-failed'));
      });
  });
}
