import type { ChangeEvent, MouseEventHandler } from 'react';
import type { PortfolioPropertySortMethods, PortfolioRevenueData } from 'types';

export enum OrderBy {
  Desc = 'desc',
  Asc = 'asc',
}

export interface PropertyRevenuesForWalletProps {
  propertyRevenuesForSelectedWallet: Array<PortfolioRevenueData> | undefined;
  handlePropertyCheckmarkChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checkedPropertiesForSelectedWallet: Array<string>;
  handleSelectAndDeselectAll: (e: ChangeEvent<HTMLInputElement>) => void;
}

export interface PropertyListProps {
  propertyRevenuesForSelectedWallet: Array<PortfolioRevenueData>;
  checkedPropertiesForSelectedWallet: Array<string>;
  handlePropertyCheckmarkChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export interface ClaimRevenueButtonComponentProps {
  isClaiming: boolean;
  revenueClaimingError: string;
  handleClaimRevenue: () => void;
}
export type TOrder = OrderBy.Desc | OrderBy.Asc | undefined;

export type TableCellWithSortProps = {
  orderBy: string;
  order: TOrder;
  onSort: (id: string) => MouseEventHandler<HTMLSpanElement> | undefined;
  id: string;
  label: string;
};

export type PortfolioTableHeadProps = {
  onRequestSort: (event: ChangeEvent, property: PortfolioPropertySortMethods) => void;
  order: TOrder;
  orderBy: string;
  rowCount?: number;
};
