import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';
import type { Notification, NotificationState } from 'types/store';
import type { SnackbarKey } from 'notistack';

const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification(state, { payload }: PayloadAction<Notification>) {
      state.notifications = [...state.notifications, { ...payload }];
    },
    closeNotifications(state, { payload }: PayloadAction<{ dismissAll: boolean; key?: number }>) {
      const { dismissAll, key } = payload;
      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeNotification(state, { payload }: PayloadAction<{ key: SnackbarKey }>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload.key
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { addNotification, closeNotifications, removeNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
