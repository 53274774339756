/* eslint-disable import/prefer-default-export */
import { store } from 'store';
import { CurrencyTypes } from 'types/store';

export function getUserCurrency(): CurrencyTypes {
  const {
    currency: { selectedCurrency },
    company: { company },
  } = store.getState();

  // TODO: better fallback to default company currency
  return selectedCurrency[company] ?? CurrencyTypes.EUR;
}

export function getCurrencySymbol(currency: CurrencyTypes) {
  let symbol = '';
  switch (currency) {
    case CurrencyTypes.USD:
      symbol = '$';
      break;
    case CurrencyTypes.EUR:
      symbol = '€';
      break;
    case CurrencyTypes.GBP:
      symbol = '£';
      break;
    default:
      console.error('get-currency-symbol-invalid-currency-abbreviation');
  }

  return symbol;
}

export function toFiatCurrency(
  daiValue: number,
  overrideCurrency: CurrencyTypes | null = null,
  usePeggedDaiToUsd = false
): [number, CurrencyTypes] {
  const {
    currency: { daiToFiatRates, usdToOtherRates },
  } = store.getState();

  let tempCurrency = getUserCurrency();
  if (overrideCurrency) tempCurrency = overrideCurrency;

  if (daiValue === 0) return [0, tempCurrency];

  // If we're using the pegged value, foolishly assume that 1 DAI === 1 $ and
  // use the exchange rates from USD to other fiat currencies
  let currentRate = 0;
  if (usePeggedDaiToUsd) {
    currentRate = usdToOtherRates[tempCurrency.toUpperCase()];
  } else {
    currentRate = daiToFiatRates[tempCurrency.toUpperCase()];
  }

  return [daiValue * currentRate, tempCurrency];
}

export function formatDaiToFiatString(
  daiValue: number,
  digits = 0,
  overrideCurrency: CurrencyTypes | null = null,
  usePeggedDaiToUsd = false
): string {
  const [value, currency] = toFiatCurrency(daiValue, overrideCurrency, usePeggedDaiToUsd);

  const symbol = getCurrencySymbol(currency);

  if (currency === CurrencyTypes.USD) {
    return `${symbol}${value.toLocaleString('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })}`;
  }

  return `${value.toLocaleString('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })}${symbol}`;
}

// Gives back the fiat currency symbol and the
// exchange rate from USD/DAI based on input
export function getFiatCurrencyData(
  currency: CurrencyTypes,
  usePeggedDaiToUsd: boolean
): [number, string] {
  const {
    currency: { daiToFiatRates, usdToOtherRates },
  } = store.getState();

  // Pre-Sale properties mostly use peggedDaiToUsd, however, there might be situations
  // where we will use pegged {{stablecoin}} to {{matchingFiatCurrency}} if a CP decides
  // to use another stablecoin
  let conversionRate = 0;
  if (usePeggedDaiToUsd) {
    conversionRate = usdToOtherRates[currency.toUpperCase()];
  } else {
    conversionRate = daiToFiatRates[currency.toUpperCase()];
  }

  const symbol = getCurrencySymbol(currency);

  return [conversionRate, symbol];
}
