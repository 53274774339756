import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backendAPI, i18nInstance } from 'utils';
import { createNotification } from 'utils/notification';
import type { TinitialState } from 'types/store/proForma';
import { updateTransactionStatus } from 'store/userBankTransactions';
import { genericErrorReasons } from 'types/api';

const initialState: TinitialState = {
  data: undefined,
  meta: {
    invoice: {
      loading: false,
      loaded: true,
      error: null,
    },
    paymentConfirmation: {
      invoiceId: '',
      loading: false,
      loaded: true,
      error: null,
    },
  },
  confirmation: false,
};

const proFormaId = localStorage.getItem('proFormaId');

const proFormaSlice = createSlice({
  name: 'proForma',
  initialState,
  reducers: {
    fetchProFormaPending(state) {
      state.meta.invoice = { ...state.meta.invoice, loading: true, loaded: false };
    },
    fetchProFormaSuccess(state, action) {
      state.data = action.payload;
      state.meta.invoice = { ...state.meta.invoice, loaded: true, loading: false };
    },
    fetchProFormaRejected(state, action) {
      state.meta.invoice = {
        ...state.meta.invoice,
        loaded: false,
        loading: false,
        error: action.payload,
      };
    },
    confirmPaymentPending(state, { payload }: PayloadAction<{ invoiceId: string }>) {
      state.meta.paymentConfirmation = {
        ...state.meta.paymentConfirmation,
        invoiceId: payload.invoiceId || '',
        loading: true,
        loaded: false,
      };
    },
    confirmPaymentSuccess(state, action) {
      if (state.data) state.data = { ...state.data, status: action.payload };
      state.confirmation = true;
      state.meta.paymentConfirmation = {
        ...state.meta.paymentConfirmation,
        loading: false,
        loaded: true,
      };
    },
    confirmPaymentRejected(state, action) {
      state.meta.paymentConfirmation = {
        ...state.meta.paymentConfirmation,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    },
  },
});

// TODO: CONVERT TO thunk
export function fetchProFormaData() {
  return async (dispatch) => {
    dispatch(fetchProFormaPending());

    try {
      const response = await backendAPI.get(`/initial-sale/investment/${proFormaId}`);

      if (response.data) {
        dispatch(fetchProFormaSuccess(response.data));
        return response.data;
      } else {
        dispatch(createNotification(i18nInstance.t('pro_forma_fetching_error'), 'error'));

        const noDataError = new Error('No data available');
        dispatch(fetchProFormaRejected(noDataError));
        console.error(noDataError);
      }
    } catch (error) {
      dispatch(createNotification(i18nInstance.t('pro_forma_fetching_error'), 'error'));
      dispatch(fetchProFormaRejected(error));
      console.error(error);
    }
  };
}

export function confirmPayment(invoiceId: string) {
  return async (dispatch) => {
    dispatch(confirmPaymentPending({ invoiceId }));

    return backendAPI
      .post(`/initial-sale/investment/${invoiceId}/confirm`)
      .then(({ data }) => {
        if (data.status && data.status !== 'FAILURE' && data.status === 'SUCCESS') {
          dispatch(confirmPaymentSuccess('PAYED'));
          dispatch(updateTransactionStatus({ transactionHash: invoiceId, txStatus: 'PAYED' }));
        } else {
          const proFormaConfirmPaymentError = i18nInstance.t('pro_forma_confirm_payment_error');
          dispatch(createNotification(proFormaConfirmPaymentError, 'error'));
          dispatch(confirmPaymentRejected(proFormaConfirmPaymentError));
        }
      })
      .catch((error) => {
        console.error('pro-forma-payment-confirmation-error', error);
        const networkError = i18nInstance.t(genericErrorReasons.network_error);
        dispatch(confirmPaymentRejected(networkError));
        dispatch(createNotification(networkError, 'error'));
      });
  };
}

export const {
  fetchProFormaRejected,
  fetchProFormaPending,
  fetchProFormaSuccess,
  confirmPaymentRejected,
  confirmPaymentSuccess,
  confirmPaymentPending,
} = proFormaSlice.actions;

export default proFormaSlice.reducer;
