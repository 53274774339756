import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const LayersSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 59.844 55.938"
    >
      <path
        d="M59.318,16.852 L30.392,31.843 C30.248,31.917 30.091,31.954 29.934,31.954 C29.776,31.954 29.619,31.917 29.475,31.843 L0.553,16.852 C0.222,16.681 0.014,16.338 0.014,15.964 C0.014,15.590 0.222,15.248 0.553,15.076 L29.479,0.086 C29.767,-0.063 30.109,-0.063 30.396,0.086 L59.318,15.076 C59.649,15.248 59.857,15.590 59.857,15.964 C59.857,16.338 59.649,16.681 59.318,16.852 ZM29.938,2.099 L3.184,15.964 L29.934,29.829 L56.689,15.964 L29.938,2.099 ZM0.625,26.015 L4.560,24.063 C5.054,23.818 5.652,24.021 5.896,24.515 C6.141,25.009 5.939,25.609 5.445,25.854 L3.165,26.985 L29.938,41.805 L56.710,26.985 L54.430,25.854 C53.936,25.609 53.734,25.009 53.978,24.515 C54.223,24.022 54.821,23.819 55.315,24.063 L59.280,26.030 C59.545,26.153 59.749,26.390 59.826,26.686 C59.939,27.128 59.742,27.590 59.344,27.810 L30.420,43.821 C30.270,43.904 30.104,43.946 29.938,43.946 C29.772,43.946 29.606,43.904 29.455,43.821 L0.531,27.810 C0.133,27.590 -0.066,27.128 0.048,26.686 C0.127,26.377 0.345,26.133 0.625,26.015 ZM0.625,38.008 L4.560,36.055 C5.054,35.810 5.652,36.013 5.896,36.508 C6.141,37.002 5.939,37.602 5.445,37.847 L3.165,38.978 L29.938,53.797 L56.710,38.978 L54.430,37.847 C53.936,37.602 53.734,37.002 53.978,36.508 C54.223,36.013 54.821,35.810 55.315,36.055 L59.280,38.022 C59.545,38.145 59.749,38.382 59.826,38.678 C59.939,39.120 59.742,39.582 59.344,39.803 L30.420,55.814 C30.270,55.897 30.104,55.939 29.938,55.939 C29.772,55.939 29.606,55.897 29.455,55.814 L0.531,39.803 C0.133,39.582 -0.066,39.120 0.048,38.678 C0.127,38.370 0.345,38.126 0.625,38.008 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default LayersSVG;
