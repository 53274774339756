import { Suspense, lazy, useEffect } from 'react';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import CustomHead from 'components/custom/CustomHead';

import generateTheme, { fallbackTheme } from './theme';
import { useAppSelector } from 'types';
import RoutesHandler from 'components/routes/RoutesHandler';
import { changeI18nInstanceLanguage } from 'store/language';

const NotificationWrapper = lazy(() => import('components/notification/NotificationWrapper'));

function App() {
  const { theme: companyTheme, defaultLanguage } = useAppSelector(
    (store) => store.company.configuration
  );
  const cpAvailableLanguages = useAppSelector((store) => store.company.languages);
  const preferredUserLanguage = useAppSelector((store) => store.user.preferredLanguage);

  // Primary place for initial app load language handling
  useEffect(() => {
    if (!preferredUserLanguage) {
      const defaultLangToApply = defaultLanguage ? defaultLanguage : 'en';
      changeI18nInstanceLanguage(defaultLangToApply);
    } else if (preferredUserLanguage) {
      const preferredUserLangSupportedByCP = cpAvailableLanguages.some(
        (languageData) => languageData.countryCode === preferredUserLanguage
      );
      const langToApply = preferredUserLangSupportedByCP ? preferredUserLanguage : 'en';
      changeI18nInstanceLanguage(langToApply);
    }
  }, [preferredUserLanguage, defaultLanguage, cpAvailableLanguages]);

  const isThemeReady = Boolean(companyTheme?.identifier);

  const currentTheme = companyTheme
    ? generateTheme(companyTheme.identifier, companyTheme.brandColor)
    : fallbackTheme;

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={currentTheme}>
        <ThemeProvider theme={currentTheme}>
          {isThemeReady ? <CustomHead /> : null}
          <Suspense>
            <NotificationWrapper />
          </Suspense>
          <RoutesHandler />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
