import type { FC } from 'react';
import type { TBaseIconProps } from 'types';

const LockSVG: FC<TBaseIconProps> = ({ fillColor, height = '100%', width = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      viewBox="0 0 40 48"
    >
      <path
        d="M37.000,48.000 L3.000,48.000 C1.346,48.000 0.000,46.654 0.000,45.000 L0.000,19.000 C0.000,17.346 1.346,16.000 3.000,16.000 L8.000,16.000 L8.000,12.000 C8.000,5.383 13.383,0.000 20.000,0.000 C26.617,0.000 32.000,5.383 32.000,12.000 L32.000,16.000 L37.000,16.000 C38.654,16.000 40.000,17.346 40.000,19.000 L40.000,45.000 C40.000,46.654 38.654,48.000 37.000,48.000 ZM20.000,2.000 C14.486,2.000 10.000,6.486 10.000,12.000 L10.000,16.000 L30.000,16.000 L30.000,12.000 C30.000,6.486 25.514,2.000 20.000,2.000 ZM38.000,19.000 C38.000,18.448 37.552,18.000 37.000,18.000 L3.000,18.000 C2.448,18.000 2.000,18.448 2.000,19.000 L2.000,45.000 C2.000,45.552 2.448,46.000 3.000,46.000 L37.000,46.000 C37.552,46.000 38.000,45.552 38.000,45.000 L38.000,19.000 ZM21.000,31.816 L21.000,37.000 C21.000,37.553 20.553,38.000 20.000,38.000 C19.447,38.000 19.000,37.553 19.000,37.000 L19.000,31.816 C17.839,31.402 17.000,30.302 17.000,29.000 C17.000,27.346 18.346,26.000 20.000,26.000 C21.654,26.000 23.000,27.346 23.000,29.000 C23.000,30.302 22.161,31.402 21.000,31.816 ZM20.000,28.000 C19.448,28.000 19.000,28.448 19.000,29.000 C19.000,29.552 19.448,30.000 20.000,30.000 C20.552,30.000 21.000,29.552 21.000,29.000 C21.000,28.448 20.552,28.000 20.000,28.000 Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default LockSVG;
