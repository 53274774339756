import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createNotification } from 'utils/notification';
import { gqlclient, GET_PORTFOLIO_QUERY } from 'query';
import { RESET_STATE } from './sharedActions';
import { formatBigInt, i18nInstance } from 'utils';
import type {
  PortfolioWallet,
  RawPortfolioQueryRes,
  ReformattedRevenueInfoEntry,
  PerWalletRevenue,
  PortfolioState,
  AdaptedPortfolioProperty,
} from 'types';
import { PortfolioQueryRes } from 'types';
import { getCurrentPropertyUserTokenValuation } from 'utils/format';

const initialState: PortfolioState = {
  pendingRevenue: 0,
  perWalletRevenues: [],
  isRequestingProperties: false,
  errorMessageProperties: null,
  portfolioQueryRes: null,
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    portfolioPropertiesFetchRequest(state) {
      state.isRequestingProperties = true;
    },
    portfolioPropertiesFetchSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        portfolioQueryRes: PortfolioQueryRes;
        pendingRevenue: number;
        perWalletRevenues: Array<PerWalletRevenue>;
      }>
    ) {
      const { portfolioQueryRes, pendingRevenue, perWalletRevenues } = payload;
      state.portfolioQueryRes = portfolioQueryRes;
      state.pendingRevenue = pendingRevenue;
      state.perWalletRevenues = perWalletRevenues;
      state.isRequestingProperties = false;
      state.errorMessageProperties = null;
    },
    portfolioPropertiesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingProperties = false;
      state.errorMessageProperties = reason;
    },
    updateStoreClaimedRevenueEntries(
      state,
      {
        payload,
      }: PayloadAction<{
        selectedWallet: string;
        claimedProperties: Array<string>;
      }>
    ) {
      const { selectedWallet, claimedProperties } = payload;

      const indexOfRevenuesForSelectedWallet = state.perWalletRevenues.findIndex(
        (perWalletRevenue) => {
          return perWalletRevenue.address === selectedWallet;
        }
      );

      if (indexOfRevenuesForSelectedWallet >= 0) {
        const revenuesForSelectedWallet = state.perWalletRevenues[indexOfRevenuesForSelectedWallet];

        if (revenuesForSelectedWallet) {
          const claimedAmount = revenuesForSelectedWallet.revenues.reduce(
            (accumulator, revenueEntry) => {
              if (claimedProperties.indexOf(revenueEntry.property.id) >= 0) {
                return accumulator + formatBigInt(revenueEntry.pendingRevenue);
              }

              return accumulator;
            },
            0
          );

          const remainingPendingRevenue = state.pendingRevenue - claimedAmount;

          // Due to rounding errors that 'formatBigInt' can make, we have to make sure we don't
          // go below 0.
          state.pendingRevenue = remainingPendingRevenue < 0 ? 0 : remainingPendingRevenue;
          state.perWalletRevenues[indexOfRevenuesForSelectedWallet].revenues =
            revenuesForSelectedWallet.revenues.filter((revenue) => {
              return claimedProperties.indexOf(revenue.property.id) === -1;
            });
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

// TODO: CONVERT TO thunk
export function getPortfolioOnChainProperties(userId: string, cpIdBytes: string) {
  return async (dispatch) => {
    dispatch(portfolioPropertiesFetchRequest());
    return gqlclient
      .query<RawPortfolioQueryRes>({
        query: GET_PORTFOLIO_QUERY,
        variables: {
          userId: userId.toLowerCase(),
          cpIdBytes: cpIdBytes.toLowerCase(),
        },
      })
      .then((result) => {
        const { data: rawData } = result;
        const portfolioQueryRes = new PortfolioQueryRes(rawData);

        const {
          user: { wallets },
        } = portfolioQueryRes;

        const allPropertyRevenues = groupPendingRevenuesByProperty(wallets);
        const totalPendingRevenue = extractTotalPendingRevenue(allPropertyRevenues);
        const perWalletRevenues = extractAllRevenuesPerWallet(wallets);

        dispatch(
          portfolioPropertiesFetchSuccess({
            portfolioQueryRes,
            pendingRevenue: totalPendingRevenue,
            perWalletRevenues,
          })
        );
      })
      .catch((error) => {
        dispatch(portfolioPropertiesFetchFailure({ reason: error.message }));

        // We don't show a notification if the 'user' or 'wallet' keys are falsy
        // due to the case where a freshly signed up user would receive errors upon
        // visiting their portfolio page until they get their wallet and user id data added onto
        // the blockchain for the subgraph to pick up.
        if (error.message !== 'improper-query-response-structure') {
          dispatch(createNotification(i18nInstance.t('portfolio_setup_error'), 'error'));
        }
      });
  };
}

function extractAllRevenuesPerWallet(wallets: Array<PortfolioWallet>): Array<PerWalletRevenue> {
  const allRevenuesPerWallet: Array<PerWalletRevenue> = [];
  wallets.forEach((wallet) => {
    if (wallet.pendingRevenue.length) {
      allRevenuesPerWallet.push({ address: wallet.id, revenues: [...wallet.pendingRevenue] });
    }
  });
  return allRevenuesPerWallet;
}

/**
 *
 * We need this function in order to transform revenues grouped by wallet to revenues grouped by
 * property with nested wallets and how much rent they have individually.
 *
 * Based on the new methodology of claiming revenues, it won't see much use yet, but might
 * become more useful later.
 *
 * @returns Reformatted array of revenues grouped by property instead of by wallet
 */
function groupPendingRevenuesByProperty(
  wallets: Array<PortfolioWallet>
): Record<string, ReformattedRevenueInfoEntry> {
  const reformattedRevenueInfo: Record<string, ReformattedRevenueInfoEntry> = {};

  wallets.forEach((wallet) => {
    const { id: walletAddress, pendingRevenue: fetchedPendingRevenues } = wallet;

    fetchedPendingRevenues.forEach((fetchedRevenueEntry) => {
      const {
        property: { id: fetchedPropertyId },
      } = fetchedRevenueEntry;

      const recordExists = Boolean(reformattedRevenueInfo[fetchedPropertyId]?.propertyId);

      if (recordExists) {
        reformattedRevenueInfo[fetchedPropertyId].perWalletAmounts.push({
          walletAddress,
          amount: fetchedRevenueEntry.pendingRevenue,
        });

        const { pendingRevenue: existingPendingRevenue } =
          reformattedRevenueInfo[fetchedPropertyId];

        reformattedRevenueInfo[fetchedPropertyId].pendingRevenue =
          existingPendingRevenue + fetchedRevenueEntry.pendingRevenue;
      } else {
        reformattedRevenueInfo[fetchedPropertyId] = {
          propertyId: fetchedRevenueEntry.property.id,
          perWalletAmounts: [
            {
              walletAddress,
              amount: fetchedRevenueEntry.pendingRevenue,
            },
          ],
          pendingRevenue: fetchedRevenueEntry.pendingRevenue,
        };
      }
    });
  });

  return reformattedRevenueInfo;
}

function extractTotalPendingRevenue(
  allPropertyRevenues: Record<string, ReformattedRevenueInfoEntry>
): number {
  let totalRevenue = 0;
  for (const propertyRevenue in allPropertyRevenues) {
    totalRevenue += formatBigInt(allPropertyRevenues[propertyRevenue].pendingRevenue);
  }

  return totalRevenue;
}

export function generatePortfolioPropertyData(portfolioWallets: Array<PortfolioWallet>) {
  const allPropertyRevenues = groupPendingRevenuesByProperty(portfolioWallets);

  const tempProperties: AdaptedPortfolioProperty[] = [];
  portfolioWallets.forEach((tempWallet) => {
    const { properties } = tempWallet;

    properties.forEach((propertyWallet) => {
      const {
        amount,
        property: {
          id,
          tokenValuation,
          propertyValuation,
          token: { totalSupply, name, symbol },
          cp: { id: cpIdBytes },
        },
      } = propertyWallet;

      const tempPropertyIndex = tempProperties.findIndex((e) => e.id === id);
      const tempPropertyAmount =
        tempPropertyIndex >= 0 ? tempProperties[tempPropertyIndex].amount : 0;

      const tempAmount = formatBigInt(amount) + tempPropertyAmount;
      const tempTokenValuation = formatBigInt(tokenValuation);
      const tempPropertyValuation = formatBigInt(propertyValuation);
      const tempTotalSupply = formatBigInt(totalSupply);

      const valuation = getCurrentPropertyUserTokenValuation(
        tempTokenValuation,
        tempPropertyValuation,
        tempTotalSupply,
        tempAmount
      );

      const revenueInfo = allPropertyRevenues[id] || null;

      const propertyData = {
        id: id,
        amount: tempAmount,
        valuation: valuation,
        companyId: cpIdBytes,
        revenueInfo,
        name: name,
        symbol: symbol,
      };

      if (tempPropertyIndex >= 0) {
        tempProperties[tempPropertyIndex] = propertyData;
      } else {
        tempProperties.push(propertyData);
      }
    });
  });

  return tempProperties;
}

export const {
  portfolioPropertiesFetchFailure,
  portfolioPropertiesFetchRequest,
  portfolioPropertiesFetchSuccess,
  updateStoreClaimedRevenueEntries,
} = portfolioSlice.actions;
export default portfolioSlice.reducer;
