import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Grid, Backdrop } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as CarouselEl } from 'react-responsive-carousel';
import { useAppSelector } from 'types';
import { CloseSVG as CloseSVGIcon, PhotoSVG } from 'components/icons';
import { propertyDataSelector } from 'store/selectors/properties';
import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import BlockLayout from 'components/layout/BlockLayout';
import { useTranslation } from 'react-i18next';

type BigImagePreviewType = {
  imageIndex: number;
  images: {
    original: string;
    thumbnail: string;
  }[];
  closeImagePreview: () => void;
};

const GalleryGrid = styled(Grid)`
  width: 100%;
  position: relative;
  flex: 1.2;
  flex-basis: auto;
  min-height: 0;
  max-height: 50rem;

  ${(props) => props.theme.breakpoints.down('md')} {
    max-height: 35rem;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 20rem;
  }
`;

const GoogleMapReactWrapper = styled.div`
  height: auto;
  width: 100%;
  border-radius: 0.6rem;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 20rem;
  }
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
`;

const Carousel = styled(CarouselEl)`
  height: 100%;
  cursor: pointer;
  width: 100%;
  border-radius: 0.6rem;
  overflow: hidden;

  & .carousel-slider {
    height: 100%;

    & .slider-wrapper {
      height: 100% !important;

      & ul .slide {
        height: 100%;
      }

      & ul {
        height: 100%;
      }
    }
  }
`;

const CloseSVG = styled(CloseSVGIcon)`
  position: absolute;
  top: 3vh;
  right: 3vw;
  cursor: pointer;
`;

const handleApiLoaded = (map, maps, place) => {
  return new maps.Marker({
    position: {
      lat: place.lat,
      lng: place.lng,
    },
    map,
  });
};

const BigImagePreview = ({ imageIndex, images, closeImagePreview }: BigImagePreviewType) => {
  if (imageIndex >= 0) {
    return (
      <Backdrop open={imageIndex >= 0} style={{ zIndex: '7' }} onClick={closeImagePreview}>
        <CloseSVG height="20px" width="20px" fillColor="white" onClick={closeImagePreview} />
        <img
          style={{ maxWidth: '75vw', maxHeight: '75vh' }}
          src={images[imageIndex].original}
          alt="Preview of the property"
        />
      </Backdrop>
    );
  }

  return null;
};

const PropertyLocation = () => {
  const { t } = useTranslation();

  const [imagePreviewIndex, setImagePreviewIndex] = useState(-1);

  const { latitude, longitude, images: rawImages } = useAppSelector(propertyDataSelector);
  const isRequestingProperty = useAppSelector((state) => state.properties.isRequestingProperty);

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const onImageClick = (index: number) => setImagePreviewIndex(index);

  const closeImagePreview = () => setImagePreviewIndex(-1);

  const images = useMemo(
    () => rawImages.map((image) => ({ original: image, thumbnail: image })),
    [rawImages]
  );

  return (
    <Grid container item direction="column" spacing={4}>
      <GalleryGrid container item xs>
        {images.length ? (
          <Carousel
            showArrows
            infiniteLoop
            swipeable
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            emulateTouch
            onClickItem={onImageClick}
            autoPlay
            interval={5000}
          >
            {images.map((item) => {
              return <Image key={`slide-${item.original}`} src={item.original} />;
            })}
          </Carousel>
        ) : (
          <BlockLayout variant="secondary">
            <ZeroItemsLayout
              Icon={PhotoSVG}
              size={7}
              desc={t('marketplace_no_photos')}
              hint={t('marketplace_table_empty_hint')}
            />
          </BlockLayout>
        )}
      </GalleryGrid>
      <Grid container item xs>
        <Grid container item spacing={4}>
          <Grid container item sm={12} md>
            <GoogleMapReactWrapper>
              {isRequestingProperty ? null : (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
                  }}
                  defaultCenter={center}
                  defaultZoom={15}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, center)}
                />
              )}
            </GoogleMapReactWrapper>
          </Grid>

          <Grid container item sm={12} md>
            <GoogleMapReactWrapper>
              {isRequestingProperty ? null : (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
                  }}
                  defaultCenter={center}
                  defaultZoom={5}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, center)}
                />
              )}
            </GoogleMapReactWrapper>
          </Grid>
        </Grid>
      </Grid>
      <BigImagePreview
        images={images}
        imageIndex={imagePreviewIndex}
        closeImagePreview={closeImagePreview}
      />
    </Grid>
  );
};

export default PropertyLocation;
