import type { TransactionReceipt, TransactionResponse } from 'ethers';
import { Contract } from 'ethers';
import { getProvider } from './web3';
import { RevenueDistributionAbi } from 'contracts';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import type { PropertyStatisticsRevenueDistributions, RevDistChartDataFormat } from 'types';

dayjs.extend(quarterOfYear);

export async function getPendingRevenue(
  propertyTokenAddress: string,
  userAddress: string
): Promise<bigint> {
  const provider = getProvider();

  const revenueContract = new Contract(
    process.env.REACT_APP_REVENUE_DISTRIBUTION_ADDRESS as string,
    RevenueDistributionAbi,
    provider
  );

  const result = (await revenueContract.pendingRevenue(
    propertyTokenAddress,
    userAddress
  )) as bigint;
  return result;
}

export async function getAverageMonthlyPayout(propertyTokenAddress: string): Promise<bigint> {
  const provider = getProvider();

  const revenueContract = new Contract(
    process.env.REACT_APP_REVENUE_DISTRIBUTION_ADDRESS as string,
    RevenueDistributionAbi,
    provider
  );

  const result = (await revenueContract.getAverageMonthlyPayout(propertyTokenAddress)) as bigint;
  return result;
}

export async function claimRevenueForToken(
  propertyTokenAddress: string
): Promise<TransactionReceipt | null> {
  const provider = getProvider();
  const signer = await provider.getSigner();

  const revenueContract = new Contract(
    process.env.REACT_APP_REVENUE_DISTRIBUTION_ADDRESS as string,
    RevenueDistributionAbi,
    signer
  );

  const transaction = (await revenueContract.claimRevenueForToken(
    propertyTokenAddress
  )) as TransactionResponse;
  return transaction.wait();
}

export async function claimRevenueForMultipleProperties(
  selectedWallet: string,
  propertyTokenAddresses: Array<string>
): Promise<TransactionReceipt | null> {
  const provider = getProvider();
  const signer = await provider.getSigner();

  const revenueContract = new Contract(
    process.env.REACT_APP_REVENUE_DISTRIBUTION_ADDRESS as string,
    RevenueDistributionAbi,
    signer
  );

  const transaction = (await revenueContract.claimRevenuesForWalletForMultipleProperties(
    selectedWallet,
    propertyTokenAddresses
  )) as TransactionResponse;

  return transaction.wait();
}

// Chart utils

/**
 * Adapt the revenue distribution chart data so that revenues are arranged by month instead of by event.
 *
 * @param {PropertyStatisticsRevenueDistributions[]} revenueDistributions - The array of revenue distributions.
 * @return {void} This function does not return a value.
 */
export const adaptRevenueDistributionChartData = (
  revenueDistributions: PropertyStatisticsRevenueDistributions[]
) => {
  const revenueDistributionEventsWithMonths = revenueDistributions.map(
    (revenueDistributionEvent) => {
      const { amount, from, to } = revenueDistributionEvent;
      return arrangeRevenuePerMonth(amount, from, to);
    }
  );

  const flattenedRevenueDistributionsByMonth = flattenRevenueEventsByMonth(
    revenueDistributionEventsWithMonths
  );

  // Remove any entries where the distributed revenue is 0
  const cleanedArray = flattenedRevenueDistributionsByMonth.filter(
    (obj) => obj.revenueDistributed !== 0
  );

  return cleanedArray;
};

/**
 *
 * @param revenueDistributed - How much revenue was distributed in this distribution event
 * @param from - What is the start date of the distribution period
 * @param to - What is the end date of the distribution period
 *
 * @returns The proportional revenue distributed for each month in the distribution period
 *
 * A revenue distribution event can be created for a period spanning months if not years, so this functions
 * neatly groups the distributed amount into months for the time span/period
 */
export const arrangeRevenuePerMonth = (revenueDistributed: number, from: string, to: string) => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  const numberOfMonths = monthsBetween(fromDate, toDate);

  // In case the currently observed revenue was distributed for a period within a single month,
  // we just return it back in our preferred format
  if (numberOfMonths === 0) {
    return [{ period: fromDate.format('MMM YYYY'), revenueDistributed: revenueDistributed }];
  }

  const months: Array<RevDistChartDataFormat> = [];

  const dayCount = toDate.diff(fromDate, 'day');
  const dailyRevenue = revenueDistributed / dayCount;

  let currentMonth = dayjs(fromDate);
  for (let i = 0; i <= numberOfMonths; i++) {
    const totalDaysInMonth = dayjs(currentMonth).daysInMonth();

    let daysLeftInMonth: number = dayjs(currentMonth).daysInMonth();
    if (i === 0) {
      daysLeftInMonth = totalDaysInMonth - fromDate.date();
    } else if (i === numberOfMonths) {
      daysLeftInMonth = toDate.date();
    }

    const revenueForCurrentMonth = dailyRevenue * daysLeftInMonth;

    months.push({
      period: currentMonth.format('MMM YYYY'),
      revenueDistributed: revenueForCurrentMonth,
    });

    currentMonth = currentMonth.add(1, 'month');
  }

  return months;
};

/**
 *
 * @param startDate - Start date wrapped in dayjs
 * @param endDate - End date wrapped in dayjs
 * @returns number of months that elapsed between the specified periods
 */
function monthsBetween(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
  let yearsDiff = endDate.year() - startDate.year();
  let monthsDiff = endDate.month() - startDate.month();

  // Handle negative month difference (second date in same or earlier month)
  if (monthsDiff < 0) {
    yearsDiff -= 1;
    monthsDiff += 12;
  }

  return yearsDiff * 12 + monthsDiff;
}

/**
 * Flattens revenue distribution amounts by month.
 *
 * The revenue from each event passed to this function should be arranged into the months between 'from' and 'to'.
 * This function then flattens the revenue from all the events into months ( e.g. different events that have overlapping periods of
 * distribution will be summed up into same months ).
 */
function flattenRevenueEventsByMonth(
  revenueDistributionEvents: Array<Array<RevDistChartDataFormat>>
) {
  return revenueDistributionEvents.reduce((accumulatedArray, curr) => {
    curr.forEach((revenuePerMonth) => {
      const existingMonthIndex = accumulatedArray.findIndex(
        (obj) => obj.period === revenuePerMonth.period
      );

      if (existingMonthIndex !== -1) {
        accumulatedArray[existingMonthIndex].revenueDistributed +=
          revenuePerMonth.revenueDistributed;
      } else {
        accumulatedArray.push({
          period: revenuePerMonth.period,
          revenueDistributed: revenuePerMonth.revenueDistributed,
        });
      }
    });

    return accumulatedArray;
  }, []);
}

/**
 *
 * Takes the revenue distributions grouped by month and arranges them into quarters
 *
 * @param adaptedRevenueChartDataPerMonth - An array of revenue distribution amounts arranged by month
 * @returns - An array of revenue distribution amounts arranged by quarter
 */
export function rearrangeRevenuesIntoQuarters(
  adaptedRevenueChartDataPerMonth: Array<RevDistChartDataFormat>
) {
  const quarterlyRevenueSum: Array<RevDistChartDataFormat> = [];

  adaptedRevenueChartDataPerMonth.forEach(({ period, revenueDistributed }) => {
    const wrappedDayjsMonth = dayjs(period, 'MMM YYYY');
    const quarter = wrappedDayjsMonth.quarter();

    const formattedQuarter = `Q${quarter} ${wrappedDayjsMonth.format('YYYY')}`;

    const existingQuarterIndex = quarterlyRevenueSum.findIndex(
      (obj) => obj.period === formattedQuarter
    );

    if (existingQuarterIndex !== -1) {
      quarterlyRevenueSum[existingQuarterIndex].revenueDistributed += revenueDistributed;
    } else {
      quarterlyRevenueSum.push({
        period: formattedQuarter,
        revenueDistributed: revenueDistributed,
      });
    }
  });

  return quarterlyRevenueSum;
}

/**
 *
 * Takes the revenue distributions grouped by month and arranges them into years
 *
 * @param adaptedRevenueChartDataPerMonth - An array of revenue distribution amounts arranged by month
 * @returns - An array of revenue distribution amounts arranged by year
 */
export function rearrangeRevenuesIntoYears(
  adaptedRevenueChartDataPerMonth: Array<RevDistChartDataFormat>
) {
  const yearlyRevenueSum: Array<RevDistChartDataFormat> = [];

  adaptedRevenueChartDataPerMonth.forEach(({ period, revenueDistributed }) => {
    const wrappedDayjsMonth = dayjs(period, 'MMM YYYY');

    const formattedYear = `${wrappedDayjsMonth.format('YYYY')}`;

    const existingYearIndex = yearlyRevenueSum.findIndex((obj) => obj.period === formattedYear);

    if (existingYearIndex !== -1) {
      yearlyRevenueSum[existingYearIndex].revenueDistributed += revenueDistributed;
    } else {
      yearlyRevenueSum.push({
        period: formattedYear,
        revenueDistributed: revenueDistributed,
      });
    }
  });

  return yearlyRevenueSum;
}
