import type { FC } from 'react';
import type { TBaseIconProps } from 'types/icons';
const CheckSVG: FC<TBaseIconProps & { className?: string }> = ({
  fillColor,
  height = '100%',
  width = '100%',
  style,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style ? style : undefined}
      className={className}
      viewBox="0 0 60 60"
    >
      <path
        d="M30 60C13.458 60 0 46.542 0 30S13.458 0 30 0s30 13.458 30 30-13.458 30-30 30zm0-58C14.561 2 2 14.561 2 30c0 15.44 12.561 28 28 28s28-12.56 28-28C58 14.561 45.439 2 30 2zm-2.856 38.681a1 1 0 01-.71.319h-.022a.999.999 0 01-.702-.288l-11.412-11.25a1 1 0 111.404-1.424l10.679 10.527 17.887-19.246a1 1 0 011.464 1.362l-18.588 20z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CheckSVG;
