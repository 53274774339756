import { lazy } from 'react';
import type { CustomRouteWrapper } from 'types';

// Home pages
const Welcome = () => lazy(() => import('pages/home/Welcome'));

// Portfolio pages
const PortfolioOverview = lazy(() => import('pages/portfolio/Overview'));

// Marketplace pages
const MarketplaceOverview = lazy(() => import('pages/marketplace/Overview'));
const PropertyPage = lazy(() => import('pages/marketplace/Property'));

// Offering pages
const OfferingOverview = lazy(() => import('pages/offering/Overview'));

// Account pages
const Settings = lazy(() => import('pages/account/Settings'));
const Wallets = lazy(() => import('pages/account/Wallets'));
// const Funding = lazy(() => import('pages/account/Funding'));
const History = lazy(() => import('pages/account/History'));
const OfferingsHistory = lazy(() => import('pages/account/Offerings'));
const Orders = lazy(() => import('pages/account/Orders'));

const homeRoutes: CustomRouteWrapper = {
  id: 'home',
  path: '/',
  containsHome: true,
  component: Welcome(),
};

const portfolioRoutes: CustomRouteWrapper = {
  id: 'portfolio',
  path: '/portfolio',
  component: PortfolioOverview,
  /* disabledWithoutWallet: true, */
};

const marketplaceRoutes: CustomRouteWrapper = {
  id: 'marketplace',
  path: '/marketplace',
  component: MarketplaceOverview,
  children: [
    {
      id: 'MarketplacePropertyInfo',
      path: '/marketplace/property/:propertyAddress',
      name: 'PropertyInfo',
      component: PropertyPage,
      hideInSidebar: true,
    },
  ],
};

const offeringRoutes: CustomRouteWrapper = {
  id: 'offerings',
  path: '/offerings',
  component: OfferingOverview,
};

const accountRoutes: CustomRouteWrapper = {
  id: 'account',
  path: '/account',
  component: Settings,
  children: [
    {
      id: 'account_wallets',
      path: '/account/wallets',
      component: Wallets,
    },
    {
      id: 'account_trades',
      path: '/account/history',
      component: History,
    },
    /*
    We don't have support for direct fiat to DAI funding since we are yet to
    find an appropriate provider

    Enable once we find and enable an appropriate provider
    {
      id: 'account_funding',
      path: '/account/funding',
      component: Funding,
    },
    */
    { id: 'account_orders', path: '/account/orders', component: Orders },
    {
      id: 'account_offerings',
      path: '/account/offerings',
      component: OfferingsHistory,
    },
  ],
};

const helpRoutes: CustomRouteWrapper = {
  id: 'help',
  path: '/help',
  hideInSidebar: true,
};

export const dashboardRoutes = [
  homeRoutes,
  portfolioRoutes,
  marketplaceRoutes,
  offeringRoutes,
  accountRoutes,
  helpRoutes,
];

export enum GeneralRoutes {
  Portfolio = 'portfolio',
  Marketplace = 'marketplace',
  Offerings = 'offerings',
  Account = 'account',
}
